import { Feature } from "geojson";


export enum FieldStatus {
    NO_INFO = "NO_INFO",
    BALING_ORDERED = "BALING_ORDERED",
    COLLECT_ORDERED = "COLLECT_ORDERED",
    READY_FOR_BALING = "READY_FOR_BALING",
    READY_FOR_COLLECT = "READY_FOR_COLLECT"
}

export class Field {
    geometry: Feature = null;
    currentAmountOfBales: number = 0;
    originalAmountOfBales: number = 0;
    status: FieldStatus = FieldStatus.NO_INFO;
    note: string = '';
    accessRoad: Feature = null;
    ownerID: string;
    cropID: string;
}
