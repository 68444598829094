
import { centerOfMass, centroid } from '@turf/turf';
import React from 'react';
import { Layer, Source } from "react-mapbox-gl";
import { connect } from 'react-redux';
import { selectEditFeature, selectedStacksIds, selectStackPopUpId, setSelectStackId, setStackPopUpId } from '../../redux/slicers/mapSlice';
import { selectStacksById } from '../../redux/slicers/stackSlice';
import { MapboxContext} from './MapComponent';
import {PopUp} from './PopUp'
import _ from "lodash";

export const endClusters = 'StackClusters';
export const endClusterCount =  'cluster-count';
export const endPolyLayer = 'StackPolyLayer';
export const endPolySource = 'Stacks';
export const endCentroids = 'StackCentroids';
export const endMarkers = 'StackMarkers'
export const endMarkerSource = 'StackMarkerSource'


class StacksLayer extends React.Component {

  static contextType = MapboxContext;

  constructor(props){
    super(props);
    this.getNames = this.getNames.bind(this)
    this.polyClicked = this.polyClicked.bind(this)
    this.onMapIdle = this.onMapIdle.bind(this)
    this.updateSelected = this.updateSelected.bind(this)
    // this.onPopUpClose = this.onPopUpClose.bind(this) 
    
  }
  getNames(){
    return { 
      clusters : this.props.name + endClusters,
      clusterCount : this.props.name + endClusterCount,
      newPolyLayer : this.props.name + endPolyLayer,
      newPolySource : this.props.name + endPolySource,
      centroids : this.props.name + endCentroids,
      markerSource: this.props.name + endMarkerSource, 
      markers: this.props.name + endMarkers,
    }
  }

  polyClicked(e){
    if (e.features.length > 0) {
      console.log("Stack clicked")
      if (this.props.selectable === true) {           
        this.props.setSelectStackId(e.features[0].properties.id)     
      }   
    }
  }

  // onPopUpClose(){
  
  // }

  getLayerStacks(stacks){

    const newPolyFeaturesList = [];
    const stackCentroidList = [];
    const markerList = []

    stacks.forEach((stack, i) => {
      let tmpStack = JSON.parse(JSON.stringify(stack));
      tmpStack.location.properties = {}
      tmpStack.location.properties.id = stack.id // Save cosmos db id as properties id
      tmpStack.location.properties.type = this.props.type
      // tmpStack.geometry.id = i;
      if(tmpStack.location?.geometry?.type === "Point") {
        markerList.push(tmpStack.location)
      } else {
        newPolyFeaturesList.push(tmpStack.location);
      }
      try{
        var center = centroid(tmpStack.location);
        center.properties = tmpStack.location.properties
        center.properties.name = stack?.name

        console.log(center)
        stackCentroidList.push(center);
      } catch (error) {
        console.log("Stacklayer", error)
        console.log(tmpStack)        
      }
    })
    return {newPolyFeaturesList,stackCentroidList, markerList}
  }

  updateSelected(){
    var it = 0
    // Filter featues as mapbox can return multiple of the same feature
    const map = this.context    
    const {newPolySource, markerSource} = this.getNames()
    var features =  map.querySourceFeatures(newPolySource);
    var markers = map.querySourceFeatures(markerSource)
    let ids = features.map(o => o.id)
    let markerIds = markers.map(o => o.id)
    let filtered = features.filter(({id}, index) => !ids.includes(id, index+1))  
    let markerfiltered = markers.filter(({id}, index) => !markerIds.includes(id, index+1))
    // Update clicked state
    filtered.forEach((feature) => {
      const existAt = this.props.selectedStacks.indexOf(feature.properties.id)
      if (existAt === -1) {
        map.removeFeatureState({
          source: newPolySource,
          id: feature.id
        });
      } else {        
        map.setFeatureState({
          source: newPolySource,
          id: feature.id,
        }, {
          clicked: true
        })
      }
    })

    markerfiltered.forEach((feature) => {
      const existAt = this.props.selectedStacks.indexOf(feature.properties.id)
      if (existAt === -1) {
        map.removeFeatureState({
          source: markerSource,
          id: feature.id
        });
      } else {        
        map.setFeatureState({
          source: markerSource,
          id: feature.id,
        }, {
          clicked: true
        })
      }
    })
  }

  componentWillUnmount(){
    const map = this.context 
    map.off('idle', this.onMapIdle) 
  }

  onMapIdle(){
    if(this.updated === true){
      this.updateSelected()
      this.updated = false;
    }    
  }
  
  componentDidMount(){
    const map = this.context 
    map.on('idle', this.onMapIdle) 
  }

  componentDidUpdate(){  
    this.updated = true 
    const popUpStack = this.props.selectStacksById(this.props.selectedPopUpId)
  }

  render(){
    const {clusters, clusterCount, newPolyLayer, newPolySource,centroids,markers, markerSource} = this.getNames(this.props.name)
    const {color } = this.props
    const {newPolyFeaturesList , stackCentroidList, markerList } =  this.getLayerStacks(this.props.stacks) 
    return(
      <>
      <Source id={centroids} geoJsonSource={{
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: stackCentroidList,
        },
        cluster: true,
        generateId: true,
        clusterMaxZoom: 14,
      }} />

      <Source id={newPolySource} geoJsonSource={{
        type: 'geojson',
        generateId: true,
        data: {
          type: 'FeatureCollection',
          features: newPolyFeaturesList,
        }
      }} />

    <Source id={markerSource} geoJsonSource={{
        type: 'geojson',
        generateId: true,
        data: {
          type: 'FeatureCollection',
          features: markerList,
        }
      }} />

      {this.props.visible === true && (<>
      <Layer
        id={clusters}
        type='circle'
        sourceId={centroids}        
        filter={['has', 'point_count']}
        paint={{
          'circle-color': color.unclicked,
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }}
      />

      < Layer
        id = {markers + 'test'}
        type = 'circle'
        sourceId={centroids}
        filter={['!', ['has', 'point_count']]}       
        paint={ {
        'circle-color': color.unclicked,
        'circle-radius': 10,
        'circle-stroke-width': 2,
        'circle-stroke-color': '#fff'
        }}
        maxZoom={14}      
      />


      <Layer 
        type='symbol' 
        id={clusterCount}
        sourceId={centroids} 
        filter={['has', 'point_count']}
        maxZoom={14} 
        layout={{'text-field': '{point_count_abbreviated}' }} 
        paint={{"text-color": "#202","text-halo-color": "#fff","text-halo-width": 2 }}
    
      /> 

      < Layer
        id = {markers}
        type = 'circle'
        sourceId={markerSource}
        // filter={['!', ['has', 'point_count']]}       
        paint={ {
        'circle-color': [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          color.clicked,
          color.unclicked,
        ],
        'circle-radius': 10,
        'circle-stroke-width': 2,
        'circle-stroke-color': '#fff'
        }}
        minZoom={12}
        onClick={this.polyClicked}
      />
   
    
      
      <Layer
        id={newPolyLayer}
        type='fill'
        sourceId={newPolySource}        
        paint={{
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'clicked'], false],
            color.clicked,
            color.unclicked,
          ],
          'fill-opacity': 0.5
        }}    
        minZoom={12}
        onClick={this.polyClicked}
      />   

      <Layer 
      type='symbol'
       id={"stackname"}
        sourceId={centroids} 
        filter={['!',['has', 'point_count']]}
        minZoom={15}
        layout={{'text-field': ["get", "name"],
                 'text-offset' : [0,-1.5] }}
        paint={{"text-color": "#202","text-halo-color": "#fff","text-halo-width": 2 }}/>

      {this.props.children}

      </>)}  
    </>
    )
  }
}

const mapStateToProps = state => (
  {selectedStacks: selectedStacksIds(state), 
   selectStacksById: (id) => selectStacksById(state,id),
   editFeature: selectEditFeature(state)
  })

function mapDispatchToProps(dispatch) {
return {
  setSelectStackId: (id) => dispatch(setSelectStackId(id)),  
};
}
export default connect(mapStateToProps,mapDispatchToProps)(StacksLayer)
