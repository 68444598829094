
import React, { useContext, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import { MapboxContext } from './MapComponent';


export const PopUp = ({ map, children, coordinates, mapboxPopupProps}) => {
    // this is a mapbox map instance, you can pass it via props
    // const { map } = useContext(MapboxContext);
    const popupRef = useRef();

    const onPopUpclose = () => {        
     
    }

 
    

    useEffect(() => {         
        const popup = new mapboxgl.Popup(mapboxPopupProps)
            .setLngLat(coordinates)
            .setDOMContent(popupRef.current)
            .setMaxWidth('none')
            .addTo(map);

        popup.on('close', onPopUpclose)   
          
        
       
        var px = map.project(popup._lngLat); // find the pixel location on the map where the popup anchor is
        px.y -= popup._container.clientHeight/2 // find the height of the popup container, divide by 2, subtract from the Y axis of marker location
        map.panTo(map.unproject(px),{animate: true,essential: true}); // pan to new center 
        // map?.flyTo({center: popup._lngLat, essential: true})
        // const point = map.project(popup._lngLat);
        // // console.log(point)
        // const popupHeight = popup._container.clientHeight + 50; // Small amount of padding
        // // const popupWidth = popup._container.clientWidth + 50; // Small amount of padding
        // if (point.y - popupHeight < 0) {
        //     map.panBy([0, point.y - popupHeight]);
        // }

        // if (point.x + popupWidth < 0) {
        //     map.panBy([0, point.y - popupHeight]);
        // }
       
       

         return popup.remove;
    }, [children, mapboxPopupProps, coordinates,map]);

    return (
        /**
         * This component has to have 2 divs.S
         * Because if you remove outter div, React has some difficulties
         * with unmounting this component.
         * Also `display: none` is solving that map does not jump when hovering
         * ¯\_(ツ)_/¯
         */
        <div style={{ display: 'none' }}>         
            <div ref={popupRef}>
                {children}
            </div>
         </div>
    );
};