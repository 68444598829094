import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Grid, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { selectEditFeature, setEditFeature, setDraw, setPopUp, selectDraw } from '../../redux/slicers/mapSlice';
import { centerOfMass } from '@turf/turf';
import { NEW_ITEM_TYPE, POPUPTYPE } from '../../services/maps/LayerTypes';
import _ from 'lodash';
import { clearCustomerFields } from '../../redux/slicers/customerSlice';
import { clearNewItemState, selectNewItemState } from '../../redux/slicers/dashboardSlice';
import userSlice from '../../redux/slicers/userSlice';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  }, 
root:{
  position: 'absolute',
  zIndex: '10',
  left: '50%',
  transform: 'translate(-50%, 0)',
  marginTop: 5,
  top: 0,   
  padding: '5px' 
}
}));

export default function DrawControl() {
  const classes = useStyles();
  const dispatch = useDispatch()
  const editFeature = useSelector(selectEditFeature)
  const itemState = useSelector(selectNewItemState)
  const draw = useSelector(selectDraw)

  const handleDone = () => {

    if(itemState.type === NEW_ITEM_TYPE.STACK){
      if(itemState.isNew === false && itemState.isEditing === true){
        dispatch(setPopUp({id: itemState.stack.id, type: POPUPTYPE.STACK}))
        dispatch(setDraw({...draw,enableLayer: true,
          enableEdit: false}))
          return
      }      
    }

    if(itemState.type === NEW_ITEM_TYPE.FIELD){
      if(itemState.isNew === false && itemState.isEditing === true){
        dispatch(setPopUp({id: itemState.field.id, type: POPUPTYPE.FIELD}))
        dispatch(setDraw({...draw,enableLayer: true,
          enableEdit: false}))
          return
      }
    }
    dispatch(setPopUp({id: centerOfMass(editFeature).geometry.coordinates.slice(), type: POPUPTYPE.NEW_FIELD}))
    dispatch(setDraw({...draw,enableLayer: true,
                       enableEdit: false}))
  }

  const handleCancel = () => {
    dispatch(clearCustomerFields())
    dispatch(setEditFeature({}))
    dispatch(setDraw({...draw,enableLayer: false,
      enableEdit: false}))
    dispatch(clearNewItemState())   
  }

  return (   
        <Paper className={classes.root}>
            <Grid container direction="row">
                <Grid item>              
                  <Button
                    variant="contained"                   
                    color="secondary"
                    className={classes.button}
                    startIcon={<ClearIcon />}
                    onClick ={handleCancel}
                  >
                    Fortryd
                  </Button>
                </Grid>
                { !_.isEmpty(editFeature) && <Grid item>
                <Button
                    variant="contained"                  
                    color="primary"
                    className={classes.button}
                    startIcon={<DoneIcon />}
                    onClick ={handleDone}
                  >
                    Færdig
                  </Button> 
                </Grid>       }        
            </Grid>
        </Paper>     
  );
}