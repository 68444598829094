import { configureStore } from '@reduxjs/toolkit'

import balesReducer from './slicers/baleSlice'
import customerReducer from './slicers/customerSlice'
import userReducer from './slicers/userSlice'
import fieldsReducer from './slicers/fieldSlice'
import jobsReducer from './slicers/jobsSlice'
import dashboardReducer from './slicers/dashboardSlice'
import mapReducer from './slicers/mapSlice'
import stackReduce from './slicers/stackSlice'
import permissionReducer from './slicers/permissionSlicer'

const store = configureStore({
  reducer: {
    // Define a top-level state field named `todos`, handled by `todosReducer`
    bales: balesReducer,
    customers: customerReducer,
    users: userReducer,
    fields: fieldsReducer,
    jobs: jobsReducer,
    dashboard: dashboardReducer,
    map: mapReducer,
    stacks: stackReduce,
    selectorPermissions: permissionReducer,
  },
  devTools: (process.env.NODE_ENV === "development")
})

export default store
