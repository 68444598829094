import { SatelliteSharp } from '@material-ui/icons';
import { createSlice,createAsyncThunk, createEntityAdapter  } from '@reduxjs/toolkit'
import _ from "lodash";
import { createSelector } from 'reselect'
import { fetchUsers } from './userSlice';
const jobsAdapter = createEntityAdapter()

const initialState = jobsAdapter.getInitialState({
    status: 'idle',
    statusMsg: "",
    editJob : {},
    editJobIsNewJob: false
  })


  export const assignToJob = createAsyncThunk('jobs/assingToJob', async (arg,{dispatch}) => {
    const serverUrl = process.env.REACT_APP_SERVER_URL;  
    const response = await fetch(
      `${serverUrl}/api/AssignJob`,
      {
        method: 'POST',      
        body: JSON.stringify(arg.assign),
        headers: {
            Authorization: `Bearer ${arg.token}`,
          }        
        }
    );   
    const data = await response.json()
    if(response.ok){
      await dispatch(fetchUsers(arg.token))
      return data
    }
  })


  export const createJob = createAsyncThunk('jobs/createJob', async (arg,{dispatch}) => {

    const serverUrl = process.env.REACT_APP_SERVER_URL;  
    const response = await fetch(
      `${serverUrl}/api/CreateAnyJob`,
      {
        method: 'POST',      
        body: JSON.stringify(arg.job),
        headers: {
            Authorization: `Bearer ${arg.token}`,
          }        
        }
    );   
    const data = await response.json()
    if(response.ok){
      await dispatch(fetchAllJobs({token:arg.token}))
      return data
    }
})

export const updateJob = createAsyncThunk('jobs/updateJob', async (arg,{dispatch}) => {

  const serverUrl = process.env.REACT_APP_SERVER_URL;  
  const response = await fetch(
    `${serverUrl}/api/UpdateAnyJob`,
    {
      method: 'POST',      
      body: JSON.stringify(arg.job),
      headers: {
          Authorization: `Bearer ${arg.token}`,
        }        
      }
  );   
  const data = await response.json()
  if(response.ok){
    await dispatch(fetchAllJobs({token:arg.token}))
    return data
  }
})


export const fetchAllJobs = createAsyncThunk('jobs/fetchAllJobs', async (arg,{rejectWithValue}) => {

    const serverUrl = process.env.REACT_APP_SERVER_URL;    
    const response = await fetch(
      `${serverUrl}/api/ListAllJobs`,
        {
        headers: {
            Authorization: `Bearer ${arg.token}`,
          }        
        }
    );   

    const data = await response.json()
    if (response.ok) {     
      return data
    }
})

export const deleteJob = createAsyncThunk('jobs/deleteJob', async (arg,{rejectWithValue,dispatch}) => {

  const serverUrl = process.env.REACT_APP_SERVER_URL;  
  console.log("Deleteing job")
  console.log(arg.job)
  const response = await fetch(
    `${serverUrl}/api/DeleteAnyJob`,
      {
      method: 'DELETE',
      headers: {
          Authorization: `Bearer ${arg.token}`,
        },
        body: JSON.stringify(arg.job)     
      }
  );   

  const data = await response.json()
  if (response.ok) {
    await dispatch(fetchAllJobs({token:arg.token}))   
    return data
  } else {
  return rejectWithValue(response)
  }
})

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {  
    resetStatus(state,action){
        state.status = 'idle'
        state.statusMsg = ""
    },   
    setAssignedUsers(state,action){
      state.assignedUsers = action.payload
    }
  },  
  extraReducers: builder => {
    builder 
      .addCase(fetchAllJobs.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchAllJobs.fulfilled, (state, action) => {
            const newEntities = {}
            const id = []
            action.payload.forEach(job => {
                newEntities[job.id] = job
                id.push(job.id)
            })
            state.ids = id
            state.entities = newEntities
            state.status = 'idle'
        state.statusMsg = "Succesfully fetched all jobs"
      })
      .addCase(fetchAllJobs.rejected, (state, action) => {
        state.status = 'error'
        state.statusMsg = "Could not fetch all jobs" 
      }).addCase(deleteJob.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(deleteJob.fulfilled, (state, action) => {
        state.status = 'success'
        state.statusMsg = "Succesfully deleted job"
      })
      .addCase(deleteJob.rejected, (state, action) => {
        state.status = 'error'
        state.statusMsg = action.payload 
      })
      .addCase(createJob.fulfilled, (state, action) => {    
        state.status = 'success'
        state.statusMsg = "Succesfully created a job"
      })
      .addCase(createJob.rejected, (state, action) => {
        state.status = 'error'
        state.statusMsg = action.payload 
      })
      .addCase(updateJob.fulfilled, (state, action) => { 
        state.status = 'success'
        state.statusMsg = "Succesfully updated job"
      })
      .addCase(updateJob.rejected, (state, action) => {
        state.status = 'error'
        state.statusMsg = action.payload 
      }) 
  }
})

export const {resetStatus,setAssignedUsers} = jobsSlice.actions

export default jobsSlice.reducer

export const {
    selectAll: selectJobs,
    selectById: selectJobsById
  } = jobsAdapter.getSelectors(state => state.jobs)
  
export const isEditingAJob = createSelector(
  [(state) => state.jobs.editJob] ,
  (editJob) => {   
    return (!_.isEmpty(editJob))
  }
  )

export const editingJob = createSelector(
  (state) => state.jobs.editJob,
  (editJob) => { return editJob }
)

export const selectAssignedUsers = state => state.jobs.assignedUsers
