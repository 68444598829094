
import { centerOfMass, centroid } from '@turf/turf';
import React from 'react';
import { Layer, Source } from "react-mapbox-gl";
import { connect } from 'react-redux';
import { JobStatus } from '../../models/job';
import { FieldStatus } from '../../models/organisationField';
import { selectFieldsById } from '../../redux/slicers/fieldSlice';
import { selectedFieldsIds, selectEditFeature, selectFieldPopUpId, setFieldPopUpId, setSelectFieldId } from '../../redux/slicers/mapSlice';
import { MapboxContext } from './MapComponent';
import _ from "lodash";
import { colors } from '@material-ui/core';

export const endClusters = 'Clusters';
export const endClusterCount = 'cluster-count';
export const endPolyLayer = 'PolyLayer';
export const endPolySource = 'Fields';
export const endCentroids = 'Centroids';


export const LayerColors = ["#d8373a",
    "#4fc858",
    "#a552cb",
    "#65ae31",
    "#5d6add",
    "#afbf30",
    "#d748ae",
    "#38c887",
    "#d64587",
    "#44a14d",
    "#e17edc",
    "#869728",
    "#774ca1",
    "#bdb64c",
    "#aa4196",
    "#86bc68",
    "#b285e0",
    "#cda337",
    "#5465a9",
    "#e1651b",
    "#46c3e2",
    "#e3643d",
    "#4bccbf",
    "#db3963",
    "#3a7b32",
    "#8696e3",
    "#e3902e",
    "#529acf",
    "#a54223",
    "#2c9d98",
    "#d8615c",
    "#6ebf92",
    "#ab3e54",
    "#429369",
    "#944676",
    "#597721",
    "#ab6da5",
    "#2b6e3f",
    "#e48cb9",
    "#206e54",
    "#d97d81",
    "#597236",
    "#974c5a",
    "#a3ae68",
    "#be7232",
    "#746f3a",
    "#e5956e",
    "#7b6e20",
    "#caa569",
    "#92622f"] 


export const getColorFromStatus = (status) => {
  switch (status) {

    case FieldStatus.BALING_ORDERED:
      return "#5234eb"
    case FieldStatus.COLLECT_ORDERED:
      return "#40eded"
    case FieldStatus.NO_INFO:
      return "#f70707"
    case FieldStatus.READY_FOR_BALING:
      return "#fccf03"
    case FieldStatus.READY_FOR_COLLECT:
      return "#741594"
  }
}

class FieldsLayer extends React.Component {

  static contextType = MapboxContext;

  constructor(props) {
    super(props);
    this.getNames = this.getNames.bind(this)
    this.onMapIdle = this.onMapIdle.bind(this)
    this.onPolyClicked = this.onPolyClicked.bind(this)
    this.updateSelected = this.updateSelected.bind(this)

  }
  getNames() {
    return {
      clusters: this.props.name + endClusters,
      clusterCount: this.props.name + endClusterCount,
      newPolyLayer: this.props.name + endPolyLayer,
      newPolySource: this.props.name + endPolySource,
      centroids: this.props.name + endCentroids
    }
  }

  getLayerFields(fields) {

    const newPolyFeaturesList = [];
    const fieldCentroidList = [];

    console.log(fields)

    fields.forEach((field, i) => {
      try {
        let tmpField = JSON.parse(JSON.stringify(field));
        tmpField.geometry.properties = {}
        tmpField.geometry.properties.id = field.id // Save cosmos db id as properties id
        tmpField.geometry.properties.type = this.props.type
        tmpField.geometry.properties.Afgkode = typeof  field.geometry?.properties?.Afgkode == 'number' ? field.geometry?.properties?.Afgkode.toString() : field.geometry?.properties?.Afgkode
        tmpField.geometry.properties.Afgroede = field.geometry?.properties?.Afgroede
        // tmpField.geometry.id = i;
        newPolyFeaturesList.push(tmpField.geometry);
        var center = centroid(tmpField.geometry);
        center.properties = tmpField.geometry.properties
        center.properties.currentAmountOfBales = field.currentAmountOfBales
        fieldCentroidList.push(center);
      } catch {

      }
    })
    return { newPolyFeaturesList, fieldCentroidList }
  }

  updateSelected() {
    var it = 0
    // Filter featues as mapbox can return multiple of the same feature
    const map = this.context
    const { newPolySource } = this.getNames()
    var features = map.querySourceFeatures(newPolySource);
    let ids = features.map(o => o.id)
    let filtered = features.filter(({ id }, index) => !ids.includes(id, index + 1))
    // Update clicked state
    filtered.forEach((feature) => {
      const existAt = this.props.selectedFields.indexOf(feature.properties.id)
      if (existAt === -1) {
        map.removeFeatureState({
          source: newPolySource,
          id: feature.id
        });
      } else {
        map.setFeatureState({
          source: newPolySource,
          id: feature.id,
        }, {
          clicked: true
        })
      }
    })
  }

  componentWillUnmount() {
    const map = this.context
    map.off('idle', this.onMapIdle)
  }

  onMapIdle() {
    if (this.updated === true) {
      this.updateSelected()
      this.updated = false;
    }
  }

  componentDidMount() {

    console.log("Field layer :  " + this.props.name + " mount")
    const map = this.context
    map.on('idle', this.onMapIdle)
  }

  componentDidUpdate() {
    this.updated = true
  }

  onPolyClicked(e) {
    if (e.features.length > 0) {

      if (this.props.selectable === true) {
        this.props.setSelectFieldId(e.features[0].properties.id)
      }

    }
  }


  render() {
    const { clusters, clusterCount, newPolyLayer, newPolySource, centroids } = this.getNames(this.props.name)
    const { color } = this.props
    const { newPolyFeaturesList, fieldCentroidList } = this.getLayerFields(this.props.fields)
    var matchExpression =['match', ['get', 'Afgkode']]
   

    LayerColors.forEach((color, index) => {
      matchExpression.push(index.toString(), color)
    });
  
    matchExpression.push('#ffffff')  

    console.log(matchExpression)
  
    // const popUpField = this.props.selectFieldsById(this.props.selectedPopUpId)
    return (
      <>
        <Source id={centroids} geoJsonSource={{
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: fieldCentroidList,
          },
          cluster: true,
          generateId: true,
          clusterMaxZoom: 10,
          clusterProperties: {
            sum: ["+", ["get", "currentAmountOfBales"]],
          }
        }} />

        <Source id={newPolySource} geoJsonSource={{
          type: 'geojson',
          generateId: true,
          data: {
            type: 'FeatureCollection',
            features: newPolyFeaturesList,
          }
        }} />

        {this.props.visible === true && (<>
          <Layer
            id={clusters}
            type='circle'
            sourceId={centroids}
            filter={['has', 'point_count']}
            paint={{
              'circle-color': color.unclicked,
              'circle-radius': [
                'step',
                ['get', 'point_count'],
                20,
                100,
                30,
                750,
                40
              ]
            }}
          />
          <Layer
            id={clusterCount}
            type='symbol'
            sourceId={centroids}
            filter={['has', 'point_count']}
            layout={{
              // 'text-field': '{point_count_abbreviated}',
              'text-field': ['case',
                ["==", ["typeof", ["get", "sum"]], "number"],
                ["get", "sum"],
                ["get", "point_count_abbreviated"]

              ],
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 12
            }}
          />

          <Layer
        id={newPolyLayer}
        type='fill'
        sourceId={newPolySource}        
        paint={{
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'clicked'], false],
            color.clicked,
            color.unclicked,
          ],
          'fill-opacity': 0.5
        }}
        onClick={this.onPolyClicked}

      /> 


          {/* <Layer
            id={newPolyLayer}
            type='fill'
            sourceId={newPolySource}
            paint={{
              'fill-color': [
                'case',
                ['boolean', ['feature-state', 'clicked'], false],
                color.clicked,
                matchExpression,
              ],
              'fill-opacity': 0.5
            }}
            onClick={this.onPolyClicked}

          /> */}

          {this.props.children}

        </>)}
      </>
    )
  }
}

const mapStateToProps = state => (
  {
    selectedFields: selectedFieldsIds(state),
    selectFieldsById: (id) => selectFieldsById(state, id),
    editFeature: selectEditFeature(state)
  })

function mapDispatchToProps(dispatch) {
  return {
    setSelectFieldId: (id) => dispatch(setSelectFieldId(id)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FieldsLayer)
