import { OrganisationBase } from "./organisationBase";

export class Customer extends OrganisationBase {
    name: string = "";
    cvr: number = 0;
    streetname: string = "";
    houseNumber: string = "";
    postalCode: number = 0;
    city: string = "";
    phoneNo: string = "";
    email: string = "";
}