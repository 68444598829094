
import mapboxgl from 'mapbox-gl';
import { transformTranslate, polygon, transformRotate, centerOfMass } from '@turf/turf';

import { makeStyles } from '@material-ui/core/styles';
import {addPopup} from '../maps/MapComponent'
import { Source, Layer } from "react-mapbox-gl";
import { useSelector } from 'react-redux'
import { selectMapLayersVisible } from '../../redux/slicers/mapSlice';

export const BaleLayer = ({bales}) =>{
    const layersVisible = useSelector(selectMapLayersVisible)
    const newPointFeaturesList = [];
    const newPolyFeaturesList = [];         
    const baleWidth = 1.2 / 1000.0 / 2;
    const baleLength = 2.4 / 1000.0 / 2;   

   
    bales.forEach((bale) => {        
        newPointFeaturesList.push(bale.point)   
        var topRight = transformTranslate(transformTranslate(bale.point, baleWidth, 90), baleLength, 0);
        var topLeft = transformTranslate(transformTranslate(bale.point, baleWidth, -90), baleLength, 0);
        var buttomRight = transformTranslate(transformTranslate(bale.point, baleWidth, 90), baleLength, 180);
        var buttomLeft = transformTranslate(transformTranslate(bale.point, baleWidth, -90), baleLength, 180);
        newPolyFeaturesList.push(transformRotate(polygon([[topLeft.geometry.coordinates, topRight.geometry.coordinates, buttomRight.geometry.coordinates, buttomLeft.geometry.coordinates, topLeft.geometry.coordinates]], { organisation: bale.organisation, moisture: bale.moisture }), parseFloat(bale.point.properties.bearing)));
    })

    const isVisible = () => {     
      return (layersVisible.balesLayer === true ? "visible" : "none")      
    }


    return (
      <>      
      <Source id={'balePoly'} geoJsonSource={{
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: newPolyFeaturesList,
        }        
      }}/>
      <Layer 
       id ={'balePolyLayer'}
       type='fill'
       layout={{'visibility': isVisible()}} 
       sourceId = {'balePoly'}       
       paint = {{
        'fill-color': '#fccf03',
        'fill-opacity': 1
      }}
      />
      <Source id={'bales'} geoJsonSource={{
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: newPointFeaturesList,
        },
        cluster: true,
        clusterMaxZoom: 14, 
        // cluterRadius: 50 
      }}/> 
       <Layer 
        id={'clusters'}
        type='circle'
        sourceId = {'bales'}
        layout={{'visibility': isVisible()}} 
        filter = {['has', 'point_count']}
        paint={{
          'circle-color': '#f1f075',
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }}
      />
      <Layer       
        id={'cluster-count'}
        type='symbol'
        sourceId={'bales'}
        filter={['has', 'point_count']}
        layout= {{
          'visibility': isVisible(),
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }}
       />
      </> 
    )

};

