import { Feature, Geometry, GeometryCollection } from "@turf/turf";
import { OrganisationBase } from "./organisationBase";

export enum StackStatus {
    OPEN,
    CLOSED,
    FINISEHED
}

export class Stack extends OrganisationBase {
    requestedAmount: number = 0;
    currentAmount: number = 0;
    location: Feature = {};
    status: StackStatus = StackStatus.CLOSED;
    note: string = "";
    name: string ="Stak";
}

