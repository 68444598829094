import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import Auth0ProviderWithHistory from "./services/auth/auth0-provider-with-history";
import store from './redux/store'
import { ThemeProvider } from 'react-jss';
import Theme from './utils/theme';
import {LoadPermissions} from './navigation/role-renderer/LoadPermissions';

import "./index.css";

//import { fetchBales } from './redux/slicers/baleSlice'
//store.dispatch(fetchBales())


if (process.env.NODE_ENV === "production")
    console.log = () => {};


ReactDOM.render(
  <Provider store = {store}>
    <ThemeProvider theme={Theme}>
      <Router>    
        <Auth0ProviderWithHistory>
          <LoadPermissions/>
          <App />     
        </Auth0ProviderWithHistory> 
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
