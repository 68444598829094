//import CustomerView from "./CustomerView";
import React from "react";
import Fab from "@material-ui/core/Fab";
import ContentAdd from "@material-ui/icons/Add";
import Snackbar from "@material-ui/core/Snackbar";
import {Tooltip } from "@material-ui/core";
import DataTable from "../../components/DataTable"
import SkeletonList from "../../components/SkeletonList"
import DeleteDialog from "../../components/DeleteDialog"
import Alert from "../../components/Alert";
import { listPageStyle } from "../../styles";
import SearchBar from "material-ui-search-bar";

import {withAuth0 } from "@auth0/auth0-react";
import { connect } from "react-redux";
import SLUGS from '../../navigation/slugs';
import { fetchCustomers, resetStatus,searchCustomers,customerFiltered,deleteCustomer,selectCustomerById} from '../../redux/slicers/customerSlice'
import { Permission } from "../../navigation/role-renderer/Permissions";
import { ShowWhen } from "../../navigation/role-renderer/ShowWhen";



const { Component } = require("react");
const styles = listPageStyle;

const headers =["Name","cvr","Actions"];
const dataKeys = ["name", "cvr", "actions"];

class CustomerContainer extends Component{
    
    constructor(props){
        super(props);     

        this.handleToggle = this.handleToggle.bind(this)
        this.handleSearchFilter = this.handleSearchFilter.bind(this)     
        this.onPageChange = this.onPageChange.bind(this)
        this.openDialog = this.openDialog.bind(this)
        this.closeDialog = this.closeDialog.bind(this)
        this.onSnackBarClose = this.onSnackBarClose.bind(this)
        this.handleSearchFilter = this.handleSearchFilter.bind(this)
        this.handleNewCustomer = this.handleNewCustomer.bind(this)
        this.handleEditCustomer = this.handleEditCustomer.bind(this)

        this.state = {
            token:"",
            open: false,
            searchOpen: false,
            snackbarOpen: false,
            autoHideDuration: 2000,
            page: 1,
            items: [],
            totalPages: 1,
            customerId: null,
            customerName: "",         
            search: "",
          };
    }

    async componentDidMount(){  
        const { getAccessTokenSilently } = this.props.auth0;        
        const token = await getAccessTokenSilently();
        this.setState({token: token,
                        search: ""});
        this.props.fetchCustomers(token);
        this.props.searchCustomers(this.state.search)
        console.log(this.props)
    }

    componentDidUpdate(prevProps){
        if (this.props.customers !== prevProps.customers) {
            //this.setState({ customerList: this.props.customerList });
            const page = 1;
            const totalPages = Math.ceil(this.props.customers.length / 10);
            const items = this.props.customers.slice(0,10);       
            this.setState({ page, totalPages, items });
          }
          //console.log(" this.props.deleted " + this.props.deleted);
      
          if (
            this.props.status !== prevProps.status && (this.props.status === 'succes' ||  this.props.status === 'error')
          ) {
            console.log("Openeing snackbar")
            console.log(this.state.autoHideDuration)
            this.setState({ snackbarOpen: true });            
          } 
    }

    onPageChange(_event, page) {
        const startIndex = (page - 1) * 10;
        const endIndex = startIndex + 10;
        const items = this.props.customers.slice(startIndex, endIndex);
        this.setState({ page, items });
      }
    
    openDialog(_event, value) {
        console.log(value)
        this.setState({customerName: this.props.selectCustomerById(value).name})
        if (value != null) {
            this.setState({ open: true, customerId: value });
        }
    }

    closeDialog(isConfirmed) {
        this.setState({ open: false });     
        if (isConfirmed && this.state.customerId) {
            this.props.deleteCustomer({customer: this.props.selectCustomerById(this.state.customerId), token : this.state.token});
            this.setState({ customerId: null, customerName : ""});
        }
    }

    
    handleToggle() {
        this.setState({ searchOpen: !this.state.searchOpen });
    }

    handleSearchFilter(searchval){       
        this.setState({ search: searchval});
        this.props.searchCustomers(searchval)              
    } 

    onSnackBarClose(event,reason) {
        console.log("Close snack bar")
        console.log(reason)
        if(reason !== 'clickaway'){
            this.setState({snackbarOpen: false});
            this.props.resetStatus()
        }
    }

    handleNewCustomer() {    
        this.props.history.push(SLUGS.newCustomer);
      }

    handleEditCustomer(_event,id){
        this.props.history.push('/customers/' + id);
      }
  

    render() {        
        const {status} = this.props;
        const {page, totalPages, items } = this.state;  
        const model = "customer"
        return (
         <div style={styles.container} >
            {status === 'loading' ? (              
                <SkeletonList />
            ) : (
              <div>
                <div>
                  <ShowWhen persmissions={[Permission.create_customer]}>
                  <Tooltip title="Add" aria-label="add">
                    <Fab
                      size="small"
                      color="secondary"
                      style={styles.fab}
                      onClick={this.handleNewCustomer}
                    >
                      <ContentAdd />
                    </Fab>
                  </Tooltip>     
                  </ShowWhen>      
                </div>
                <Snackbar
                  open={this.state.snackbarOpen}
                  autoHideDuration={this.state.autoHideDuration}
                  onClose={this.onSnackBarClose}
                >
                  <Alert severity={this.props.status}>
                    {this.props.statusMsg}
                  </Alert>
                </Snackbar>
                <SearchBar
                    value={this.state.search}
                    onChange={this.handleSearchFilter}
                    onRequestSearch={this.handleSearchFilter}
                />
                <DataTable
                  model={model}
                  items={items}
                  dataKeys={dataKeys}
                  headers={headers}
                  page={page}
                  totalPages={totalPages}
                  onDelete={this.openDialog}
                  onEdit = {this.handleEditCustomer}
                  onPageChange={this.onPageChange}
                  deletePermissions ={[Permission.delete_customer]}
                  editPermissions = {[Permission.edit_customer]}
                />
    
                <DeleteDialog
                  open={this.state.open}
                  closeDialog={this.closeDialog}
                  dialogTitle = "Slet bruger"
                  dialogText = {this.state.customerName}
                />
              </div>
            )}
        </div>
        );
      }
}

const  mapStateToProps = state => ({customers : searchCustomers(state),
                                     status: state.customers.status,
                                     statusMsg: state.customers.statusMsg,
                                     selectCustomerById: (id) => selectCustomerById(state,id)})

function mapDispatchToProps(dispatch) {
    return {
        fetchCustomers: (token) => dispatch(fetchCustomers(token)),
        searchCustomers: (search) => dispatch(customerFiltered(search)),
        deleteCustomer: (arg) => dispatch(deleteCustomer(arg)),
        resetStatus: () => dispatch(resetStatus())        
    };
  }
  
export default withAuth0(connect(mapStateToProps, mapDispatchToProps)(CustomerContainer));
