import { withAuth0 } from '@auth0/auth0-react';
import { Grid, Tooltip } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { common, green, red } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from 'react-redux';
import { changeTab, openDrawer } from '../../redux/slicers/dashboardSlice';
import { deleteField, setEditField, updateOwnFields } from '../../redux/slicers/fieldSlice';
import { setEditFeature, setDraw } from '../../redux/slicers/mapSlice';
import { setLastEditAmount } from '../../redux/slicers/stackSlice';

const white = common.white;
const green400 = green["400"];
const styles = {
  root: {
    maxWidth: '300px',
    maxHeight: '500px',
    minWidth: '250px'
  },
  media: {
    height: 140,
  },
  mapboxglPopupContent:{
    padding: 0,
    },
    deleteButton: {
        color: "white",
        marginRight: "1em",
        backgroundColor: red[500],
      },
      editButton: {
        marginRight: "1em",
        color: white,
        backgroundColor: green400,
      },

};


class CustomerFieldInfoPopUp extends React.Component {

    constructor(props) {
        super(props)
        this.onClose = this.onClose.bind(this)   
        this.state = {     
          open: false
     }
    }
    
   
    onClose(e,stack){
      this.props.onClose()
    }  

    render (){

    var customerField = this.props.field
    console.log(customerField)  
    const {classes} = this.props
    return (        
      <>    
        <Card className={classes.root}>
            <CardHeader                           
            title={"Mark"}
            subheader= {<div>{customerField?.properties?.Afgroede}</div>}
            action={
              <Tooltip title="Luk" aria-label="close">
                  <Fab
                    size="small"
                    className={classes.closeButton}
                    onClick={this.onClose}
                    >
                    <CloseIcon />
                </Fab>  
              </Tooltip>}                         
            />
            <CardContent>                
                 <div>                 
                  Areal [Ha] : {customerField?.properties?.IMK_areal} <br/>
                  Mark nr : {customerField?.properties?.Marknr} <br/>               
                 </div>
                <Grid containerm direction="column" spacing={2}>        
                                    
                  </Grid>              
            </CardContent>
            <CardActions disableSpacing>
            
            </CardActions>                          
        </Card>         
      </>      
    );
}

}

const  mapStateToProps = state => (
    {customerFields: state.customers.customerFields})

  
  function mapDispatchToProps(dispatch) {
    return {   
        deleteField: (arg) => dispatch(deleteField(arg)),
        setEditFeature: (arg) => dispatch(setEditFeature(arg)),
        setDraw: (arg) => dispatch(setDraw(arg)),     
        setLastEditAmount: (amount) => dispatch(setLastEditAmount(amount)),
        updateField: (arg) => dispatch(updateOwnFields(arg)),
        openUtilPanel: (arg) => dispatch(openDrawer(arg)),
        setUtilPanelTab: (arg) => dispatch(changeTab(arg))
    };
  }

export default withAuth0(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CustomerFieldInfoPopUp)))