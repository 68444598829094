import { createSlice } from '@reduxjs/toolkit'
import {LocationType} from '../../models/location'
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {drawerStatus: {open: false, currentTab: 0},
                 layersVisible: {fieldsLayer: true,
                                  balesLayer: true,
                                  stackLayer: true,
                },
                selection: {key: "",
                            toLocation: {
                              [LocationType.FIELD]: [],
                              [LocationType.STACK]: [],
                              [LocationType.CUSTOMER]: [],
                            },
                            fromLocation: {
                              [LocationType.FIELD]: [],
                              [LocationType.STACK]: [],
                              [LocationType.CUSTOMER]: [],
                            },
                            onLocation: {
                              [LocationType.FIELD]: [],
                              [LocationType.STACK]: [],
                              [LocationType.CUSTOMER]: [],
                            }
                          },
                  showJobDetails: {},
                  newItemState:{activeStep: 0,
                                customer: {},
                                customerValid: false}
                },
               
  reducers: {
    openDrawer(state, action) {
      // ✅ This "mutating" code is okay inside of createSlice!
      state.drawerStatus.open = action.payload
    },
    changeTab(state,action){
        state.drawerStatus.currentTab = action.payload
    },
    setLayerVisible(state,action){
        state.layersVisible[action.payload.layer] = action.payload.value
    },
    setOnlyLayerVisible(state,action){
        for(var key in state.layersVisible ){              
            (key === action.payload) ? state.layersVisible[key] = true : state.layersVisible[key] = false
        }
    },
    setSelectkey(state,action){
        state.selection.key = action.payload
    },
    setSelection(state,action){
      state.selection = {...state.selection, [state.selection.key] : {...state.selection[state.selection.key],...action.payload}}
    },
    setNewItemState(state,action){
      state.newItemState = action.payload
    },
    clearNewItemState(state,action){
      state.newItemState = {activeStep: 0,
        customer: {},
        customerValid: false}
    },
    clearSelection(state, action){
      state.selection = {key: "toLocation",
      toLocation: {
        [LocationType.FIELD]: [],
        [LocationType.STACK]: [],
        [LocationType.CUSTOMER]: [],
      },
      fromLocation: {
        [LocationType.FIELD]: [],
        [LocationType.STACK]: [],
        [LocationType.CUSTOMER]: [],
      },
      onLocation: {
        [LocationType.FIELD]: [],
        [LocationType.STACK]: [],
        [LocationType.CUSTOMER]: [],
      }
    }   
    },
    setShowJobDetails(state,action){
      state.showJobDetails = action.payload
    }
  }
})

export const {openDrawer,changeTab,setLayerVisible,setSelectkey,itemSelected,setOnlyLayerVisible,setSelection,clearSelection,setShowJobDetails,setNewItemState,clearNewItemState} = dashboardSlice.actions

export const selectDrawerStatus = state => state.dashboard.drawerStatus 
export const selectLayerVisible = state => state.dashboard.layersVisible
export const selectSelection = state => state.dashboard.selection
export const selectShowJobDetails = state => state.dashboard.showJobDetails
export const selectNewItemState = state => state.dashboard.newItemState
export default dashboardSlice.reducer