export default {
    // private
    dashboard: '/dashboard',
    overview: '/overview',
    users: '/users',
    newUser: '/users/new',
    editUser: '/users/:id',
    settings: '/settings',
    profileInfo: '/profileInfo',
    customerHandling : '/customers',
    newCustomer: '/customers/new',
    editCustomer: '/customers/:id',
    root:'/',

    // auth
    login: '/login',
    signup: '/signup',
    forgotPassword: '/forgot_password'
};
