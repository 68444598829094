
import React, {useEffect, useState} from "react";

export const useResponsive = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return ({width: width, isMobile: (width <= 768), isTablet: (width <= 1200)});
}


export function withResponsive(Component) {
    return function WrappedComponent(props) {
      const responisve = useResponsive();
      return <Component {...props} responsive={responisve} />;
    }
  }