
import { createUseStyles } from 'react-jss';
import Flyer from '../../assets/Logo/Flyer.svg'


const useStyles = createUseStyles({
    container: {   
        margin: '0 0',
        border: '0px',
        height: '100%',
        display: 'flex',
        // flexDirection: 'column',
        // position: 'relative',
    },
    container_img: {
        //  height: "100%",
         width: "100%",
        // objectFit: "cover"
    }
});

const HomeView = ({name}) => {    

    const classes = useStyles(); 
    return(    
    <div className={classes.container}>
        <img className={classes.container_img} src={Flyer} alt="Flyer"/>
    </div>
    )
};

export default HomeView;