import { useDispatch } from 'react-redux'
import {fetchPermissions} from '../../redux/slicers/permissionSlicer'
import { useAuth0 } from "@auth0/auth0-react";

export const LoadPermissions = () => {

    const dispatch = useDispatch()
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    if (isAuthenticated === true) {
        getAccessTokenSilently().then((token) => {
            dispatch(fetchPermissions(token))
        })
    }
    return null
}