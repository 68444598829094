import { useAuth0 } from "@auth0/auth0-react";
import {
  ArgumentAxis,
  BarSeries, Chart, ValueAxis
} from '@devexpress/dx-react-chart-material-ui';
import { Tooltip } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import { blue, common, green, grey, red } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import center from "@turf/center";
import clsx from 'clsx';
import _ from "lodash";
import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldStatus } from "../../models/organisationField";
import { selectLayerVisible } from "../../redux/slicers/dashboardSlice";
import { selectFieldPopUpId, selectPopUp, setFieldPopUpId } from "../../redux/slicers/mapSlice";
import { deleteField, isEditingAField } from "../../redux/slicers/fieldSlice";
import { createSelectByAuth0Id } from '../../redux/slicers/userSlice';
import { MapboxContext } from "../../services/maps/MapComponent";



const grey500 = grey["500"];
const green400 = green["400"];

const white = common.white;

const useStyles = makeStyles((theme) => ({
  root: {
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatarTransport: {
    backgroundColor: blue[500],
  },
  avatarBale: {
    backgroundColor: green[500],
  },
  editButtonIcon: {
    fill: white,
  },
  editButton: {
    marginRight: "1em",
    color: white,
    backgroundColor: green400,
  },
  showButtonSelected: {
    backgroundColor: green400,
  },
  showButton: {
    // marginLeft: "2em"
  },
  deleteButton: {
    color: "white",
    marginRight: "1em",
    backgroundColor: red[500],
  },
  doneButtonIcon: {
    fill: white,
  },
  doneButton: {
    marginRight: "1em",
    color: white,
    backgroundColor: green400,
  },
  cancelButton: {
    color: "grey",
    fill: grey500,
  }
}));


export const getTextFromStatus = (Status) => {
    switch (Status) {
      case FieldStatus.NO_INFO:
        return "Ingen information"
  
      case FieldStatus.BALING_ORDERED:
        return "Presning bestilt"
  
      case FieldStatus.COLLECT_ORDERED:
        return "Samling bestilt"

      case FieldStatus.READY_FOR_BALING:
        return "Klar til presning"

      case FieldStatus.READY_FOR_COLLECT:
        return "Klar til samling"

      default:
        return "ukendt";
    }
  }

export function SelectionCount({text, count}) {
    return (
    <Grid item>
        <Typography variant="body1" >
        {text} : {count}
        </Typography>
    </Grid>
    )
}

export function FieldContent({ field }) {
   
    return (
     <Grid item container direction="column" spacing = {2} >  
        {/* <SelectionCount text = 'Oprindeligt antal baler' count = {field?.originalAmountOfBales} />         */}
        <SelectionCount text = 'Pressede baller'count = {field?.originalAmountOfBales} />  
        <SelectionCount text = 'Resterende baller'count = {field?.currentAmountOfBales} />        
         
    </Grid>
    )}
