export enum Permission{
    read_user = 'read:user', 
    edit_user = 'edit:user', 
    delete_user = 'delete:user', 
    create_user = 'create:user', 
    read_job = 'read:job', 
    edit_job = 'edit:job', 
    delete_job = 'delete:job', 
    create_job = 'create:job', 
    read_customer = 'read:customer', 
    delete_customer = 'delete:customer', 
    create_customer = 'create:customer', 
    create_stack = 'create:stack', 
    read_stack = 'read:stack', 
    edit_stack = 'edit:stack', 
    delete_stack = 'delete:stack', 
    read_field = 'read:field', 
    edit_field = 'edit:field', 
    delete_field = 'delete:field', 
    create_field = 'create:field', 
    modify_field = 'modify:field', 
    share_stack = 'share:stack', 
    modify_stack = 'modify:stack', 
    read_bale = 'read:bale', 
    edit_bale = 'edit:bale', 
    delete_bale = 'delete:bale', 
    create_bale = 'create:bale', 
    edit_customer = 'edit:customer'
}

export default Permission;