import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ContentCreate from "@material-ui/icons/Create";
import ActionDelete from "@material-ui/icons/Delete";
import { connect, useSelector } from 'react-redux';
import { deleteStack, selectLastEditAmount, selectStacksById, setEditStack, setLastEditAmount, updateStack } from '../../redux/slicers/stackSlice';
import { Grid, Paper, TextField, Tooltip } from '@material-ui/core';
import Fab from "@material-ui/core/Fab";
import { blue, common, green, grey, red } from "@material-ui/core/colors";
import { withAuth0 } from '@auth0/auth0-react';
import { setEditFeature, setDraw, setClosePopUp } from '../../redux/slicers/mapSlice';
import CloseIcon from '@material-ui/icons/Close';
import { getTextFromStatus, StackContent } from '../../components/Stack/StackTile';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteDialog from '../../components/DeleteDialog';
import { changeTab, clearNewItemState, openDrawer, selectNewItemState, setNewItemState } from '../../redux/slicers/dashboardSlice';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { EditStackTile } from '../../components/Stack/EditStackTile';
import { NEW_ITEM_TYPE, POPUPTYPE } from './LayerTypes';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Collapse } from '@material-ui/core';
import { SelectionCount } from '../../components/Fields/FieldTile';
import { withResponsive } from '../../components/Responsive';
import NavigationIcon from '@material-ui/icons/Navigation';
import { Link } from '@material-ui/core';
import { center, centroid } from '@turf/turf';
import { ShowWhen } from '../../navigation/role-renderer/ShowWhen';
import Permission from '../../navigation/role-renderer/Permissions';

const white = common.white;
const green400 = green["400"];
const styles = theme => ({
  root: {
    maxWidth: '300px',
    minWidth: '250px'
    //  margin: '1px'
  },
  media: {
    height: 140,
  },
  mapboxglPopupContent: {
    padding: 0,
  },
  deleteButton: {
    color: "white",
    marginRight: "1em",
    backgroundColor: red[500],
  },
  editButton: {
    marginRight: "1em",
    color: white,
    backgroundColor: green400,
  },
  closeButton: {
    marginRight: "1em",
  },
  showButton: {
    marginRight: "1em",
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },

});


class StackInfoPopUp extends React.Component {

  constructor(props) {
    super(props)
    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this)
    this.onClose = this.onClose.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
    this.onShow = this.onShow.bind(this)
    this.handleExpandClick = this.handleExpandClick.bind(this)
    this.onNavigate = this.onNavigate.bind(this)
    this.updateBaleCount = this.updateBaleCount.bind(this)
    this.state = {
      addText: "Tilføj",
      dialog: { open: false, onConfirm: null },
      expanded: true
    }
  }

  onDelete() {
    const stack = this.props.selectStacksById(this.props.stackID)
    this.props.deleteStack({ token: this.state.token, stack: stack })
    this.props.onClose()
  }

  onEdit(e, stack) {
    console.log(stack)
    this.props.setItemState({ ...this.props.itemState, stack: stack, type: NEW_ITEM_TYPE.STACK, isEditing: true, isNew: false })
  }

  onShow(event) {
    this.props.setUtilPanelTab(2)
    this.props.openUtilPanel(true)
  }

  onClose(e, stack) {
    this.props.clearItemState()
    this.props.setEditFeature({})
    this.props.setDraw({
      enableLayer: false,
      enableEdit: false
    })
    this.props.onClose()
  }

  async componentDidMount() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    this.setState({ token: token });
  }

  updateBaleCount(value) {
    const stack = this.props.selectStacksById(this.props.stackID)
    var modStack = JSON.parse(JSON.stringify(stack))

    modStack.currentAmount = parseInt(modStack.currentAmount) + value;

    this.props.updateStack({ stack: modStack, token: this.state.token })
  }


  closeDialog(isConfirmed) {
    this.setState({ ...this.state, dialog: { ...this.state.dialog, open: false } });
    if (isConfirmed) {
      this.state.dialog.onConfirm()
    }
  }

  handleExpandClick() {
    this.setState({ expanded: !this.state.expanded });
  };

  onNavigate(e, stack) {
    const coord = center(stack.location).geometry.coordinates
    window.open(encodeURI("https://www.google.com/maps/dir/?api=1&destination=" + coord[1] + "," + coord[0] + "&travelmode=driving"), "_blank");

  }
  render() {

    const stack = this.props.selectStacksById(this.props.stackID)
    const { classes } = this.props
    return (
      <>
        <Card className={classes.root}>
          <CardHeader
            title={stack.name !== undefined ? stack.name : "Stak"}
            subheader={this.props.itemState.isEditing !== true && ("Status: " + getTextFromStatus(stack.status))}
            action={this.props.itemState.isEditing !== true &&
              <Tooltip title="Luk" aria-label="close">
                <Fab
                  size="small"
                  className={classes.closeButton}
                  onClick={(e) => this.onClose(e, stack)}
                >
                  <CloseIcon />
                </Fab>
              </Tooltip>
            }
          />
          <CardContent>
            {(this.props.itemState.isEditing === true && this.props.itemState.type === NEW_ITEM_TYPE.STACK) ?
              <EditStackTile />
              :
              <Grid container direction="column" spacing={2}>

                {/* <SelectionCount text = 'Oprindeligt antal baler' count = {field?.originalAmountOfBales} />         */}
                <SelectionCount text='Antal baller stakken:' count={stack?.currentAmount} />
                <SelectionCount text='Ønsket antal baller:' count={stack?.requestedAmount} />


                <ShowWhen persmissions={[Permission.modify_stack]}>
                  <Grid item container direction="row" spacing={2} justify="center" alignItems="center">

                  <Grid item xs={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        //endIcon={<RefreshIcon />}
                        onClick={() => {
                          if (this.props.lastEditAmount !== 0) {
                            this.setState({
                              ...this.state, dialog: {
                                open: true,
                                onConfirm: () => {this.updateBaleCount(-Math.abs(parseInt(this.props.lastEditAmount)))},
                                title: "Opdater stak",
                                text: "Fjern" + " " + Math.abs(parseInt(this.props.lastEditAmount)) + " fra stakken?"
                              }
                            });
                          }
                        }}
                      >
                        Fjern
                      </Button>
                    </Grid>

                    <Grid item xs={4}>
                      <TextField label={"Antal"} variant="outlined" type="number" InputLabelProps={{
                        shrink: true,
                      }}
                        defaultValue={this.props.lastEditAmount}
                        onBlur={(event) => { this.props.setLastEditAmount(event.target.value) }}
                        // onChange={(event) => {
                        //   //var text = event.target.value > 0 ? "Tilføj" : "Fjern"
                        //   //this.setState({ ...this.state, addText: text })
                        // }}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        //endIcon={<RefreshIcon />}
                        onClick={() => {
                          if (this.props.lastEditAmount !== 0) {
                            this.setState({
                              ...this.state, dialog: {
                                open: true,
                                onConfirm: () => {this.updateBaleCount(Math.abs(parseInt(this.props.lastEditAmount)))},
                                title: "Opdater stak",
                                text: "Tilføj" + " " + Math.abs(parseInt(this.props.lastEditAmount)) + " baller til stakken?"
                              }
                            });
                          }
                        }}
                      >
                        Tilføj
                      </Button>
                    </Grid>
                  </Grid>
                </ShowWhen>
              </Grid>
            }

            {/* <pre>{JSON.stringify(modStack,null,2)}</pre> */}
          </CardContent>
          {this.props.itemState.isEditing !== true && <CardActions disableSpacing>
            <ShowWhen persmissions={[Permission.edit_stack]}>
            <Tooltip title="Rediger" aria-label="edit">
              <Fab
                size="small"
                className={classes.editButton}
                onClick={(e) => this.onEdit(e, stack)}
              >
                <ContentCreate />
              </Fab>
            </Tooltip>
            </ShowWhen>
            <ShowWhen persmissions={[Permission.delete_stack]}>
            <Tooltip title="Slet" aria-label="delete">
              <Fab
                size="small"
                className={classes.deleteButton}
                onClick={(e) => {
                  this.setState({
                    ...this.state, dialog: {
                      open: true,
                      onConfirm: this.onDelete,
                      title: "Sletning stak!",
                      text: "Sletning af stakken er ikke muligt at fortryde!"
                    }
                  })
                }}
              >
                <ActionDelete />
              </Fab>
            </Tooltip>
            </ShowWhen>
            <Tooltip title="Vis" aria-label="vis">
              <Fab
                size="small"
                className={classes.showButton}
                onClick={(e) => this.onShow(e)}
              >
                <VisibilityIcon />
              </Fab>
            </Tooltip>
            {(this.props.responsive.isMobile || this.props.responsive.isTablet) &&
              <Tooltip title="Åben rute i maps" aria-label="open-route">
                <Fab
                  size="small"
                  // className={showJobDetail?.id === job.id ? classes.showButtonSelected : classes.showButton}
                  onClick={(e) => this.onNavigate(e, stack)}
                >
                  < NavigationIcon />
                </Fab>
              </Tooltip>}
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.state.expanded,
              })}
              onClick={this.handleExpandClick}
              aria-expanded={this.state.expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>}
          <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph>Noter:</Typography>
              <Typography>
                {stack?.note}
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
        <DeleteDialog
          open={this.state.dialog.open}
          closeDialog={this.closeDialog}
          dialogTitle={this.state.dialog.title}
          dialogText={this.state.dialog.text}
        />
      </>
    );
  }

}

const mapStateToProps = state => (
  {
    selectStacksById: (id) => selectStacksById(state, id),
    lastEditAmount: selectLastEditAmount(state),
    itemState: selectNewItemState(state)
  })

function mapDispatchToProps(dispatch) {
  return {
    deleteStack: (arg) => dispatch(deleteStack(arg)),
    setEditFeature: (arg) => dispatch(setEditFeature(arg)),
    setDraw: (arg) => dispatch(setDraw(arg)),
    closePopUp: () => dispatch(setClosePopUp()),
    setItemState: (arg) => dispatch(setNewItemState(arg)),
    clearItemState: () => dispatch(clearNewItemState()),
    setLastEditAmount: (amount) => dispatch(setLastEditAmount(amount)),
    updateStack: (arg) => dispatch(updateStack(arg)),
    openUtilPanel: (arg) => dispatch(openDrawer(arg)),
    setUtilPanelTab: (arg) => dispatch(changeTab(arg))
  };
}

export default withResponsive(withAuth0(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(StackInfoPopUp))))