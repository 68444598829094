
import { withAuth0 } from "@auth0/auth0-react";
import { Container } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { bbox, union } from '@turf/turf';
import React from 'react';
import { Layer } from "react-mapbox-gl";
import { connect } from "react-redux";
import { fetchDKFields } from '../../redux/slicers/customerSlice';
import { createOwnFields, fetchOwnFields, selectFields } from '../../redux/slicers/fieldSlice';
import { clearFieldSelection, selectedFieldsIds, setPopUpAllowed } from '../../redux/slicers/mapSlice';
import FieldsLayer, { endCentroids } from '../../services/maps/FieldLayer';
import { MapboxContext } from '../../services/maps/MapComponent';
import CustomerForm, { validateCustomer, validateCVR } from "./CustomerForm";
import {withResponsive} from "../../components/Responsive"
import CustomerFieldInfoPopUp from "./CustomerFieldInfoPopUp";
import { POPUPTYPE, NEW_ITEM_TYPE } from "../../services/maps/LayerTypes";



const useStyles = {
    fetchFields: {
        padding: '10px 0',
        textAlign: 'center',
        flex: 1,
    },
    root: {
        flexGrow: 1,
        width: '100%',
      },
    btnToolbar:{
        position: 'absolute',
        zIndex: '10',
        marginTop: 5,
        marginRight: 5,
        top: 0,
        right: 0,
    },    
    mapUtilContainer: {        
        border: '0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

    },
    fetchBales: {
        padding: '10px',
        textAlign: 'center',
        flex: 1,
    },
    selectLayers: {
        padding: '10px',
        textAlign: 'center',
        flex: 1,
    },
    // paper: {
    //     marginTop: 0,
    //     position: 'absolute',
    //   },
      paperMobile: {
        // maxWidth: '800px',
        marginTop: 0,
        width: '100%',
        position: 'absolute',
      },

    paperDesktop: {
        maxWidth: '600px',
        marginTop: 0,   
        position: 'absolute',
    },
  };


class CustomerMapUtils extends React.Component{

    static contextType = MapboxContext;

    constructor(props){
        super(props);          
        this.handleFetchCustomerFields = this.handleFetchCustomerFields.bind(this)
        this.handleFetchOwnFields = this.handleFetchOwnFields.bind(this)      
        this.updateFields = this.updateFields.bind(this)
        this.handleSelectFieldsToggle = this.handleSelectFieldsToggle.bind(this)
    }

    state = {
        isOpen : true,
        callbackRegistered: false, 
        forceLayerUpdate: true,
        knownFields :[],
        newFields: [],
        selecting: false,
    }

    async componentDidMount(){      
        const { getAccessTokenSilently } = this.props.auth0; 
        const token = await getAccessTokenSilently();
        this.setState({token: token});   
        if(this.props.map === null){          
            this.setState({forceLayerUpdate:true})
            return;
        }
    }

    componentDidUpdate(prevProps){         

        const {customerFields, ownFields} = this.props;
        const {customerFields: prevCustomerFields, ownFields: prevOwnFields} = prevProps;
        console.log("CustomerMapUitl Updated")
        
        if(this.props.map === null){                  
            return;
        }else {            
            if(this.props.searchCVR !== prevProps.searchCVR || this.state.forceLayerUpdate === true){                
                if(validateCVR(this.props.searchCVR) === true){
                    console.log("Customer map util updated -> feth customer fields")
                    this.handleFetchCustomerFields() 
                    this.setState({forceLayerUpdate:false})   
                }                              
            } else if(ownFields !== prevOwnFields || customerFields !== prevCustomerFields){
                this.updateFields()
            }
        }   
    }

    componentWillUnmount(){
        this.props.clearFieldSelection();
        this.props.allowPopUp(true)
    }

    updateFields(){
        const map = this.context;

        var knownFields = []
        var newFields = []

        // Convert EU field to a field that the Field layer understands
        var customerFields = JSON.parse(JSON.stringify(this.props.customerFields));
        customerFields.map((cfield) =>{       
            var geom = {geometry: cfield.geometry, properties: cfield.properties, type: cfield.type}

            cfield.geometry = geom;
            delete cfield.type;
            delete cfield.properties;
            return cfield
        }            
        )        

        // Seperate in to known and new fields
        console.log(this.props.ownFields)
        customerFields.forEach((cField) => {      
            const existsAt = this.props.ownFields.findIndex((field) => field.geometry.properties.id === cField.geometry.properties.Marknr + cField.geometry.properties.Journalnr);
            (existsAt === -1) ? newFields.push(cField) : knownFields.push(cField);
        })         
        this.setState({newFields : newFields, knownFields: knownFields});
        this.props.clearFieldSelection();  

        // Zoom to a bounding box sourounding all customer fields
        
        var collectionFrom = null
        var collectionTo = null
        if(newFields.length > 0){
            collectionFrom = union.apply(this, newFields.map((fields) => fields.geometry))
        }
        if(knownFields.length > 0){
            collectionTo = union.apply(this, knownFields.map((fields) => fields.geometry))        
        }        
      
        if(collectionFrom && collectionTo){
            map?.fitBounds(bbox(union(collectionFrom,collectionTo)), { speed: 0.5, essential: true, padding: { top: 50, bottom: 50, left: 50, right: 50 } })
        } else if(collectionFrom){
            map?.fitBounds(bbox(collectionFrom), { speed: 0.5, essential: true, padding: { top: 50, bottom: 50, left: 50, right: 50 } })
        } else if(collectionTo){
            map?.fitBounds(bbox(collectionTo), { speed: 0.5, essential: true, padding: { top: 50, bottom: 50, left: 50, right: 50 } })
        }
    }

    async handleFetchCustomerFields(){
        await this.handleFetchOwnFields()
        await this.props.fetchDKFields({search: {cvr: this.props.searchCVR}, token:this.state.token})    
        this.updateFields()       
    }

    async handleFetchOwnFields(){
        await this.props.fetchOwnFields({token:this.state.token})      
    }    

    handleSelectFieldsToggle(newVal){
        console.log(this.state.selecting)
        this.setState({...this.state, selecting:newVal})        
        this.props.allowPopUp(!newVal)
       
    }

    render(){
        const {classes} = this.props
        return (          
                <>             
                {this.state.isOpen === false ? ( 
                    <div className={classes.btnToolbar}>
                        <Button variant="contained" onClick = {() => this.setState({isOpen :true})}>
                            <MenuOpenIcon/>
                        </Button>
                    </div>) :(                    
                    <Drawer  classes={this.props.responsive.isMobile === true? ({paper: classes.paperMobile}) : ({paper: classes.paperDesktop})} variant={"persistent"} anchor={'right'} open={this.state.isOpen} onClose={() => this.setState({isOpen :false})} BackdropProps={{ invisible: true }}>
                        {/* <div className={classes.root}> */}
                            <AppBar position="static" color="default">
                                <Toolbar>
                                    <IconButton onClick={() => this.setState({isOpen :false})}>
                                        <ChevronRightIcon />
                                    </IconButton>                        
                                </Toolbar>
                            </AppBar>                
                             <Container>
                                    <CustomerForm customer = {this.props.customer} isSelectingFields={this.state.selecting} fieldToggle={this.handleSelectFieldsToggle}/>
                            </Container>
                        
                    </Drawer>                           
                    )}   
                    <FieldsLayer fields={this.state.knownFields} visible={this.state.knownFields.length > 0} selectable={false} name='knownFields' color={{ unclicked: '#3434eb', clicked: '#3434eb'} } type={POPUPTYPE.EU_FIELD} >
                        <Layer type='symbol' id={"knownFieldsLabels"} sourceId={'knownFields' + endCentroids} filter={['!',['has', 'point_count']]} maxzoom={10} layout={{'text-field': 'Tilføjet' }} paint={{"text-color": "#202","text-halo-color": "#fff","text-halo-width": 2 }}/> 
                    </FieldsLayer>

                    <FieldsLayer fields={this.state.newFields} visible={this.state.newFields.length > 0} selectable={this.state.selecting}  name='newFields' color={{ unclicked: '#fccf03' , clicked: '#2feb74'}} type={POPUPTYPE.EU_FIELD} >
                        <Layer type='symbol' id={"newFieldsLabels"} sourceId={'newFields' + endCentroids} filter={['!',['has', 'point_count']]} maxzoom={10} layout={{'text-field': ["get", "Afgroede"] }} paint={{"text-color": "#202","text-halo-color": "#fff","text-halo-width": 2 }}/> 
                    </FieldsLayer>
                </>                      
        );
    }
}

const mapStateToProps = state => ({
    customerFields: state.customers.customerFields,
    searchCVR: state.customers.searchCVR,
    ownFields: selectFields(state),
    selectedCustomerFieldsId: selectedFieldsIds(state),
})

function mapDispatchToProps(dispatch){
    return{
        fetchDKFields: (arg) => dispatch(fetchDKFields(arg)), //{cvr: {cvr:searchCVR}, token:token}
        fetchOwnFields: (arg) => dispatch(fetchOwnFields(arg)),
        createOwnFields: (arg) => dispatch(createOwnFields(arg)),
        clearFieldSelection: () => dispatch(clearFieldSelection()),
        allowPopUp: (arg) => dispatch(setPopUpAllowed(arg))      
    };
}

export default withResponsive(withAuth0(connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(CustomerMapUtils))));
