import {
  ArgumentAxis,
  BarSeries, Chart, ValueAxis
} from '@devexpress/dx-react-chart-material-ui';
import { Tooltip } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import { blue, common, green, grey, red } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import center from "@turf/center";
import clsx from 'clsx';
import _ from "lodash";
import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StackStatus } from "../../models/stack";
import { openDrawer, selectNewItemState } from "../../redux/slicers/dashboardSlice";
import { selectPopUp, setPopUp } from "../../redux/slicers/mapSlice";
import { POPUPTYPE, NEW_ITEM_TYPE} from "../../services/maps/LayerTypes";
import { MapboxContext } from "../../services/maps/MapComponent";
import { useResponsive } from '../Responsive';



const grey500 = grey["500"];
const green400 = green["400"];

const white = common.white;

const useStyles = makeStyles((theme) => ({
  root: {
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatarTransport: {
    backgroundColor: blue[500],
  },
  avatarBale: {
    backgroundColor: green[500],
  },
  editButtonIcon: {
    fill: white,
  },
  editButton: {
    marginRight: "1em",
    color: white,
    backgroundColor: green400,
  },
  showButtonSelected: {
    backgroundColor: green400,
  },
  showButton: {
    // marginLeft: "2em"
  },
  deleteButton: {
    color: "white",
    marginRight: "1em",
    backgroundColor: red[500],
  },
  doneButtonIcon: {
    fill: white,
  },
  doneButton: {
    marginRight: "1em",
    color: white,
    backgroundColor: green400,
  },
  cancelButton: {
    color: "grey",
    fill: grey500,
  }
}));


export const getTextFromStatus = (Status) => {
    switch (Status) {
      case StackStatus.OPEN:
        return "Åben"
  
      case StackStatus.CLOSED:
        return "Lukket"
  
      case StackStatus.FINISEHED:
        return "Færdig"

      default:
        return "ukendt";
    }
  }

export function SelectionCount({text, count}) {
    return (
    <Grid item>
        <Typography variant="body1" >
        {text} : {count}
        </Typography>
    </Grid>
    )
}

export function StackContent({ stack , height}) {


    function data(stack){
      var array = []
      array.push({key: "Aktuel antal", amount: stack.currentAmount})
      array.push({key: "Ønsket antal", amount: stack.requestedAmount})     
      console.log(array)
      return array
      
    }   

    return (
     <Grid container direction="row" spacing = {2}  alignItems="stretch">  
        <Grid item xs>
           <Chart data={data(stack)} height={height}>
              <ArgumentAxis />
              <ValueAxis />
                <BarSeries valueField="amount" argumentField="key"  />
          </Chart>          
         </Grid>
    </Grid>
    )}



export function StackTile({ stack }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const popup = useSelector(selectPopUp)
  const dispatch = useDispatch()
  const itemState = useSelector(selectNewItemState)
  const map = useContext(MapboxContext)  
  const boxRef = useRef(null);
  const responsive = useResponsive()

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if(popup.id === stack.id){
      console.log("Scrolling")
      boxRef?.current?.scrollIntoView();
    }
  },[popup,stack,boxRef])

  const onShow = (e, stack) => {
    if(!_.isEmpty(stack.location)){
        const coord = center(stack.location).geometry.coordinates    
        map?.flyTo({center: coord, essential: true})
    }

    // Show popup 
    dispatch(setPopUp({id: stack.id, type: POPUPTYPE.STACK}))

    if(responsive.isMobile === true){    
      dispatch(openDrawer(false))
    }

  }

  return (
    <Grid item>
      <Card className={classes.root}>
        <CardHeader     
          title={stack.name !== undefined ? stack.name : "Stak"}    
          subheader={"Status: " + getTextFromStatus(stack.status)}
        />
        <CardContent>
           <StackContent stack ={stack} height = {200}/>
        </CardContent>
        <CardActions disableSpacing>
          {itemState.isEditing !== true && <Tooltip title="Vis" aria-label="vis">
            <Fab
              size="small"
              className={popup.id === stack.id ? classes.showButtonSelected : classes.showButton}
              onClick={(e) => onShow(e, stack)}
            >
              <VisibilityIcon />
            </Fab>
          </Tooltip>}
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Noter:</Typography>
            <Typography>
              {stack?.note}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  )
}

