import { useAuth0 } from "@auth0/auth0-react";
import { ListItemIcon, Tooltip } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { blue, common, green, grey } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaleJob } from "../../models/baleJob";
import { JobStatus, JobType } from '../../models/job';
import { LocationType } from "../../models/location";
import { TransportJob } from "../../models/transportJob";
import { JobReference, ServiceUser } from "../../models/user";
import { selectCustomerById, selectCustomers } from "../../redux/slicers/customerSlice";
import { clearNewItemState, clearSelection, selectLayerVisible, selectNewItemState, selectSelection, setNewItemState, setSelection, setSelectkey, setShowJobDetails } from '../../redux/slicers/dashboardSlice';
import { assignToJob, createJob, selectAssignedUsers,setAssignedUsers, updateJob } from '../../redux/slicers/jobsSlice';
import { clearAllSelections, mapSetSelectedFieldIds, mapSetSelectedStackIds, selectedFieldsIds, selectedStacksIds, setSelectablility, setVisibility } from '../../redux/slicers/mapSlice';
import { selectUsers, updateUser, updateUserMetaData } from "../../redux/slicers/userSlice";
import { BaleJobIcon, getTextFromStatus, SelectionCount, TransportJobIcon } from './JobTile';

//layers

const grey500 = grey["500"];
const green400 = green["400"];
const white = common.white;

const useStyles = makeStyles((theme) => ({
  root: {
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatarTransport: {
    backgroundColor: blue[500],
  },
  avatarBale: {
    backgroundColor: green[500],
  },
  editButtonIcon: {
    fill: white,
  },
  editButton: {
    marginRight: "1em",
    color: white,
    backgroundColor: green400,
  },
  deleteButton: {
    color: "grey",
    fill: grey500,
  },
  doneButtonIcon: {
    fill: white,
  },
  doneButton: {
    marginRight: "1em",
    color: white,
    backgroundColor: green400,
  },
  cancelButton: {
    color: "grey",
    fill: grey500,
  },
  selected: {  
    backgroundColor: "green",
  }  
}));


export function NewBaleJobContent() {
  const selection = useSelector(selectSelection)
  const selectedFields = useSelector(selectedFieldsIds)
  const customers = useSelector(selectCustomers)
  const itemState = useSelector(selectNewItemState)
  const dispatch = useDispatch()
  const toCustomer = useSelector((state) => selectCustomerById(state,itemState.job?.toCustomerId))
  const fromCustomer = useSelector((state) => selectCustomerById(state,itemState.job?.atCustomerId))
  const users = useSelector(selectUsers)
  const assignedUsers = useSelector(selectAssignedUsers)
  const layersVisible = useSelector(selectLayerVisible)

  // Show all selected fields when new key is selected
  const handleSelect = (event,newVal) => {  
      console.log(newVal) 
      if(newVal !== null){
        dispatch(setSelectkey(newVal))
        dispatch(clearAllSelections())
        dispatch(mapSetSelectedFieldIds(selection[newVal][LocationType.FIELD]))
        dispatch(setSelectablility({fieldsLayer: true, stackLayer : false, balesLayer: false}))
        dispatch(setShowJobDetails({}))
        dispatch(setVisibility(layersVisible))     
      } else {
        dispatch(setSelectkey(""))
        dispatch(setShowJobDetails(itemState.job))
        dispatch(setVisibility({fieldsLayer: false,
          balesLayer: false,
          stackLayer: false,}
        ))
      }
  }

  const handleToCustomerSelect = (event, newVal) => {
    console.log(newVal)
    dispatch(setNewItemState({ ...itemState, job: {...itemState.job ,toCustomerId: newVal?.id}}))
  }

  const handleFromCustomerSelect = (event, newVal) => {
    dispatch(setNewItemState({ ...itemState, job: { ...itemState.job, atCustomerId: newVal?.id}}))
  }

  const handleUserAssign = (event, newVal) => {
    dispatch(setAssignedUsers(newVal))
  }

  // Update dashboard selection whenever selected items on the layer changes
  useEffect(() => {
    if(selection.key !== ""){
      console.log(selectedFields)
       dispatch(setSelection({[LocationType.FIELD] : selectedFields}))
    }
  },[selectedFields,dispatch])

  useEffect(() => {    
    dispatch(setNewItemState({...itemState, job: {...itemState.job,onLocation: updateSelectedFields(selection.onLocation)}}))
  }, [selection, dispatch])


  useEffect(() => {
    var initialUsers = []
    initialUsers = users.filter(user => {
      const index = user.assignedJobs?.findIndex((job) => {
        console.log(job)
        return job?.jobID === itemState.job?.id
      })
      return !(index === -1)
    })

    console.log(initialUsers)
    dispatch(setAssignedUsers(initialUsers))    
  }, [])

  return (
    <Grid container direction="column" spacing={4}>
      <Grid container item direction="row" spacing={2} alignItems='center'>
        <Grid item={true} >
          <ToggleButtonGroup value={selection.key}  onChange={handleSelect} exclusive>
              <ToggleButton value = "onLocation">
                 Vælg marker
            </ToggleButton>
        </ToggleButtonGroup>
        </Grid>
        {SelectionCount('Marker', selection.onLocation[LocationType.FIELD].length)}        
      </Grid>
      <Grid item >
            <Autocomplete
            id="combo-from-customer"
            options={customers}
            value = {fromCustomer}
            getOptionLabel={(option) => option.name}
            onChange = {handleFromCustomerSelect}
            // style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Hos kunde" variant="outlined" />}
          />
      </Grid>
      <Grid item >
            <Autocomplete
            id="combo-to-customer"
            options={customers}
            value = {toCustomer}
            getOptionLabel={(option) => option.name}
            onChange = {handleToCustomerSelect}
            // style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Til kunde" variant="outlined" />}
          />
      </Grid>
       <Grid item >
            <Autocomplete
            multiple
            id="combo-assigned-users"
            options={users}
            value = {assignedUsers}
            getOptionLabel={(option) => option.name}
            // filterSelectedOptions
            onChange = {handleUserAssign}
            // style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Tilknyttede brugere" variant="outlined" />}
          />
      </Grid>
    </Grid>
  )
}



export function NewTransportJobContent() {
  const selection = useSelector(selectSelection)
  const selectedFields = useSelector(selectedFieldsIds)
  const selectedStacks = useSelector(selectedStacksIds)
  const classes = useStyles();
  const dispatch = useDispatch()
  const itemState = useSelector(selectNewItemState)
  const users = useSelector(selectUsers)
  const assignedUsers = useSelector(selectAssignedUsers)
  const layersVisible = useSelector(selectLayerVisible)
  
  // Show all selected fields when new key is selected
  const handleSelect = (event,newVal) => {   
      if(newVal !== null){
        dispatch(setSelectkey(newVal))
        dispatch(clearAllSelections())
        dispatch(mapSetSelectedFieldIds(selection[newVal][LocationType.FIELD]))
        dispatch(mapSetSelectedStackIds(selection[newVal][LocationType.STACK]))
        dispatch(setSelectablility({fieldsLayer: true, stackLayer : true, balesLayer: false}))
        dispatch(setShowJobDetails({}))
        dispatch(setVisibility(layersVisible)) 
      } else {
        dispatch(setSelectkey(""))
        dispatch(setShowJobDetails(itemState.job))
        dispatch(setVisibility({fieldsLayer: false,
          balesLayer: false,
          stackLayer: false,}
        ))
      }
  }

  // Update dashboard selection whenever selected items on the layer changes
  useEffect(() => {
      if(selection.key !== ""){
        dispatch(setSelection({[LocationType.FIELD] : selectedFields}))
        dispatch(setSelection({[LocationType.STACK] : selectedStacks}))
      }

  },[selectedStacks,selectedFields,dispatch])


  useEffect(() => {    
    dispatch(setNewItemState({...itemState, job: {...itemState.job, toLocation: updateSelectedFields(selection.toLocation), fromLocation: updateSelectedFields(selection.fromLocation)}}))
  }, [selection, dispatch])

  useEffect(() => {
    var initialUsers = []
    initialUsers = users.filter(user => {
      const index = user.assignedJobs?.findIndex((job) => {
        console.log(job)
        return job?.jobID === itemState.job?.id
      })
      return !(index === -1)
    })
    dispatch(setAssignedUsers(initialUsers))    
  }, [])

  const handleUserAssign = (event, newVal) => {
    dispatch(setAssignedUsers(newVal))
  }

  return (
    <Grid container direction="column" spacing={4}>
      <Grid container item direction="row" spacing={2} alignItems='center'>
        <Grid item={true} >
          <ToggleButtonGroup value={selection.key}  onChange={handleSelect} exclusive>
              <ToggleButton value = "toLocation">
                 Vælg destinationer
            </ToggleButton>
        </ToggleButtonGroup>
        </Grid>
        {SelectionCount('Marker', selection.toLocation[LocationType.FIELD].length)}
        {SelectionCount('Stakke', selection.toLocation[LocationType.STACK].length)}        
      </Grid>

      <Grid container item direction="row" spacing={2} alignItems='center'>
        <Grid item>
        <ToggleButtonGroup value={selection.key}  onChange={handleSelect} exclusive>
              <ToggleButton value="fromLocation" classes ={{selected: classes.selected}}>
                 Vælg afhenting
            </ToggleButton>
        </ToggleButtonGroup>
        </Grid>
        {SelectionCount('Marker', selection.fromLocation[LocationType.FIELD].length)}
        {SelectionCount('Stakke', selection.fromLocation[LocationType.STACK].length)}
      </Grid>

      <Grid item >
            <Autocomplete
            multiple
            id="combo-assigned-users"
            options={users}
            value = {assignedUsers}
            getOptionLabel={(option) => option.name}
            // filterSelectedOptions
            onChange = {handleUserAssign}
            // style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Tilknyttede brugere" variant="outlined" />}
          />
      </Grid>
    </Grid>
  )
}


const updateSelectedFields = (selection) => {
    var locations = []
    Object.keys(selection).forEach((key) => {
      selection[key].forEach((locationID) => {
        locations.push({type: key, id: locationID})
      })          
    })

    return locations
}



export function EditJobTile() {
  const classes = useStyles();
  const selection = useSelector(selectSelection)
  const itemState = useSelector(selectNewItemState)
  const assignedUsers = useSelector(selectAssignedUsers)
  const users = useSelector(selectUsers)
  const dispatch = useDispatch()
  // const newJob = useSelector(selectNewJob)
  const { getAccessTokenSilently,user } = useAuth0();    
  
  useEffect(() => {
 
       
  },[])

  

  const onDone = async (e) => {

    const token = await getAccessTokenSilently();
    var finishedJob = {}

    finishedJob =  { ...itemState.job, organisationId: user['http://smartbaling.com/metadata'].organisation}
   
      if(itemState.isNew === true){
        console.log(JSON.stringify(finishedJob))
        dispatch(createJob({job: finishedJob, token: token})).then((value) => {
          assignUsers(value.payload)
        })
      } else {
        console.log(JSON.stringify(finishedJob))
        dispatch(updateJob({job: finishedJob, token: token})).then((value) => {
          console.log(value)
          assignUsers(value.payload)
        })

  }

  const assignUsers = async (newJob) => {
    console.log(newJob)
    const token = await getAccessTokenSilently();

          //Assign current job to the correct users only if it is not a new job. 
      // New job does not have a id yet. 
      assignedUsers.forEach((assignedUser) => {
        const index = assignedUser?.assignedJobs?.findIndex((job) => {return job?.jobID === newJob?.id})

        if(index === -1){ // User is not already assigned to this job. 
          var updatedUser = JSON.parse(JSON.stringify(assignedUser))
          const jobReference = new JobReference()
          jobReference.jobID = newJob.id;
          jobReference.jobType = newJob.type;
          jobReference.organisationID = newJob.organisationId;             
          
          updatedUser.assignedJobs.push(jobReference)

          console.log("Assigning user to job")
          dispatch(updateUserMetaData({user: updatedUser, token:token}))      
        }               
      })

      var notAssignedUsers = users.filter((user) => {
          // Check if a user is in the assigned user array
          const index = assignedUsers.findIndex((assignedUser) => {return assignedUser.id === user.id})

          //Return true if not in assigned array
          return (index === -1)
      })

      notAssignedUsers.forEach((notAssignedUser) => {

        //Check if user is assigned to this job, and should not be
        const index = notAssignedUser?.assignedJobs?.findIndex((job) => {return job?.jobID === newJob?.id})
        if(index !== -1){
          //Remove job
          var tmpAssignedUser = JSON.parse(JSON.stringify(notAssignedUser))
          tmpAssignedUser.assignedJobs.splice(index,1)            
          // Update user
          dispatch(updateUserMetaData({user: tmpAssignedUser, token:token}))
        }
      })
    } 

    dispatch(clearAllSelections())
    dispatch(clearSelection())
    dispatch(setSelectablility({fieldsLayer: false, balesLayer: false, stackLayer: false}))
    dispatch(clearNewItemState())

  }

  const onCancel = (e) => {
    dispatch(clearAllSelections())
    dispatch(clearSelection())
    dispatch(setSelectablility({fieldsLayer: false, balesLayer: false, stackLayer: false}))
    dispatch(setAssignedUsers([]))
    dispatch(clearNewItemState())
    dispatch(setShowJobDetails({}))
  }

  const handleChange = (event) => {

    if (event.target.name === 'type') {
      if(event.target.value === JobType.BALING){
        var newJob = new BaleJob()
        if(!itemState.isNew){          
          newJob.note = itemState.job?.note
          newJob.id = itemState.job?.id         
        }
        console.log(newJob)
        dispatch(setNewItemState({...itemState, job: newJob}))  
        dispatch(setSelectablility({fieldsLayer: true, stackLayer : false, balesLayer: false}))

      } else if(event.target.value === JobType.TRANSPORT){
        var newJob = new TransportJob()
        if(!itemState.isNew){     
          newJob.note = itemState.job?.note
          newJob.id = itemState.job?.id         
        }
        console.log(newJob)
        dispatch(setNewItemState({...itemState, job: newJob}))
        dispatch(setSelectablility({fieldsLayer: true, stackLayer : true, balesLayer: false}))
      }    
      dispatch(clearAllSelections())
      dispatch(clearSelection())
    }
  }

  const handleStatusChange = (event) => {
    if (event.target.name === 'status') {
      dispatch(setNewItemState({ ...itemState, job: {...itemState.job, status: event.target.value }}))
    }
  }

  const handleNotesChanged = (event) => {
    dispatch(setNewItemState({ ...itemState, job: {...itemState.job, note: event.target.value }}))
  }
  return (
    <Grid item>
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="type-native-helper">Type</InputLabel>
              <Select
                value={itemState.job?.type}
                onChange={handleChange}
                disabled= {itemState.isNew === false}
                inputProps={{
                  name: 'type',
                  id: 'type-native-helper',
                }}              >
                <MenuItem value={JobType.BALING}> <ListItemIcon> <BaleJobIcon /> </ListItemIcon> </MenuItem>
                <MenuItem value={JobType.TRANSPORT}><ListItemIcon> <TransportJobIcon /></ListItemIcon> </MenuItem>
              </Select>
            </FormControl>
          }
          // subheader={"Status: " + getTextFromStatus(editJob.status)}
          subheader={ <FormControl className={classes.formControl}>
              <InputLabel htmlFor="status-native-helper">Status</InputLabel>
              <Select
                value={itemState.job?.status}
                onChange={handleStatusChange}
                inputProps={{
                  name: 'status',
                  id: 'status-native-helper',
                }}              >
                <MenuItem value={JobStatus.NEW}> {getTextFromStatus(JobStatus.NEW)}</MenuItem>
                <MenuItem value={JobStatus.CREATED}> {getTextFromStatus(JobStatus.CREATED)}</MenuItem>
                <MenuItem value={JobStatus.READY}> {getTextFromStatus(JobStatus.READY)}</MenuItem>
                <MenuItem value={JobStatus.IN_PROGRESS}> {getTextFromStatus(JobStatus.IN_PROGRESS)}</MenuItem>
                <MenuItem value={JobStatus.FINISHED}> {getTextFromStatus(JobStatus.FINISHED)}</MenuItem>
              </Select>
            </FormControl>}
        />
        <CardContent>
          {itemState.job?.type === JobType.BALING ? <NewBaleJobContent  /> : <NewTransportJobContent  />}
        </CardContent>

        <CardContent>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            defaultValue={itemState.job?.note}
            onBlur={handleNotesChanged}
            label="Noter"
            multiline
            variant="outlined"
          />
        </CardContent>
        <CardActions disableSpacing>

          <Tooltip title="Accepter" aria-label="edit">
            <Fab
              size="small"
              className={classes.doneButton}
              onClick={(e) => onDone(e)}
            >
              <DoneIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Fortryd" aria-label="delete">
            <Fab
              size="small"
              className={classes.cancelButton}
              onClick={(e) => onCancel(e)}
            >
              <CancelIcon />
            </Fab>
          </Tooltip>
        </CardActions>
      </Card>
    </Grid>
  )
}

