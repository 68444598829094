import React from  'react';


import { Map } from "../../services/maps"
import DashboardUtilPanel from './DashboardUtilPanel'
import { useDispatch, useSelector } from 'react-redux'
import { selectBales } from '../../redux/slicers/baleSlice'
import { selectFields } from '../../redux/slicers/fieldSlice'
import  FieldsLayer, { endCentroids, getColorFromStatus }  from '../../services/maps/FieldLayer'
import { BaleLayer } from '../../services/maps/BaleLayer'
import { useEffect } from 'react';
import { selectDraw, selectLayersSelectable, selectMapLayersVisible, selectPopUpAlowed, setVisibility } from '../../redux/slicers/mapSlice';
import { selectLayerVisible, selectNewItemState, selectShowJobDetails } from "../../redux/slicers/dashboardSlice"
import {JobDetailLayers} from "../Dashboard/DashBoardUtilPanelJobs"
import _ from "lodash"
import EditFeature  from "../../services/maps/EditFeature"
import StackLayer from "../../services/maps/StackLayer"
import { selectStacks } from "../../redux/slicers/stackSlice"
import { FieldStatus } from "../../models/organisationField"
import DashboardLayerSelect from './DashboardLayerSelect';
import PopUpHandler from '../../services/maps/PopUpHandler';
import { POPUPTYPE } from '../../services/maps/LayerTypes';
import { Layer } from "react-mapbox-gl";
import DrawControl from './DrawControl';
const DashboardView = () => {
    // const layersVisible = useSelector(selectMapLayersVisible)
    const layersSelectable = useSelector(selectLayersSelectable)
    const fields = useSelector(selectFields)
    const stacks = useSelector(selectStacks)
    const bales = useSelector(selectBales)
    const layersVisible = useSelector(selectMapLayersVisible)
    const dashLayersVisible = useSelector(selectLayerVisible)
    const jobDetails = useSelector(selectShowJobDetails)
    const dispatch = useDispatch()
    const draw = useSelector(selectDraw)


    // Update visibility based on dashboard states
    useEffect(() => {
        dispatch(setVisibility(layersVisible))       

    }, [layersVisible,dispatch])

    useEffect(() => {
        dispatch(setVisibility(dashLayersVisible))
    }, [dashLayersVisible,dispatch])


 
    {console.log(layersSelectable)}
    return (
       
        <Map>     
            {/* { Object.values(FieldStatus).map((value) => {   
                        var filterFields = []                     
                        filterFields = fields.filter((field) => {return field.status === value})
                        if(filterFields.length > 0 && layersVisible.fieldsLayer === true){
                            return ( <FieldsLayer fields={filterFields}  visible = {layersVisible.fieldsLayer} selectable={layersSelectable.fieldsLayer} name={'fields'+ value} color={{ unclicked: getColorFromStatus(value), clicked: '#2feb74'}} type={POPUPTYPE.FIELD}>
                                            <Layer type='symbol' id={"baleAmount" + value} sourceId={'fields'+ value + endCentroids} filter={['!',['has', 'point_count']]} maxzoom={10} layout={{'text-field': ["get", "currentAmountOfBales"] }} paint={{"text-color": "#202","text-halo-color": "#fff","text-halo-width": 2 }}/> 
                                    </FieldsLayer>
                        )
                        }
                })
            }  */}
            <FieldsLayer fields={fields}  visible = {layersVisible.fieldsLayer} selectable={layersSelectable.fieldsLayer} name={'fields'} color={{ unclicked: getColorFromStatus(FieldStatus.READY_FOR_BALING), clicked: '#2feb74'}} type={POPUPTYPE.FIELD}>
                        <Layer type='symbol' id={"baleAmount"} sourceId={'fields' + endCentroids} filter={['!',['has', 'point_count']]} maxzoom={10} layout={{'text-field': ["get", "currentAmountOfBales"] }} paint={{"text-color": "#202","text-halo-color": "#fff","text-halo-width": 2 }}/> 
            </FieldsLayer>
            <StackLayer id ={" Dash"} stacks={stacks}  visible = {layersVisible.stackLayer} selectable={layersSelectable.stackLayer} name='stacks' color={{ unclicked: '#1620f0', clicked: '#2feb74'}} type={POPUPTYPE.STACK} />
            <BaleLayer bales={bales} />
            {!_.isEmpty(jobDetails) && <JobDetailLayers />}
            <EditFeature/>
            <DashboardUtilPanel />
            <DashboardLayerSelect/>           
            <PopUpHandler allowNewField = {true}/>
        </Map>
    )
};

export default DashboardView;