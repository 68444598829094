//import UserView from "./UserView";
import React from "react";
import Fab from "@material-ui/core/Fab";
import ContentAdd from "@material-ui/icons/Add";
import Snackbar from "@material-ui/core/Snackbar";
import {Tooltip } from "@material-ui/core";
import DataTable from "../../components/DataTable"
import SkeletonList from "../../components/SkeletonList"
import DeleteDialog from "../../components/DeleteDialog"
import Alert from "../../components/Alert";
import { listPageStyle } from "../../styles";
import SearchBar from "material-ui-search-bar";

import {withAuth0 } from "@auth0/auth0-react";
import { connect } from "react-redux";
import SLUGS from '../../navigation/slugs';
import { fetchUsers, resetStatus,searchUsers,userFiltered,deleteUser,selectUserById,resetDetailedUser} from '../../redux/slicers/userSlice'
import { ShowWhen } from "../../navigation/role-renderer/ShowWhen";
import { Permission } from "../../navigation/role-renderer/Permissions";



const { Component } = require("react");
const styles = listPageStyle;

const headers =["","Name","Mobile","Actions",];
const dataKeys = ["avatar", "name", "phone", "actions",];

class UserContainer extends Component{
    
    constructor(props){
        super(props);     

        this.handleToggle = this.handleToggle.bind(this)
        this.handleSearchFilter = this.handleSearchFilter.bind(this)     
        this.onPageChange = this.onPageChange.bind(this)
        this.openDialog = this.openDialog.bind(this)
        this.closeDialog = this.closeDialog.bind(this)
        this.onSnackBarClose = this.onSnackBarClose.bind(this)
        this.handleSearchFilter = this.handleSearchFilter.bind(this)
        this.handleNewUser = this.handleNewUser.bind(this)
        this.handleEditUser = this.handleEditUser.bind(this)

        this.state = {
            token:"",
            open: false,
            searchOpen: false,
            snackbarOpen: false,
            autoHideDuration: 2000,
            page: 1,
            items: [],
            totalPages: 1,
            customerId: null,
            customerName: "",         
            search: "",
          };
    }

    async componentDidMount(){  
        const { getAccessTokenSilently } = this.props.auth0;        
        const token = await getAccessTokenSilently();
        this.setState({token: token,
                        search: ""});
        this.props.fetchUsers(token);
        this.props.searchUsers(this.state.search)
    }

    componentDidUpdate(prevProps){
        if (this.props.users !== prevProps.users) {
            //this.setState({ customerList: this.props.customerList });
            const page = 1;
            const totalPages = Math.ceil(this.props.users.length / 10);
            const items = this.props.users.slice(0,10);       
            this.setState({ page, totalPages, items });
          }
          //console.log(" this.props.deleted " + this.props.deleted);
      
          if (
            this.props.status !== prevProps.status && (this.props.status === 'success' ||  this.props.status === 'error')
          ) {
            console.log("Openeing snackbar")
            console.log(this.state.autoHideDuration)
            this.setState({ snackbarOpen: true });            
          } 
    }

    onPageChange(_event, page) {
        const startIndex = (page - 1) * 10;
        const endIndex = startIndex + 10;
        const items = this.props.users.slice(startIndex, endIndex);
        this.setState({ page, items });
      }
    
    openDialog(_event, value) {
        console.log(value)
        this.setState({userName: this.props.selectUserById(value).name})
        if (value != null) {
            this.setState({ open: true, userId: value });
        }
    }

    closeDialog(isConfirmed) {
        this.setState({ open: false });     
        if (isConfirmed && this.state.userId) {
            this.props.deleteUser({user: this.props.selectUserById(this.state.userId), token : this.state.token});
            this.setState({ userId: null, userName : ""});
        }
    }

    
    handleToggle() {
        this.setState({ searchOpen: !this.state.searchOpen });
    }

    handleSearchFilter(searchval){       
        this.setState({ search: searchval});
        this.props.searchUsers(searchval)              
    } 

    onSnackBarClose(event,reason) {
        console.log("Close snack bar")
        console.log(reason)
        if(reason !== 'clickaway'){
            this.setState({snackbarOpen: false});
            this.props.resetStatus()
        }
    }

    handleNewUser() {    
        this.props.resetDetailedUser()
        this.props.history.push(SLUGS.newUser);
      }

    handleEditUser(_event,id){
        this.props.history.push('/users/' + id);
      }
  

    render() {        
        const {status} = this.props;
        const {page, totalPages, items } = this.state;  
        const model = "user"
        return (
         <div style={styles.container}>
            {status === 'loading' ? (              
                <SkeletonList />
            ) : (
              <div>
                <div>
                  <ShowWhen persmissions={[Permission.create_user]}>
                    <Tooltip title="Add" aria-label="add">
                      <Fab
                        size="small"
                        color="secondary"
                        style={styles.fab}
                        onClick={this.handleNewUser}
                      >
                        <ContentAdd />
                      </Fab>
                    </Tooltip>     
                  </ShowWhen>      
                </div>
                <Snackbar
                  open={this.state.snackbarOpen}
                  autoHideDuration={this.state.autoHideDuration}
                  onClose={this.onSnackBarClose}
                >
                  <Alert severity={this.props.status}>
                    {this.props.statusMsg}
                  </Alert>
                </Snackbar>
                <SearchBar
                    value={this.state.search}
                    onChange={this.handleSearchFilter}
                    onRequestSearch={this.handleSearchFilter}
                />
                <DataTable
                  model={model}
                  items={items}
                  dataKeys={dataKeys}
                  headers={headers}
                  page={page}
                  totalPages={totalPages}
                  onDelete={this.openDialog}
                  onEdit = {this.handleEditUser}
                  onPageChange={this.onPageChange}
                  deletePermissions ={[Permission.delete_user]}
                  editPermissions = {[Permission.edit_user]}
                />
    
                <DeleteDialog
                  open={this.state.open}
                  closeDialog={this.closeDialog}
                  dialogTitle = "Slet bruger"
                  dialogText = {this.state.userName}
                />
                {/* <React.Fragment>
                  <Drawer
                    anchor="right"
                    open={this.state.searchOpen}
                    onClose={this.handleToggle}
                    style={styles.searchDrawer}
                  >
                    <Grid container spacing={0} style={styles.searchGrid}>
                      <Grid item style={styles.searchField}>
                        <h5>Search</h5>
                      </Grid>
                      <Grid item xs={12} style={styles.searchField}>
                        <TextField
                          placeholder="Contains"
                          label="Contains"
                          name="contains"
                          fullWidth={true}
                          value={this.state.search}
                          onChange={this.handleSearchFilter}
                        />
                      </Grid>                   
                    </Grid>
                  </Drawer>
                </React.Fragment> */}
              </div>
            )}
        </div>
        );
      }
}

const  mapStateToProps = state => ({users : searchUsers(state),
                                     status: state.users.status,
                                     statusMsg: state.users.statusMsg,
                                     selectUserById: (id) => selectUserById(state,id)})

function mapDispatchToProps(dispatch) {
    return {
        fetchUsers: (token) => dispatch(fetchUsers(token)),
        searchUsers: (search) => dispatch(userFiltered(search)),
        deleteUser: (arg) => dispatch(deleteUser(arg)),
        resetStatus: () => dispatch(resetStatus()),
        resetDetailedUser: () => dispatch(resetDetailedUser())        
    };
  }
  
export default withAuth0(connect(mapStateToProps, mapDispatchToProps)(UserContainer));
