import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { blue, common, green, grey } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldStatus } from "../../models/organisationField";
import { selectCustomers } from "../../redux/slicers/customerSlice";
import { clearNewItemState, selectNewItemState, setNewItemState } from '../../redux/slicers/dashboardSlice';
import { setEditField, updateOwnFields } from '../../redux/slicers/fieldSlice';
import { selectEditFeature, setEditFeature, setDraw, setSelectablility, setClosePopUp } from '../../redux/slicers/mapSlice';
import { getTextFromStatus } from './FieldTile';
import GestureIcon from '@material-ui/icons/Gesture';
import { ShowWhen } from "../../navigation/role-renderer/ShowWhen";
import Permission from "../../navigation/role-renderer/Permissions";

//layers

const grey500 = grey["500"];
const green400 = green["400"];
const white = common.white;

const useStyles = makeStyles((theme) => ({
  root: {
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatarTransport: {
    backgroundColor: blue[500],
  },
  avatarBale: {
    backgroundColor: green[500],
  },
  editButtonIcon: {
    fill: white,
  },
  editButton: {
    marginRight: "1em",
    color: white,
    backgroundColor: green400,
  },
  deleteButton: {
    color: "grey",
    fill: grey500,
  },
  doneButtonIcon: {
    fill: white,
  },
  doneButton: {
    marginRight: "1em",
    color: white,
    backgroundColor: green400,
  },
  cancelButton: {
    color: "grey",
    fill: grey500,
  },
  selected: {
    backgroundColor: "green",
  }
}));


// export function NewFieldContent({ mapEditFeature, editField }) {

//   const dispatch = useDispatch()
//   const map = useContext(MapboxContext)
//   const customers = useSelector(selectCustomers)
//   const newItem = useSelector(selectNewItemState)
//   // const creatingNewField = useSelector(editFieldIsNewField)
//   const editFeature = useSelector(selectEditFeature)

//   // useEffect(() => {
//   //     dispatch(setDraw(true))
//   //     if(!_.isEmpty(editField.geometry) && _.isEmpty(editFeature)){       
//   //         dispatch(setEditFeature(editField.geometry))
//   //     }
//   //     return () => {

//   //     }
//   // },[])

//   // function goToLocation(){    
//   //   if(!_.isEmpty(mapEditFeature)){
//   //       const coord = center(mapEditFeature).geometry.coordinates
//   //       console.log(coord)
//   //       map?.flyTo({center: coord, zoom: 16, essential: true})
//   //   }    
//   // }  

//   const handleOwnerSelect = (event, newVal) => {
//     dispatch(setNewItemState({ ...newItem, field: { ...newItem.field, ownerID: newVal?.id } }))
//   }
//   return (
//     <>
//     {/* // <Grid item container direction="column" spacing={4}> */}
//       {/* <Grid container item direction="row" spacing={2} alignItems='center'>
//           <Grid item xs={12}>
//             <TextField fullWidth value={_.isEmpty(mapEditFeature) ? "Ingen lokation " : "Lat/Lon : " + center(mapEditFeature).geometry.coordinates} label="Lokation - Klik for fokus" variant="outlined" InputProps={{ readOnly: true }} onClick= {goToLocation} />
//           </Grid>
//         </Grid> */}
//       <Grid item >
//         <Autocomplete
//           id="combo-customer"
//           options={customers}
//           // value = {fromCustomer}
//           getOptionLabel={(option) => option.name}
//           onChange={handleOwnerSelect}
//           // style={{ width: 300 }}
//           renderInput={(params) => <TextField {...params} label="Ejer" variant="outlined" />}
//         />
//       </Grid>
//       <Grid item>
//         <TextField label="Pressede baller" variant="outlined" type="number" InputLabelProps={{
//           shrink: true,
//         }}
//           defaultValue={editField?.originalAmountOfBales}
//           onBlur={(event) => { dispatch(setNewItemState({ ...newItem, field: { ...newItem.field, originalAmountOfBales: event.target.value } })) }}
//         />
//       </Grid>
//       <Grid item >
//         <TextField label="Nuværende antal baller" variant="outlined" type="number" InputLabelProps={{
//           shrink: true,
//         }}
//           defaultValue={editField?.currentAmountOfBales}
//           onBlur={(event) => { dispatch(setNewItemState({ ...newItem, field: { ...newItem.field, currentAmountOfBales: event.target.value } })) }}
//         />

//       </Grid>
//     {/* // </Grid> */}
//     </>
//   )
// }

export function EditFieldTile() {
  const mapEditFeature = useSelector(selectEditFeature)
  const editItem = useSelector(selectNewItemState)
  const classes = useStyles();
  const customers = useSelector(selectCustomers)
  // const creatingNewField = useSelector(editItem.fieldIsNewField)
  const dispatch = useDispatch()
  const { getAccessTokenSilently, user } = useAuth0();
  const boxRef = useRef(null);

  const handleOwnerSelect = (event, newVal) => {
    dispatch(setNewItemState({ ...editItem, field: { ...editItem.field, ownerID: newVal?.id } }))
  }

  const getDefaultCustomer = () => {
     
    return customers.find((customer) => {
      return customer.id === editItem.field?.ownerID
    })
  }

  const onDone = async (e) => {

    const token = await getAccessTokenSilently();
    var finishedField = {}
    var feature = JSON.parse(JSON.stringify(mapEditFeature));
    delete feature.id

    if(_.isEmpty(feature)){
      finishedField = { ...editItem.field}
    } else {
      finishedField = { ...editItem.field,  geometry: {...editItem.field.geometry, geometry: feature.geometry}}
    }
    dispatch(updateOwnFields({ field: finishedField, token: token }))   
    dispatch(setSelectablility({fieldsLayer: false, balesLayer: false, stackLayer: false}))
    dispatch(setEditFeature({}))
    dispatch(setDraw({enableLayer: false,
                      enableEdit: false}))
    dispatch(clearNewItemState())
  }

  const onCancel = (e) => {
    dispatch(setSelectablility({fieldsLayer: false, balesLayer: false, stackLayer: false}))
    dispatch(setEditFeature({}))
    dispatch(setDraw({enableLayer: false,
      enableEdit: false}))
    dispatch(clearNewItemState())
  }

  const handleStatusChange = (event) => {

    if (event.target.name === 'status') {
      dispatch(setNewItemState({ ...editItem, field: {...editItem.field, status: event.target.value }}))
    }
  }

  const handleNotesChanged = (event) => {
    dispatch(setNewItemState({ ...editItem, field: {...editItem.field, note: event.target.value }}))
  }

  const handleEditField = () => {

    dispatch(setDraw({enableLayer: true,
      enableEdit: true, controls: {  polygon: true,
        point: false,
        line_string: false,                    
        trash: true,
        combine_features: false,
        uncombine_features: false,
        }})) 
    dispatch(setEditFeature(editItem.field.geometry.geometry))
    dispatch(setClosePopUp())
  }

  useEffect(() => {
    console.log("Scrolling")
    boxRef?.current?.scrollIntoView();
  }, [boxRef])


  return (
    <Grid item container direction="column" spacing={4}>
      <Grid item>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="status-native-helper">Status</InputLabel>
          <Select
            value={editItem.field?.status}
            onChange={handleStatusChange}
            inputProps={{
              name: 'status',
              id: 'status-native-helper',
            }}              >
            <MenuItem value={FieldStatus.NO_INFO}> {getTextFromStatus(FieldStatus.NO_INFO)}</MenuItem>
            <MenuItem value={FieldStatus.BALING_ORDERED}> {getTextFromStatus(FieldStatus.BALING_ORDERED)}</MenuItem>
            <MenuItem value={FieldStatus.COLLECT_ORDERED}> {getTextFromStatus(FieldStatus.COLLECT_ORDERED)}</MenuItem>
            <MenuItem value={FieldStatus.READY_FOR_BALING}> {getTextFromStatus(FieldStatus.READY_FOR_BALING)}</MenuItem>
            <MenuItem value={FieldStatus.READY_FOR_COLLECT}> {getTextFromStatus(FieldStatus.READY_FOR_COLLECT)}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
     <ShowWhen persmissions={[Permission.edit_field]}>
      <Grid item >
        <Autocomplete
          id="combo-customer"
          options={customers}
          // value = {fromCustomer}
          defaultValue = {getDefaultCustomer()}
          getOptionLabel={(option) => option.name}
          onChange={handleOwnerSelect}
          // style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Ejer" variant="outlined" />}
        />
      </Grid>
      </ShowWhen>
      <Grid item>
        <TextField label="Pressede baller" variant="outlined" type="number" InputLabelProps={{
          shrink: true,
        }}
          defaultValue={editItem.field?.originalAmountOfBales}
          onBlur={(event) => { dispatch(setNewItemState({ ...editItem, field: { ...editItem.field, originalAmountOfBales: event.target.value } })) }}
        />
      </Grid>
      <Grid item >
        <TextField label="Nuværende antal baller" variant="outlined" type="number" InputLabelProps={{
          shrink: true,
        }}
          defaultValue={editItem.field?.currentAmountOfBales}
          onBlur={(event) => { dispatch(setNewItemState({ ...editItem, field: { ...editItem.field, currentAmountOfBales: event.target.value } })) }}
        />

      </Grid>
      

      <Grid item>
        <TextField
          fullWidth
          id="outlined-multiline-static"
          defaultValue={editItem.field?.note}
          onBlur={handleNotesChanged}
          label="Noter"
          multiline
          variant="outlined"
        />
      </Grid>

      <ShowWhen persmissions={[Permission.edit_field]}>
      <Grid item>
        <Button
            variant="contained"
            color="default"
            // className={classes.button}
            startIcon={<GestureIcon />}
            onClick={handleEditField}
          >
            Tegn
          </Button>
      </Grid>
      </ShowWhen>
      <Grid item container direction="row" spacing={2}>
        <Grid item>
          <Tooltip title="Accepter" aria-label="edit">
            <Fab
              size="small"
              className={classes.doneButton}
              onClick={(e) => onDone(e)}
            >
              <DoneIcon />
            </Fab>
          </Tooltip>

        </Grid>
        <Grid item>
          <Tooltip title="Fortryd" aria-label="delete">
            <Fab
              size="small"
              className={classes.cancelButton}
              onClick={(e) => onCancel(e)}
            >
              <CancelIcon />
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  )
}

