import { createSlice,createAsyncThunk, createEntityAdapter  } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

const userAdapter = createEntityAdapter()

const initialState = userAdapter.getInitialState({
    status: 'idle',
    statusMsg: "",
    filter: '',
    detailedUser: {}    
  })
  
export const getUserDetails = createAsyncThunk('users/getUserDetails', async (arg,{rejectWithValue}) => {

    const serverUrl = process.env.REACT_APP_SERVER_URL;  
    console.log("Fetching detailed user at " + serverUrl + "for user :")  
    console.log(arg.user)  
    const response = await fetch(
      `${serverUrl}/api/getUserDetails`,
        {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${arg.token}`,
          },
        body: JSON.stringify(arg.user)  
        }
        
    );   

    const data = await response.json()
    if (response.ok) {  
      console.log(data)     
      return data
    } else {
      return rejectWithValue(response)
    }
})


export const fetchUsers = createAsyncThunk('users/fetchUsers', async (token,{rejectWithValue}) => {

    const serverUrl = process.env.REACT_APP_SERVER_URL;  
    console.log("Fetching users at " + serverUrl)    
    const response = await fetch(
      `${serverUrl}/api/ListUsers`,
        {
        headers: {
            Authorization: `Bearer ${token}`,
          },
        }
    );   

    const data = await response.json()
    if (response.ok) {      
      return data
    } else {
      return rejectWithValue(response)
    }
})

export const deleteUser = createAsyncThunk('users/deleteUser', async (arg,{rejectWithValue, dispatch}) => {

  const serverUrl = process.env.REACT_APP_SERVER_URL;  
  console.log("Deleteing user : ")
  console.log(JSON.stringify(arg.user, null, 2))    
  console.log("with token");
  console.log(arg.token)
  const response = await fetch(
    `${serverUrl}/api/DeleteUser`,
      {
      method: 'DELETE',
      headers: {
          Authorization: `Bearer ${arg.token}`,
        },
        body: JSON.stringify(arg.user)     
      }
  );   

  const data = await response.json()
  if (response.ok) {  
    dispatch(fetchUsers(arg.token)) 
    return data
    } else {
    return rejectWithValue(response)
    }
})

export const createUser = createAsyncThunk('users/createUser', async (arg,{rejectWithValue}) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;  
  console.log("creating user : ")
  console.log(JSON.stringify(arg.user))    
  const response = await fetch(
    `${serverUrl}/api/CreateUser`,
     {
      method: 'POST',
      headers: {Authorization: `Bearer ${arg.token}`},
      body: JSON.stringify(arg.user)      
     }
  );   

  const data = await response.json()
  console.log(data)
  if (response.ok) {   
    return data
  } else {
    return rejectWithValue(data)
  }
})

export const updateUser = createAsyncThunk('users/updateUser', async (arg,{rejectWithValue}) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;  
  console.log("updating user : ")
  console.log(arg.user)    
  const response = await fetch(
    `${serverUrl}/api/UpdateUser`,
      {
      method: 'POST',
      headers: {Authorization: `Bearer ${arg.token}`},
      body: JSON.stringify(arg.user)      
      }
  );   

  const data = await response.json()
    if (response.ok) {   
      return data
    } else {
      return rejectWithValue(data)
    }
})

export const updateUserMetaData = createAsyncThunk('users/updateUserMetaData', async (arg,{rejectWithValue,dispatch}) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;  
  console.log("updating updateUserMetaData : ")
  console.log(arg.user)    
  const response = await fetch(
    `${serverUrl}/api/updateUserMetaData`,
      {
      method: 'POST',
      headers: {Authorization: `Bearer ${arg.token}`},
      body: JSON.stringify(arg.user)      
      }
  );   

  const data = await response.json()
    if (response.ok) {   
      dispatch(fetchUsers(arg.token))
      return data
    } else {
      return rejectWithValue(data)
    }
})


const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {  
    userFiltered(state,action){
        state.filter = action.payload
    },
    resetStatus(state,action){
        state.status = 'idle'
        state.statusMsg = ""
    },
    resetDetailedUser(state,action){
        state.detailedUser = {}
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
         console.log("Fetch users success")
         const newEntities = {}
         const id = []
         action.payload.forEach(user => {
           newEntities[user.id] = user
           id.push(user.id)
         })
        state.ids = id
        state.entities = newEntities
        state.status = 'idle'
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        console.log("User could not be feched") 
        console.log(action)       
      }) 
      .addCase(deleteUser.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        console.log(action.payload.id)
        delete state.entities[action.payload.id]
        state.status = 'success'
        state.statusMsg = "Succesfully deleted user"
      })
      .addCase(deleteUser.rejected, (state, action) => {
        console.log("User could not be deleted deleted")
        state.status = 'error'
        state.statusMsg = action.payload 
      }) 
      .addCase(createUser.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(createUser.fulfilled, (state, action) => {
        console.log(action)
        state.status = 'success'
        state.statusMsg = "Succesfully created user"
      })
      .addCase(createUser.rejected, (state, action) => {
        console.log(action)
        state.status = 'error'
        state.statusMsg = "Could not create user" 
      }) 
      .addCase(updateUser.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        console.log(action)
        state.status = 'success'
        state.statusMsg = "Succesfully Updated user"
      })
      .addCase(updateUser.rejected, (state, action) => {
        console.log(action)
        state.status = 'error'
        state.statusMsg = "Could not update user" 
      }) 
      .addCase(getUserDetails.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.detailedUser = action.payload
        state.status = 'success'
        state.statusMsg = "Succesfully fetched user details"
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        console.log(action)
        state.status = 'error'
        state.statusMsg = "Could not fetch user details" 
      }) 
  }
})

export const {resetStatus,userFiltered,resetDetailedUser} = userSlice.actions

export default userSlice.reducer

export const {
    selectAll: selectUsers,
    selectById: selectUserById
  } = userAdapter.getSelectors(state => state.users)

const getSearchFilter = state => {
    return state.users.filter}

export const searchUsers = createSelector(
    [getSearchFilter,userAdapter.getSelectors(state => state.users).selectAll],
    (searchFilter, users) => {    
        return users.filter(user => { 
            if(user){          
              return (          
              user.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
              user.phone.toString().toLowerCase().includes(searchFilter.toLowerCase()) ||               
              searchFilter === "")
            } else {
              return false
            }           
            
        })
    }
)


export const createSelectByAuth0Id = (auth0ID) => createSelector(
  [userAdapter.getSelectors(state => state.users).selectAll],
    (users) => {
      return users.find(user => user.auth0ID === auth0ID)
    }
)

