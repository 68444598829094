import { useAuth0 } from "@auth0/auth0-react";
import { Paper } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { pink } from "@material-ui/core/colors";
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
//import { createUseStyles } from 'react-jss';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Crop169Icon from '@material-ui/icons/Crop169';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import TextureIcon from '@material-ui/icons/Texture';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import React, { useContext, useEffect } from 'react';
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from 'react-redux';
import { getTextFromStatus } from "../../components/Fields/FieldTile";
import { FieldStatus } from "../../models/organisationField";
//API
import { fetchBales } from '../../redux/slicers/baleSlice';
import { fetchCustomers } from '../../redux/slicers/customerSlice';
import { changeTab, openDrawer, selectDrawerStatus, selectLayerVisible, setLayerVisible, setShowJobDetails } from '../../redux/slicers/dashboardSlice';
import { fetchOwnFields } from '../../redux/slicers/fieldSlice';
import { fetchAllJobs } from '../../redux/slicers/jobsSlice';
import { selectPopUp, setPopUp, setVisibility } from '../../redux/slicers/mapSlice';
import { fetchAllStacks } from "../../redux/slicers/stackSlice";
import { fetchUsers } from '../../redux/slicers/userSlice';
import { getColorFromStatus } from "../../services/maps/FieldLayer";
import DashboardUtilPanelFields from "./DashBoardUtilPanelFields";
import DashboardUtilPanelJobs from './DashBoardUtilPanelJobs';
import DashboardUtilPanelStacks from "./DashBoardUtilPanelStacks";
import { Container } from '@material-ui/core';
import { useResponsive } from "../../components/Responsive";
import {PopUp} from '../../services/maps/PopUp'

import { GeoJSON, WFS } from "ol/format";

import {
    and as andFilter,
    equalTo as equalToFilter,
    like as likeFilter
  } from "ol/format/filter";
import { MapboxContext } from "../../services/maps/MapComponent";
import NewFieldPopUp from "../../services/maps/NewItemPopUp";



const pink500 = pink['500'];

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        width: '100%',
      },
    btnToolbar:{
        position: 'absolute',
        zIndex: '10',
        marginTop: 5,
        marginRight: 5,
        top: 0,
        right: 0,
    },    

    filterView:{
        position: 'absolute',
        zIndex: '10',
        left: '50%',
        transform: 'translate(-50%, 0)',
        marginTop: 5,
        top: 0,   
        padding: '5px' 
    },    

    // filterViewContent:{
    //     padding: '5px'
    // },

    disabledButton: {
        backgroundColor: 'red'
      },

    mapUtilContainer: {        
        border: '0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

    },
    fetchBales: {
        padding: '10px',
        textAlign: 'center',
        flex: 1,
    },
    selectLayers: {
        padding: '10px',
        textAlign: 'center',
        flex: 1,
    },
    paperMobile: {
        // maxWidth: '800px',
        marginTop: 0,
        width: '100%',
        position: 'absolute',
      },

    paperDesktop: {
        maxWidth: '600px',
        marginTop: 0,   
        position: 'absolute',
    },
    fab: {
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        marginRight: 20,
        backgroundColor: pink500, // {pink500}
        },
   
    tab: {
            minWidth: '100px', // a number of your choice
            width: '100px', // a number of your choice
     },
    
    
});


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }



const DashboardUtilPanel = () => {

    const dispatch = useDispatch()
    const { getAccessTokenSilently } = useAuth0(); 
    const balesStatus = useSelector((state) => state.bales.status)
    const drawerStatus = useSelector(selectDrawerStatus) 
    const layersVisible = useSelector(selectLayerVisible) 
    const responsive = useResponsive()

    useEffect(() => {
        getData()    
        return () => {
            console.log("Removinging dashboard util panel")            
          }
    },[])     

    const handleTabChange = async (event, newValue) => {
        dispatch(changeTab(newValue))
        const token = await getAccessTokenSilently();

        //Reset jobdetails on tab change and set layers visible to dashboard settings
        dispatch(setShowJobDetails({}))
        dispatch(setVisibility(layersVisible))       
        if(newValue === 3){
            console.log("Fetching jobs because of tab change")     
            dispatch(fetchUsers(token))          
            dispatch(fetchAllJobs({token:token}))
        } else if(newValue === 2){
            console.log("Fetching stacks because of tab change")            
            dispatch(fetchAllStacks({token:token}))
        } else if(newValue === 1){
            console.log("Fetching bales because of tab change")            
            dispatch(fetchBales(token))
        } else if(newValue === 0){
            console.log("Fetching fields because of tab change")            
            dispatch(fetchOwnFields({token:token}))  
        }
      };

    const getData = async () => {
        try {
            const token = await getAccessTokenSilently();
            dispatch(fetchBales(token))
            dispatch(fetchOwnFields({token:token}))   
            dispatch(fetchAllJobs({token:token}))    
            dispatch(fetchUsers(token))    
            dispatch(fetchCustomers(token))
            dispatch(fetchAllStacks({token:token}))
        } catch (error) {
            console.log(error.message);
        }
    };

    function a11yProps(index) {
        return {
          id: `scrollable-force-tab-${index}`,
          'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
      }

    const classes = useStyles();
    console.log(responsive)

    return (        
        <>
        {drawerStatus.open === false ? ( 
        <div className={classes.btnToolbar}>
            <Button variant="contained" onClick = {() => dispatch(openDrawer(true)) }>
                <MenuOpenIcon/>
            </Button>
        </div>) :( 
        <Drawer  classes={responsive.isMobile === true? ({paper: classes.paperMobile}) : ({paper: classes.paperDesktop})} variant={"persistent"} anchor={'right'} open={drawerStatus.open} onClose={() => dispatch(openDrawer(false))} BackdropProps={{ invisible: true }}>
            {/* <div className={classes.root}> */}
            
            <AppBar position="sticky" color="default">
                <Toolbar>
                    <IconButton onClick={() => dispatch(openDrawer(false))}>
                        <ChevronRightIcon />
                    </IconButton>
                    <Tabs
                        value={drawerStatus.currentTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="scrollable force tabs example"
                        className={{ root: classes.tab }}
                    >
                        <Tab className={classes.tab} label="Marker" icon={<TextureIcon/>} {...a11yProps(0)} />
                        <Tab className={classes.tab} label="Baller" icon={<Crop169Icon />} {...a11yProps(1)} />
                        <Tab className={classes.tab} label="Stakke" icon={<ViewModuleIcon />} {...a11yProps(2)}/>
                        <Tab className={classes.tab} label="Opgaver" icon={<AssignmentIndIcon />}{...a11yProps(3)} />                    
                    </Tabs>
                </Toolbar>
            </AppBar>
            <Container>
            <div className={classes.fetchBales}>
                {balesStatus === 'loading' ? <Loader type="ThreeDots"/> :
                <>
                
                </>}
            </div>
            <TabPanel value={drawerStatus.currentTab} index={0}>
                <DashboardUtilPanelFields/>
            </TabPanel>
            <TabPanel value={drawerStatus.currentTab} index={1}>
                               
            </TabPanel>
            <TabPanel value={drawerStatus.currentTab} index={2}> 
                <DashboardUtilPanelStacks/>                   
            </TabPanel>
            <TabPanel value={drawerStatus.currentTab} index={3}>
                <DashboardUtilPanelJobs/>
            </TabPanel>
            </Container>
            {/* </div>  */}
        </Drawer>                
        )}    
        </>     
    );
}

export default DashboardUtilPanel
