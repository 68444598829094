import React, {Component} from 'react';
import DashboardView from "./DashboardView";

class DashboardContainer extends Component{ 
    render() {
        return (       
            
                <DashboardView />
            
        )
    }
}

export default DashboardContainer;