import {React, useContext, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import SLUGS from '../slugs';
import {
    IconAgents,
    IconOverview,
    IconSettings,
    IconSubscription,   
} from '../../assets/icons';
import { convertSlugToUrl } from '../../utils/utilities';
import LogoComponent from './LogoComponent';
import Menu from './MenuComponent';
import MenuItem from './MenuItemComponent';
import AuthNav from "../../services/auth/components/auth-nav"
import { useAuth0 } from "@auth0/auth0-react";
import {SidebarContext} from "../hooks/useSidebar"

function SidebarComponent() {
    const { push } = useHistory();
    let isMobile = window.innerWidth <= 1080;
    const { isAuthenticated} = useAuth0();
    const {currentItem} = useContext(SidebarContext);

    useEffect(() => {

    
    },[])

    function onClick(slug, parameters = {}) {
        push(convertSlugToUrl(slug, parameters));
    }

    return (
        <Menu isMobile={isMobile}>
            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                <LogoComponent />
            </div>            
            <MenuItem
                id={SLUGS.root}                
                title='Overblik'
                icon={IconOverview}
                onClick={() => onClick(SLUGS.root)}
            >               
            </MenuItem>  
            {isAuthenticated && (
                <MenuItem
                id={SLUGS.dashboard}
                title='Dashboard'
                icon={IconSubscription}
                onClick={() => onClick(SLUGS.dashboard)}
                />
            )}
            {isAuthenticated &&  (            
            <MenuItem
                id={SLUGS.users}
                items={[SLUGS.user, SLUGS.newUser,SLUGS.profileInfo,'/users/']}
                title='Brugerer'
                icon={IconAgents}
            >
                <MenuItem
                    id={SLUGS.users}
                    title='Brugere'
                    items={[SLUGS.newUser, '/users/']}
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(SLUGS.users)}
                />                
                <MenuItem
                    id={SLUGS.profileInfo}
                    title='Profil'
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(SLUGS.profileInfo)}
                />                
            </MenuItem>)}
            {/* {isAuthenticated &&  (<MenuItem
                id={SLUGS.settings}
                title='Indstillinger'
                icon={IconSettings}
                onClick={() => onClick(SLUGS.settings)}
            /> )}     */}
            {isAuthenticated &&  (<MenuItem
                id={SLUGS.customerHandling}
                items={[SLUGS.newCustomer, '/customers/']}
                title='Kunder'
                icon={IconAgents}
                onClick={() => onClick(SLUGS.customerHandling)}
            /> )}           
            <AuthNav/>           
        </Menu>        
    );
}

export default SidebarComponent;
