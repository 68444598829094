import { useAuth0 } from "@auth0/auth0-react";
import { string } from 'prop-types';
import React, { useContext } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { Row } from 'simple-flexbox';
import { IconBell, IconSearch } from '../assets/icons';
import { SidebarContext } from '../navigation/hooks/useSidebar';
import SLUGS from '../navigation/slugs';
import { convertSlugToUrl } from '../utils/utilities';


const useStyles = createUseStyles((theme) => ({
    avatar: {
        height: 35,
        width: 35,
        borderRadius: 50,
        marginLeft: 14,
        border: `1px solid ${theme.color.lightGrayishBlue2}`
    },
    container: {
        height: 40,
        margin: 10
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    name: {
        ...theme.typography.itemTitle,
        textAlign: 'right',
        '@media (max-width: 768px)': {
            display: 'none'
        }
    },
    separator: {
        borderLeft: `1px solid ${theme.color.lightGrayishBlue2}`,
        marginLeft: 32,
        marginRight: 32,
        height: 32,
        width: 2,
        '@media (max-width: 768px)': {
            marginLeft: 12,
            marginRight: 12
        }
    },
    title: {
        ...theme.typography.title,
        '@media (max-width: 1080px)': {
            marginLeft: 50
        },
        '@media (max-width: 468px)': {
            fontSize: 20
        }
    },
    iconStyles: {
        cursor: 'pointer',
        marginLeft: 25,
        '@media (max-width: 768px)': {
            marginLeft: 12
        }
    }
}));

function UserInfo(){
    const theme = useTheme();
    const { push } = useHistory();
    const classes = useStyles({ theme });
    const { isAuthenticated, user } = useAuth0();

    function onClick(slug, parameters = {}) {
        push(convertSlugToUrl(slug, parameters));
    }

    if(isAuthenticated === true){
        return(
            <Row vertical='center'>
                <div onClick = {() => onClick(SLUGS.profileInfo)} >
                        <span className={[classes.name, classes.cursorPointer].join(' ')}>
                            {user.name}
                        </span>
                        <img
                            src={user.picture}
                            alt='avatar'
                            className={[classes.avatar, classes.cursorPointer].join(' ')}
                        />
                </div>
             </Row>
        );
    } else {
        return (
            <div></div>
        );
    }
   
}

function HeaderComponent() {
    const { currentItem } = useContext(SidebarContext);
    const theme = useTheme();
    const classes = useStyles({ theme });    
    let title;
    switch (true) {
        case currentItem === SLUGS.dashboard:
            title = 'Dashboard';
            break;
        case currentItem === SLUGS.overview:
            title = 'Overblik';
            break;
        case currentItem === SLUGS.users:
            title = 'Brugere';
            break;
        case  currentItem === SLUGS.newUser:
            title = 'Ny bruger';
            break;        
        case currentItem === SLUGS.settings:
            title = 'Indstillinger';
            break;
        case currentItem === SLUGS.profileInfo:
            title = 'Bruger info';
            break;
        case currentItem === SLUGS.customerHandling:
            title = 'Kunde liste';
            break;
        case currentItem === SLUGS.newCustomer:
            title = 'Opret ny kunde';
            break;
        case currentItem.includes('/customers/'):
            title = 'Rediger kundeoplysninger';
            break;
        case currentItem.includes('/users/'):
            title = 'Rediger brugeroplysninger';
            break;

        default:
            title = '';
    }




    return (
        <Row className={classes.container} vertical='center' horizontal='space-between'>
            <span className={classes.title}>{title}</span>
            <Row vertical='center'>
                {/* <div className={classes.iconStyles}>
                    <IconSearch />
                </div>
                <div className={classes.iconStyles}>
                    <IconBell />
                </div> */}
                <div className={classes.separator}></div>
                    <UserInfo/>              
            </Row>
        </Row>
    );
}

HeaderComponent.propTypes = {
    title: string
};

export default HeaderComponent;
