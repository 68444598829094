import { withAuth0 } from "@auth0/auth0-react";
import { Grid, LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SaveIcon from "@material-ui/icons/Save";
import Alert from "@material-ui/lab/Alert";
import { Field, Form, Formik } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SkeletonForm from "../../components/SkeletonForm";
import { AuthLevel, ServiceUser, User } from "../../models/user";
import SLUGS from "../../navigation/slugs";
import { createUser, getUserDetails, resetDetailedUser, resetStatus, selectUserById, updateUser } from '../../redux/slicers/userSlice';
import { Map } from "../../services/maps";
import { formPageStyles } from "../../styles";



const styles = formPageStyles;


// class UserFormPage extends React.Component {
class UserFormPage extends React.Component{
  constructor(props) {
    super(props);
    this.onSnackBarClose = this.onSnackBarClose.bind(this);
    this.onSave = this.onSave.bind(this)
  }

  state = {
    user: {},
    snackbarOpen: false,
    autoHideDuration: 5000,
  };

  async componentDidMount() {
        // @ts-ignore
    const { getAccessTokenSilently } = this.props.auth0; 
    const token = await getAccessTokenSilently();
    this.setState({token: token});
    const userId = this.props.match.params.id;
    console.log(userId)
    if (userId) {        
        const user = this.props.selectUserById(userId)
        console.log("Get user details for: " + JSON.stringify(user))
        this.props.getUserDetails({user: user, token: token})
    }  
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.status !== prevProps.status && (this.props.status === 'success' ||  this.props.status === 'error')
    ) {
      console.log("Openeing snackbar")
      this.setState({ snackbarOpen: true });            
    } 
  }

  createBackendUser(values){     

      var serviceUser = new ServiceUser()
      for (var prop in values) {if (serviceUser.hasOwnProperty(prop)) {serviceUser[prop] = values[prop];}}
    
      if(values.isAdmin === true){     
        serviceUser.roleList.push(AuthLevel.Administrator)
      }
      if(values.isCollector === true){    
        serviceUser.roleList.push(AuthLevel.Collector)
      }
      if(values.isBaler === true){    
        serviceUser.roleList.push(AuthLevel.Baler)
      }
      return serviceUser
  }

  createFrontendUser(values){  
    
   if(Object.keys(values).length === 0){
     return {}
   }
    
    var clone = Object.assign({},values)
    delete clone.roleList 
    clone.isAdmin = values.roleList.includes(AuthLevel.Administrator)
    clone.isCollector = values.roleList.includes(AuthLevel.Collector)
    clone.isBaler = values.roleList.includes(AuthLevel.Baler)
    return clone
}


  onSave(values) {      
    console.log(this.props.detailedUser)
    if(Object.keys(this.props.detailedUser).length > 0){
      const BackendUser = this.createBackendUser(values)
      this.props.updateUser({user: BackendUser,token: this.state.token})
    } else {
      const tempUser = this.createBackendUser(values)
      const BackendUser = {...tempUser, organisationId: this.props.auth0.user['http://smartbaling.com/metadata'].organisation}
      this.props.createUser({user: BackendUser,token: this.state.token})
    }
  }

  onSnackBarClose(event,reason) {
    console.log("Close snack bar")
    console.log(reason)
    if(reason !== 'clickaway'){
        this.setState({snackbarOpen: false});
        this.props.resetStatus()
    }
  }

  render() {
    const { status ,detailedUser } = this.props;   
  
    return (
    <div style={styles.container}>
      <div style={styles.containerForm}>
        {status === 'loading' ? (
          <div>
            <SkeletonForm />
          </div>
        ) : (
          <div>
          {status === 'loading' && <LinearProgress />}
          <Formik
            enableReinitialize
            initialValues={this.createFrontendUser(detailedUser)}
            validate={(values) => {
              const errors = {};
              if (!values.name) {
                errors.name = "Nødvendig";
              }
              if(!values.email){
                errors.email = "Email nødvendig"
              } else if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = "Ugyldig email adresse";
              }
              if (values.phone && !/^((\d{8})|(\+\d{10})|(00\d{10}))$/i.test(values.phone)) {
                errors.phone = "Ugyldigt telefon nummer";
              }          
              
              if(!values.password && Object.keys(this.props.detailedUser).length === 0){
                // New user, password is neccessary
                errors.password ="Adgangskode nødvendig"
              } else if(values.password && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(values.password)){
                errors.password ="Min 8 tegn, 1 tal og et stort bogstav"
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              console.log("On submit")
              this.onSave(values);
              setTimeout(() => {
                setSubmitting(false);
                console.log(JSON.stringify(values, null, 2));
              }, 5000);
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <div style={styles.buttons}>
                  <Link to={SLUGS.users}>
                    <Button variant="contained">
                      <ArrowBackIosIcon /> Back{" "}
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    style={styles.saveButton}
                    onClick={submitForm}
                    color="primary"
                    disabled={isSubmitting}
                  >
                    <SaveIcon /> Save
                  </Button>                  
                </div> 
                               
                <Grid container style={styles.containerGrid} spacing={3}>
                  <Grid item style={styles.cell} xs={12} md={4}>
                    <Field
                      variant="outlined"
                      component={TextField}
                      placeholder="Navn"
                      label="Navn"
                      name="name"
                      fullWidth={true}
                      required
                    />
                  </Grid>
                  <Grid item style={styles.cell} xs={12} md={4}>
                    <Field
                      variant="outlined"
                      component={TextField}
                      placeholder="Email"
                      label="Email"
                      fullWidth={true}
                      name="email"                      
                    />
                  </Grid>
                  <Grid item style={styles.cell} xs={12} md={4}>
                    <Field
                      variant="outlined"
                      component={TextField}
                      placeholder="12345678"
                      label="Mobile"
                      fullWidth={true}
                      type="string"
                      name="phone"                     
                    />
                  </Grid>        
                  <Grid item style={styles.cell} xs={12} md={4}>
                    <Field
                      variant="outlined"
                      component={TextField}
                      placeholder="Password"
                      label="Password"
                      fullWidth={true}
                      type="string"
                      name="password"                     
                    />
                  </Grid> 
                  <Grid container style={styles.containerGrid} spacing={3} direction="row">                          
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="isAdmin"
                        Label={{ label: 'Administrator',labelPlacement:'start' }}                        
                      />
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="isBaler"
                        Label={{ label: 'Presser',labelPlacement:'start' }}
                        
                      />
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="isCollector"
                        Label={{ label: 'Samler',labelPlacement:'start' }}                      
                      />        
                  </Grid>                       
                </Grid>  
                <br />                
                <Snackbar
                  open={this.state.snackbarOpen}
                  autoHideDuration={this.state.autoHideDuration}
                  onClose={this.onSnackBarClose}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <Alert onClose={this.onSnackBarClose} severity={this.props.status}>
                  {this.props.statusMsg}
                  </Alert>
                </Snackbar>                    
              </Form>
            )}
          </Formik> 
          </div>         
        )}
        </div>
        <div style={styles.containerMap}>
          <Map>      
            {/* <MapContext.Consumer>
              {value => (<UserMapUtils map={value} />)}
            </MapContext.Consumer>                */}
          </Map>
        </div>
      </div>
    );
  }
}

const  mapStateToProps = state => ({status: state.users.status,
                                    statusMsg: state.users.statusMsg,
                                    detailedUser: state.users.detailedUser,
                                    selectUserById: (id) => selectUserById(state,id)})

function mapDispatchToProps(dispatch) {
  return {
    createUser: (arg) => dispatch(createUser(arg)),
    updateUser: (arg) => dispatch(updateUser(arg)),
    resetStatus: () => dispatch(resetStatus()),
    getUserDetails: (arg) => dispatch(getUserDetails(arg)),
    resetDetailedUser: () => dispatch(resetDetailedUser())       
  };
}

export default withAuth0(connect(mapStateToProps, mapDispatchToProps)(UserFormPage));
