import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ContentCreate from "@material-ui/icons/Create";
import ActionDelete from "@material-ui/icons/Delete";
import { connect, useSelector } from 'react-redux';
import { deleteField, selectFieldsById, setEditField, updateOwnFields } from '../../redux/slicers/fieldSlice';
import { setLastEditAmount, selectLastEditAmount } from '../../redux/slicers/stackSlice'
import { Collapse, Grid, Paper, TextField, Tooltip } from '@material-ui/core';
import Fab from "@material-ui/core/Fab";
import { blue, common, green, grey, red } from "@material-ui/core/colors";
import { withAuth0 } from '@auth0/auth0-react';
import { setClosePopUp, setEditFeature, setDraw, setPopUp } from '../../redux/slicers/mapSlice';
import CloseIcon from '@material-ui/icons/Close';
import { FieldContent, getTextFromStatus } from '../../components/Fields/FieldTile';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteDialog from '../../components/DeleteDialog';
import { selectCustomerById } from '../../redux/slicers/customerSlice';
import { changeTab, openDrawer, selectNewItemState, setNewItemState } from '../../redux/slicers/dashboardSlice';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { EditFieldTile } from '../../components/Fields/EditFieldTile';
import { NEW_ITEM_TYPE, POPUPTYPE } from './LayerTypes';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigationIcon from '@material-ui/icons/Navigation';
import { withResponsive } from '../../components/Responsive';
import { center, area } from '@turf/turf';
import { ShowWhen } from '../../navigation/role-renderer/ShowWhen';
import Permission from '../../navigation/role-renderer/Permissions';

const white = common.white;
const green400 = green["400"];
const styles = theme => ({
  root: {
    maxWidth: '300px',
    minWidth: '250px'
  },
  media: {
    height: 140,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  mapboxglPopupContent: {
    padding: 0,
  },
  deleteButton: {
    color: "white",
    marginRight: "1em",
    backgroundColor: red[500],
  },
  editButton: {
    marginRight: "1em",
    color: white,
    backgroundColor: green400,
  },
  showButton: {
    marginRight: "1em",
  },

});


class FieldInfoPopUp extends React.Component {

  constructor(props) {
    super(props)
    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this)
    this.onClose = this.onClose.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
    this.onShow = this.onShow.bind(this)
    this.handleExpandClick = this.handleExpandClick.bind(this)
    this.updateBaleCount = this.updateBaleCount.bind(this)
    this.state = {
      addText: "Fjern",
      open: false,
      expanded: true,
      dialog: { open: false, onConfirm: null }
    }
  }

  onDelete() {
    const field = this.props.selectFieldsById(this.props.fieldID)
    this.props.closePopUp();
    this.props.deleteField({ token: this.state.token, field: field })

  }

  onEdit(e, field) {
    console.log(field)
    this.props.setItemState({ ...this.props.itemState, field: field, type: NEW_ITEM_TYPE.FIELD, isEditing: true, isNew: false })
  }


  onClose(e, stack) {
    this.props.onClose()
  }

  async componentDidMount() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    this.setState({ token: token });
  }


  updateBaleCount() {
    const field = this.props.selectFieldsById(this.props.fieldID)
    var modField = JSON.parse(JSON.stringify(field))

    modField.currentAmountOfBales = parseInt(modField.currentAmountOfBales) - parseInt(this.props.lastEditAmount);

    this.props.updateField({ field: modField, token: this.state.token })
  }

  closeDialog(isConfirmed) {
    this.setState({ ...this.state, dialog: { ...this.state.dialog, open: false } });
    if (isConfirmed) {
      this.state.dialog.onConfirm()
    }
  }

  getSubHeader() {
    return (<div> "Status: " + getTextFromStatus(field.status)" </div>)
  }

  onShow(event) {
    this.props.setUtilPanelTab(0)
    this.props.openUtilPanel(true)
  }

  handleExpandClick() {
    this.setState({ expanded: !this.state.expanded });
  };

  onNavigate(e, field) {
    const coord = center(field.geometry).geometry.coordinates
    window.open(encodeURI("https://www.google.com/maps/dir/?api=1&destination=" + coord[1] + "," + coord[0] + "&travelmode=driving"), "_blank");
  }

  render() {

    const field = this.props.selectFieldsById(this.props.fieldID)
    var modField = JSON.parse(JSON.stringify(field))
    delete modField.geometry.geometry
    delete modField.geometry.type
    const { classes } = this.props
    return (
      <>
        <Card className={classes.root}>
          <CardHeader
            title={"Mark"}
            subheader={(this.props.itemState.isEditing !== true) && <div>{"Status: " + getTextFromStatus(field.status)} <br /> {"Ejer: " + this.props.selectCustomerById(field.ownerID)?.name} <br />  {field.geometry?.properties?.Afgroede ? <>{field.geometry?.properties?.Afgroede} </> : <> Ukendt afgrøde </>} <br /> {(area(field.geometry) / 10000).toFixed(1) + "[Ha]"} {field.geometry?.properties?.Marknr && <><br/> {"Mark nr: " + field.geometry?.properties?.Marknr} </>} </div>}
            action={(this.props.itemState.isEditing !== true) &&
              <Tooltip title="Luk" aria-label="close">
                <Fab
                  size="small"
                  className={classes.closeButton}
                  onClick={(e) => this.onClose(e, field)}
                >
                  <CloseIcon />
                </Fab>
              </Tooltip>}
          />
          <CardContent>
            {/* <pre>{JSON.stringify(modField,null,2)}</pre> */}
            {(this.props.itemState.isEditing === true && this.props.itemState.type === NEW_ITEM_TYPE.FIELD) ?
              <EditFieldTile />
              :
              <Grid containerm direction="column" spacing={2}>
                <FieldContent field={field} />
                {field.currentAmountOfBales > 0 && <Grid container direction="row" item spacing={2} justify="center" alignItems="center">
                  <Grid item xs={6}>
                    <TextField label={this.state.addText} variant="outlined" type="number" InputLabelProps={{
                      shrink: true,
                    }}
                      defaultValue={-parseInt(this.props.lastEditAmount)}
                      onBlur={(event) => { this.props.setLastEditAmount(-parseInt(event.target.value)) }}
                      onChange={(event) => {
                        var text = event.target.value > 0 ? "Tilføj" : "Fjern"
                        this.setState({ ...this.state, addText: text })
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      endIcon={<RefreshIcon />}
                      onClick={() => {
                        if (this.props.lastEditAmount !== 0) {
                          this.setState({
                            ...this.state, dialog: {
                              open: true,
                              onConfirm: this.updateBaleCount,
                              title: "Opdater Mark",
                              text: this.state.addText + " " + this.props.lastEditAmount + " baller " + (-parseInt(this.props.lastEditAmount) > 0 ? "til" : "fra") + " marken?"
                            }
                          });
                        }
                      }}
                    >
                      {this.state.addText}
                    </Button>
                  </Grid>
                </Grid>}
              </Grid>
            }
          </CardContent>

          {this.props.itemState.isEditing !== true &&
            <CardActions disableSpacing>
              <ShowWhen persmissions={[Permission.edit_field, Permission.modify_field]}>
                <Tooltip title="Rediger" aria-label="edit">
                  <Fab
                    size="small"
                    className={classes.editButton}
                    onClick={(e) => this.onEdit(e, field)}
                  >
                    <ContentCreate />
                  </Fab>
                </Tooltip>
              </ShowWhen>
              <ShowWhen persmissions={[Permission.delete_field]}>
                <Tooltip title="Slet" aria-label="delete">
                  <Fab
                    size="small"
                    className={classes.deleteButton}
                    onClick={(e) => {
                      this.setState({
                        ...this.state, dialog: {
                          open: true,
                          onConfirm: this.onDelete,
                          title: "Slet Mark",
                          text: "Sletning af marken er ikke muligt at fortryde!"
                        }
                      })
                    }}
                  >
                    <ActionDelete />
                  </Fab>
                </Tooltip>
              </ShowWhen>
              <Tooltip title="Vis" aria-label="vis">
                <Fab
                  size="small"
                  className={classes.showButton}
                  onClick={(e) => this.onShow(e)}
                >
                  <VisibilityIcon />
                </Fab>
              </Tooltip>
              {(this.props.responsive.isMobile || this.props.responsive.isTablet) &&
                <Tooltip title="Åben rute i maps" aria-label="open-route">
                  <Fab
                    size="small"
                    // className={showJobDetail?.id === job.id ? classes.showButtonSelected : classes.showButton}
                    onClick={(e) => this.onNavigate(e, field)}
                  >
                    < NavigationIcon />
                  </Fab>
                </Tooltip>}
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: this.state.expanded,
                })}
                onClick={this.handleExpandClick}
                aria-expanded={this.state.expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>}

          <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph>Noter:</Typography>
              <Typography>
                {field?.note}
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
        <DeleteDialog
          open={this.state.dialog.open}
          closeDialog={this.closeDialog}
          dialogTitle={this.state.dialog.title}
          dialogText={this.state.dialog.text}
        />
      </>
    );
  }

}

const mapStateToProps = state => (
  {
    selectFieldsById: (id) => selectFieldsById(state, id),
    lastEditAmount: selectLastEditAmount(state),
    selectCustomerById: (id) => selectCustomerById(state, id),
    itemState: selectNewItemState(state)
  })


function mapDispatchToProps(dispatch) {
  return {
    deleteField: (arg) => dispatch(deleteField(arg)),
    setEditFeature: (arg) => dispatch(setEditFeature(arg)),
    setItemState: (arg) => dispatch(setNewItemState(arg)),
    setDraw: (arg) => dispatch(setDraw(arg)),
    setPopUp: (id) => dispatch(setPopUp(id)),
    closePopUp: () => dispatch(setClosePopUp()),
    //setEditField: (field) => dispatch(setEditField(field)),
    setLastEditAmount: (amount) => dispatch(setLastEditAmount(amount)),
    updateField: (arg) => dispatch(updateOwnFields(arg)),
    openUtilPanel: (arg) => dispatch(openDrawer(arg)),
    setUtilPanelTab: (arg) => dispatch(changeTab(arg))
  };
}

export default withResponsive(withAuth0(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FieldInfoPopUp))))