import { createSlice, createAsyncThunk, createEntityAdapter, unwrapResult  } from '@reduxjs/toolkit'

const initialState = {
  selectorPermissions: [],
  status: 'idle',
  error: null
}

export const fetchPermissions = createAsyncThunk('Permissions/fetchPermissions', async token => {

  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const response = await fetch(
    `${serverUrl}/api/GetUserPermissions`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const data = await response.json()
  if (response.ok) {
    return data
  }
})

const permissionsSlice = createSlice({
  name: 'selectorPermissions',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchPermissions.pending]: (state, action) => {
      state.status = 'loading'
    },
    [fetchPermissions.fulfilled]: (state, action) => {
      state.status = 'success'
      state.selectorPermissions = action.payload      
    },
    [fetchPermissions.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    }
  }
})

export default permissionsSlice.reducer