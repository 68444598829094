import { centerOfMass } from '@turf/turf';
import _ from 'lodash';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import CustomerFieldInfoPopUp from '../../pages/Customer/CustomerFieldInfoPopUp';
import { selectNewItemState } from '../../redux/slicers/dashboardSlice';
import { selectFieldsById } from '../../redux/slicers/fieldSlice';
import { selectEditFeature, selectDraw, selectPopUp, setClosePopUp, setPopUp, selectPopUpAlowed } from '../../redux/slicers/mapSlice';
import { selectStacksById } from '../../redux/slicers/stackSlice';
import FieldInfoPopUp from './FieldInfoPopUp';
import { POPUPTYPE } from './LayerTypes';
import { MapboxContext } from './MapComponent';
import NewFieldPopUp from './NewItemPopUp';
import { PopUp } from './PopUp';
import StackInfoPopup from './StackInfoPopup';

class PopUpHandler extends Component {
    

    static contextType = MapboxContext;

    constructor(props) {
        super(props);
        this.onMapClick = this.onMapClick.bind(this)
        this.clearPopUp = this.clearPopUp.bind(this)

        this.state = {
        };
    }

    componentWillUnmount() {
        const map = this.context
        map?.off('click', this.onMapClick);
    }


    componentDidMount() {
        const map = this.context
        map?.on('click', this.onMapClick)
    }

    clearPopUp(){
        this.props.closePopUp()
    }

    onMapClick(e) {
        const map = this.context

        // Return if we are editing to ignore clicking outside popup
        if(this.props.newItemState.isEditing === true) return
        if(this.props.draw.enableLayer === true) return
        if(this.props.popUpAllowed === false) return

        var features = map.queryRenderedFeatures(e.point)
        console.log(features)
        console.log(this.props.selectPopUp)
        


        if (features.length === 0) { 
            //Nothing was clicked            
            this.props.setPopUp({id: e.lngLat, type: POPUPTYPE.NEW_FIELD})                    
            
        } else {
            this.props.setPopUp(features[0].properties)
        }
    }
 
    render() {
        const popUpStack = this.props.selectStacksById(this.props.selectPopUp.id)      
        const popUpField = this.props.selectFieldsById(this.props.selectPopUp.id) 
        const popUpEUField = this.props.customerFields.find((field) => field.id === this.props.selectPopUp.id)   
  
        return (
            <div>
                {(this.props.selectPopUp.type === POPUPTYPE.STACK && popUpStack !== undefined) ? 
                <PopUp map = {this.context} coordinates = {centerOfMass(popUpStack.location).geometry.coordinates.slice()} mapboxPopupProps={{closeButton : false, closeOnMove: false, closeOnClick: false, anchor: "bottom"}} >
                    <StackInfoPopup stackID={popUpStack.id} onClose={this.clearPopUp}/>
                </PopUp> : null}     

                {(this.props.selectPopUp.type === POPUPTYPE.FIELD && popUpField !== undefined) ? 
                <PopUp map = {this.context} coordinates = {centerOfMass(popUpField.geometry).geometry.coordinates.slice()} mapboxPopupProps={{closeButton : false, closeOnMove: false, closeOnClick: false, anchor: "bottom"}} >
                    <FieldInfoPopUp fieldID={popUpField.id} onClose={this.clearPopUp}/>
                </PopUp> : null }     
                {(this.props.selectPopUp.type === POPUPTYPE.NEW_FIELD && this.props.allowNewField === true)  ? 
                <PopUp map = {this.context} coordinates = {this.props.selectPopUp.id} mapboxPopupProps={{closeButton : false, closeOnMove: false, closeOnClick: false, anchor: "bottom"}}>
                    <NewFieldPopUp coordinates = {this.props.selectPopUp.id} onClose={this.clearPopUp} />
                </PopUp> : null } 

                {(this.props.selectPopUp.type === POPUPTYPE.EU_FIELD && popUpEUField !== undefined)  ? 
                <PopUp map = {this.context} coordinates = {centerOfMass(popUpEUField.geometry).geometry.coordinates.slice()} mapboxPopupProps={{closeButton : false, closeOnMove: false, closeOnClick: false, anchor: "bottom"}}>
                   <CustomerFieldInfoPopUp field={popUpEUField} onClose={this.clearPopUp} />
                </PopUp> : null }  
            </div>
        )
    }
}

const mapStateToProps = state => (
    {selectStacksById: (id) => selectStacksById(state,id),
     selectFieldsById: (id) => selectFieldsById(state,id),
     selectPopUp: selectPopUp(state),
     customerFields: state.customers.customerFields,
     editFeature: selectEditFeature(state),
     draw: selectDraw(state),
     newItemState: selectNewItemState(state),
     popUpAllowed: selectPopUpAlowed(state)
    })

function mapDispatchToProps(dispatch) {
    return {
        setPopUp: (arg) => dispatch(setPopUp(arg)),
        closePopUp: () => dispatch(setClosePopUp())
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PopUpHandler)
