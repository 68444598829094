import { withAuth0 } from "@auth0/auth0-react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";
import { connect } from "react-redux";
import { clearCustomerFields, createCustomer, resetStatus, selectCustomerById, selectCustomerStatus, setCustomerCVR, updateCustomer } from '../../redux/slicers/customerSlice';
import { createOwnFields } from "../../redux/slicers/fieldSlice";
import { clearFieldSelection, selectedFieldsIds } from "../../redux/slicers/mapSlice";
import { Map } from "../../services/maps";
import PopUpHandler from "../../services/maps/PopUpHandler";
import { formPageStyles } from "../../styles";
import CustomerMapUtils from "./CustomerMapUtils";
const styles = formPageStyles;
const moreStyles = () => ({
  backdrop: {
    zIndex: 300,
    color: '#fff',
  },
})

// class CustomerFormPage extends React.Component {
class CustomerFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = {}
  }

  state = {
    customer: {},
    snackbarOpen: false,
    autoHideDuration: 5000,
  };

  async componentDidMount() {

    this.props.clearFieldSelection();
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    this.setState({ token: token });
    const customerId = this.props.match.params.id;
    if (customerId) {
      console.log(this.props.match)
      const customer = this.props.selectCustomerById(customerId)
      if (customer) {
        this.setState({ customer: customer })
        await this.props.setCustomerCVR(customer.cvr)
      }
    }
  }

  componentDidUpdate(prevProps) {

  }

  componentWillUnmount() {
    this.props.setCustomerCVR("");
    this.props.clearCustomerFields("");
  }

  render() {
    const { status } = this.props;
    const { customer = {} } = this.state;
    const { classes } = this.props
    return (
      <div style={styles.container}>
        <Map>
          <CustomerMapUtils customer={customer} />
          <PopUpHandler allowNewField={false} />
        </Map>
        <Backdrop className={classes.backdrop} open={this.props.customerStatus === "loading"}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectCustomerById: (id) => selectCustomerById(state, id),
  selectedCustomerFieldsId: selectedFieldsIds(state),
  customerFields: state.customers.customerFields,
  customerStatus: selectCustomerStatus(state)
})

function mapDispatchToProps(dispatch) {
  return {
    createCustomer: (arg) => dispatch(createCustomer(arg)),
    updateCustomer: (arg) => dispatch(updateCustomer(arg)),
    resetStatus: () => dispatch(resetStatus()),
    setCustomerCVR: (cvr) => dispatch(setCustomerCVR(cvr)),
    clearFieldSelection: () => dispatch(clearFieldSelection()),
    clearCustomerFields: () => dispatch(clearCustomerFields()),
    createOwnFields: (arg) => dispatch(createOwnFields(arg)),
  };
}

export default withAuth0(withStyles(moreStyles)(connect(mapStateToProps, mapDispatchToProps)(CustomerFormPage)));
