import { Tooltip } from "@material-ui/core";
import { pink } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ContentAdd from "@material-ui/icons/Add";
import { bbox, union ,featureCollection} from '@turf/turf';
import _ from "lodash";
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditJobTile } from '../../components/Jobs/EditJobTile';
import { JobTile } from '../../components/Jobs/JobTile';
import { JobStatus, JobType } from '../../models/job';
import { LocationType } from "../../models/location";
import { selectLayerVisible, selectNewItemState, selectShowJobDetails, setNewItemState, setSelectkey, setShowJobDetails } from "../../redux/slicers/dashboardSlice";
import { selectFields } from '../../redux/slicers/fieldSlice';
import { selectJobs, } from '../../redux/slicers/jobsSlice';
import { clearFieldSelection, setVisibility } from "../../redux/slicers/mapSlice";
import FieldsLayer  from "../../services/maps/FieldLayer";
import { MapboxContext } from "../../services/maps/MapComponent";
import {endCentroids} from "../../services/maps/FieldLayer"
import { Layer } from "react-mapbox-gl";
import { TransportJob } from "../../models/transportJob";
import FieldInfoPopUp from "../../services/maps/FieldInfoPopUp";
import { selectStacks } from "../../redux/slicers/stackSlice";
import StackLayer , {endCentroids as endCentroidsStacks} from "../../services/maps/StackLayer";
import StackInfoPopup from "../../services/maps/StackInfoPopup";
import { POPUPTYPE } from "../../services/maps/LayerTypes";
import { ShowWhen } from "../../navigation/role-renderer/ShowWhen";
import Permission from "../../navigation/role-renderer/Permissions";

const pink500 = pink['500'];

const useStyles = makeStyles({
    fab: {
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        marginRight: 20,
        backgroundColor: pink500, // {pink500}
    }
})


export const JobDetailLayers = () => {
    const job = useSelector(selectShowJobDetails)
    const allFields = useSelector(selectFields)
    const allStacks = useSelector(selectStacks)
    const map = useContext(MapboxContext)
    const fieldsToShowFrom= []
    const fieldsToShowTo= []
    const stacksToShowFrom = []
    const stacksToShowTo =  []
    var labelTo = ""
    var labelFrom = ""  
    if (job.type === JobType.BALING) {    
        var jobFields = job.onLocation.filter((location) => location.type === LocationType.FIELD)
        jobFields.forEach(field => {
            var realField = allFields.find((dbField) => dbField.id === field.id)
            if(realField !== undefined){
                fieldsToShowFrom.push(realField)
            }
        });

        labelFrom = "Presning"
    }
    if (job.type === JobType.TRANSPORT) {

        var jobFields = job.toLocation.filter((location) => location.type === LocationType.FIELD)
        jobFields.forEach(field => {
            var realField = allFields.find((dbField) => dbField.id === field.id)
            if(realField !== undefined){
                fieldsToShowTo.push(realField)
            }
        });

        var jobStacks= job.toLocation.filter((location) => location.type === LocationType.STACK)
        jobStacks.forEach(stack => {
            var realStack = allStacks.find((dbStack) => dbStack.id === stack.id)
            if(realStack !== undefined){
                stacksToShowTo.push(realStack)
            }
        });

        var jobFields = job.fromLocation.filter((location) => location.type === LocationType.FIELD)
        jobFields.forEach(field => {
            var realField = allFields.find((dbField) => dbField.id === field.id)
            if(realField !== undefined){
                fieldsToShowFrom.push(realField)
            }
        });

        var jobStacks= job.fromLocation.filter((location) => location.type === LocationType.STACK)
        jobStacks.forEach(stack => {
            var realStack = allStacks.find((dbStack) => dbStack.id === stack.id)
            if(realStack !== undefined){
                stacksToShowFrom.push(realStack)
            }
        });  


     
        labelFrom = "Afhent"
        labelTo = "Lever"
    }

    console.log(fieldsToShowFrom)
    console.log(fieldsToShowTo)
    console.log(stacksToShowFrom)
    console.log(stacksToShowTo)

    useEffect(() => {

        var collectionFrom = null
        var collectionTo = null

        var arrayFrom = fieldsToShowFrom.map((fields) => fields.geometry).concat(stacksToShowFrom.map((stack) => stack.location))
        var arrayTo = fieldsToShowTo.map((fields) => fields.geometry).concat(stacksToShowTo.map((stack) => stack.location))
        var totalArray = arrayFrom.concat(arrayTo)
        console.log(totalArray)
        if(totalArray.length > 0){
            // collectionFrom = union.apply(this, fieldsToShowFrom.map((fields) => fields.geometry))
            collectionFrom = featureCollection(totalArray)
            map?.fitBounds(bbox(collectionFrom), { speed: 0.5, essential: true, padding: { top: 50, bottom: 50, left: 50, right: 50 } })
        }     
       
    })

    const getPopUp = ({selectedField}) => {
        console.log("Get popup")
        return (<FieldInfoPopUp fieldID={selectedField}/>)
    }

    const getStackPopUp = ({selectedStack}) =>{
        console.log("Get stack popup")
        return (<StackInfoPopup stackID={selectedStack.id}/>)
    }

    return (
        <>
            <FieldsLayer fields={fieldsToShowFrom} visible={fieldsToShowFrom.length > 0} allowPopUp ={true} selectable={false} name='jobdetailfieldsFrom' color={{ unclicked: '#eb6734', clicked: '#fccf03'}} type = {POPUPTYPE.FIELD} getPopUp = {getPopUp}>
                <Layer type='symbol' id={"fromFieldsLabels"} sourceId={'jobdetailfieldsFrom' + endCentroids} filter={['!',['has', 'point_count']]} maxzoom={10} layout={{'text-field': labelFrom }} paint={{"text-color": "#202","text-halo-color": "#fff","text-halo-width": 2 }}/>    
            </FieldsLayer>
            <FieldsLayer fields={fieldsToShowTo} visible = {fieldsToShowTo.length > 0} allowPopUp ={true} selectable={false} name='jobdetailfieldsTo' color={{ unclicked: '#2feb74', clicked: '#fccf03'}} type = {POPUPTYPE.FIELD} getPopUp = {getPopUp}>
                <Layer type='symbol' id={"toFieldsLabels"} sourceId={'jobdetailfieldsTo' + endCentroids} filter={['!',['has', 'point_count']]} minZoom={10} layout={{'text-field': labelTo}} paint={{"text-color": "#202","text-halo-color": "#fff","text-halo-width": 2 }}/>
            </FieldsLayer>  
            <StackLayer   id ={" stackFrom"} stacks={stacksToShowFrom}  visible = {stacksToShowFrom.length > 0} allowPopUp ={true} selectable={false}  name='jobdetailStacksFrom' color={{ unclicked: '#eb6734', clicked: '#fccf03'}} type = {POPUPTYPE.STACK} getPopUp = {getStackPopUp}>
                <Layer type='symbol' id={"fromStacksLabels"} sourceId={'jobdetailStacksFrom' + endCentroidsStacks} filter={['!',['has', 'point_count']]} maxzoom={10} layout={{'text-field': labelFrom }} paint={{"text-color": "#202","text-halo-color": "#fff","text-halo-width": 2 }}/>    
            </StackLayer>
            <StackLayer   id ={" StackTo"} stacks={stacksToShowTo}  visible = {stacksToShowTo.length > 0} allowPopUp ={true} selectable={false}  name='jobdetailStacksTo' color={{ unclicked: '#2feb74', clicked: '#fccf03'}} type = {POPUPTYPE.STACK} getPopUp = {getStackPopUp}>
                <Layer type='symbol' id={"toStacksLabels"} sourceId={'jobdetailStacksTo' + endCentroidsStacks} filter={['!',['has', 'point_count']]} minZoom={10} layout={{'text-field': labelTo}} paint={{"text-color": "#202","text-halo-color": "#fff","text-halo-width": 2 }}/>
            </StackLayer>
        </>
    )
}

const DashboardUtilPanelJobs = () => {
    const jobs = useSelector(selectJobs)
    const itemState = useSelector(selectNewItemState)
    const layersVisible = useSelector(selectLayerVisible)
    const dispatch = useDispatch()
    const classes = useStyles(); 


    const handleNewJob = () => {
        console.log("Create new job")
        dispatch(clearFieldSelection())
        dispatch(setSelectkey(""))
        dispatch(setShowJobDetails({}))
        dispatch(setVisibility(layersVisible))
        dispatch(setNewItemState({...itemState, job: new TransportJob(), isEditing: true, isNew: true}))
    }

    return (<>
        {_.isEmpty(itemState.job) && <div>
            <ShowWhen persmissions={[Permission.create_job]}>
            <Tooltip title="Add" aria-label="add">
                <Fab
                    size="small"
                    color="secondary"
                    className={classes.fab}
                    onClick={handleNewJob}
                >
                    <ContentAdd />
                </Fab>
            </Tooltip>
            </ShowWhen>
        </div>}
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={2}
        >
            {itemState.isNew && <EditJobTile />}
            {jobs.length > 0 ? (
                jobs.map((job) => {
                    return (itemState.isEditing === true && job.id === itemState.job?.id ? <EditJobTile /> : <JobTile job={job} />)
                }
                ))
                : (
                    <Grid item>
                        <p style={{ textAlign: "center" }}>No Data Found !</p>
                    </Grid>)
            }
        </Grid>        
    </>
    )
}

export default DashboardUtilPanelJobs