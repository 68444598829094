import { Switch, Tooltip } from "@material-ui/core";
import { pink } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ContentAdd from "@material-ui/icons/Add";
import { bbox, union } from '@turf/turf';
import _ from "lodash";
import React, { useContext, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { useDispatch, useSelector } from 'react-redux';
import { EditStackTile } from '../../components/Stack/EditStackTile';
import { StackTile } from '../../components/Stack/StackTile';
import { editingStack, editStackIsNewStack, isEditingAStack, selectStacks, startNewStack } from "../../redux/slicers/stackSlice";
import { selectLayerVisible, setLayerVisible } from "../../redux/slicers/dashboardSlice";
import { Stack } from "../../models/stack";
import { clearFieldSelection, setVisibility } from "../../redux/slicers/mapSlice";


const pink500 = pink['500'];

const useStyles = makeStyles({
    fab: {
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        marginRight: 20,
        backgroundColor: pink500, // {pink500}
    }
})

const DashboardUtilPanelStacks = () => {
    const stacks = useSelector(selectStacks)
    const classes = useStyles(); 
  

    return (<>

        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={2}
        >
         
         
            {stacks.length > 0 ? (
                 <>                 
                {stacks.map((stack) => {                
                    return (<StackTile stack={stack} />)
                }
                )}</>)
                : (
                    <Grid item>
                        <p style={{ textAlign: "center" }}>No Data Found !</p>
                    </Grid>)
            }
        </Grid>        
    </>
    )
}

export default DashboardUtilPanelStacks
