import { createSlice,createAsyncThunk, createEntityAdapter  } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

const customerAdapter = createEntityAdapter()

const initialState = customerAdapter.getInitialState({
    status: 'idle',
    statusMsg: "",
    filter: '', 
    searchCVR: '',
    customerFields:[]  
  })

export const fetchDKFields = createAsyncThunk('customers/fetchDKFields', async (arg,{rejectWithValue}) => {

    const serverUrl = process.env.REACT_APP_SERVER_URL;  
    console.log("Fetching DK Fields at at " + serverUrl)  
    const response = await fetch(
      `${serverUrl}/api/GetDKField`,
        {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${arg.token}`,
          },
        body: JSON.stringify(arg.search)
        }
    );   

    const data = await response.json()
    if (response.ok) {     
      return data
    }
})


  
export const fetchCustomers = createAsyncThunk('customers/fetchCustomers', async token => {

    const serverUrl = process.env.REACT_APP_SERVER_URL;  
    console.log("Fetching customers at " + serverUrl)    
    const response = await fetch(
      `${serverUrl}/api/ListCustomers`,
        {
        headers: {
            Authorization: `Bearer ${token}`,
          },
        }
    );   

    const data = await response.json()
    if (response.ok) {     
      return data
    }
})

export const deleteCustomer = createAsyncThunk('customers/deleteCustomer', async (arg,{rejectWithValue,dispatch}) => {

  const serverUrl = process.env.REACT_APP_SERVER_URL;  
  console.log("Deleteing customer : ")
  console.log(JSON.stringify(arg.customer, null, 2))    
  console.log("with token");
  console.log(arg.token)
  const response = await fetch(
    `${serverUrl}/api/DeleteCustomer`,
      {
      method: 'DELETE',
      headers: {
          Authorization: `Bearer ${arg.token}`,
        },
        body: JSON.stringify(arg.customer)     
      }
  );   

  const data = await response.json()
  if (response.ok) {   
    await dispatch(fetchCustomers(arg.token))  
    return data
    } else {
    return rejectWithValue(response)
    }
})

export const createCustomer = createAsyncThunk('customers/createCustomer', async (arg,{rejectWithValue,dispatch}) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;  
  console.log("creating customer : ")
  console.log(arg.customer)    
  const response = await fetch(
    `${serverUrl}/api/CreateCustomer`,
     {
      method: 'POST',
      headers: {Authorization: `Bearer ${arg.token}`},
      body: JSON.stringify(arg.customer)      
     }
  );   

  const data = await response.json()
  if (response.ok) {   
    await dispatch(fetchCustomers(arg.token))  
    return data
  } else {
    return rejectWithValue(response)
  }
})

export const updateCustomer = createAsyncThunk('customers/updateCustomer', async (arg,{rejectWithValue,dispatch}) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;  
  console.log("updating customer : ")
  console.log(arg.customer)    
  const response = await fetch(
    `${serverUrl}/api/UpdateCustomer`,
      {
      method: 'POST',
      headers: {Authorization: `Bearer ${arg.token}`},
      body: JSON.stringify(arg.customer)      
      }
  );   

  const data = await response.json()
    if (response.ok) {
      await dispatch(fetchCustomers(arg.token))    
      return data
    } else {
      return rejectWithValue(response)
    }
})

const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {  
    customerFiltered(state,action){
        state.filter = action.payload
    },
    resetStatus(state,action){
        state.status = 'idle'
        state.statusMsg = ""
    },
    setCustomerCVR(state,action){
        state.searchCVR = action.payload
    },
    clearCustomerFields(state,action){
      state.customerFields = [];
    }
    
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCustomers.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
         const newEntities = {}
         const id = []
         action.payload.forEach(customer => {
           newEntities[customer.id] = customer
           id.push(customer.id)
         })
        state.ids = id
        state.entities = newEntities
        state.status = 'idle'
      })
      .addCase(deleteCustomer.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        console.log(action.payload.id)
        state.status = 'success'
        state.statusMsg = "Succesfully deleted customer"
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        console.log("Customer could not be deleted deleted")
        state.status = 'error'
        state.statusMsg = action.payload 
      }) 
      .addCase(createCustomer.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        console.log(action)
        state.status = 'success'
        state.statusMsg = "Succesfully created customer"
      })
      .addCase(createCustomer.rejected, (state, action) => {
        console.log(action)
        state.status = 'error'
        state.statusMsg = "Could not create customer" 
      }) 
      .addCase(updateCustomer.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        console.log(action)
        state.status = 'success'
        state.statusMsg = "Succesfully Updated customer"
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        console.log(action)
        state.status = 'error'
        state.statusMsg = "Could not update customer" 
      }) 
      .addCase(fetchDKFields.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchDKFields.fulfilled, (state, action) => {
        state.customerFields = action.payload.features
        state.status = 'idle'
        state.statusMsg = "Succesfully fetched customer fields"
      })
      .addCase(fetchDKFields.rejected, (state, action) => {
        console.log(action)
        state.status = 'error'
        state.statusMsg = "Could not fetch customer fields" 
      }) 
  }
})

export const {resetStatus,customerFiltered,setCustomerCVR,clearCustomerFields} = customerSlice.actions

export default customerSlice.reducer

export const {
    selectAll: selectCustomers,
    selectById: selectCustomerById
  } = customerAdapter.getSelectors(state => state.customers)

  
const getSearchFilter = state => {
    return state.customers.filter}

export const searchCustomers = createSelector(
    [getSearchFilter,customerAdapter.getSelectors(state => state.customers).selectAll],
    (searchFilter, customers) => {    
        return customers.filter(customer => { 
            if(customer){          
              return (          
              customer?.name?.toLowerCase().includes(searchFilter.toLowerCase()) || 
              customer?.cvr?.toString().toLowerCase().includes(searchFilter.toLowerCase()) || 
              customer?.phoneNo?.toString().toLowerCase().includes(searchFilter.toLowerCase()) || 
              customer?.postalCode?.toString().toLowerCase().includes(searchFilter.toLowerCase()) || 
              customer?.streetname?.toString().toLowerCase().includes(searchFilter.toLowerCase()) || 
              searchFilter === "")
            } else {
              return false
            }           
            
        })
    }
)
export const selectCustomerStatus = state => state.customers.status
export const selectCustomerFields = state => state.customers.customerFields