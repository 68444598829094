// src/components/auth-nav.js

import React from "react";
import AuthenticationButton from "./authentication-button";

const AuthNav = () => (  
    <AuthenticationButton />  
);

export default AuthNav;
