export enum POPUPTYPE {
    FIELD = "FIELD",
    STACK = "STACK",
    EU_FIELD = "EU_FIELD",
    NEW_FIELD = "NEW_FIELD"
}

export enum NEW_ITEM_TYPE {
    FIELD = "FIELD",
    STACK = "STACK",
    JOB = "JOB", 
}