import React from  'react';
import { Row } from 'simple-flexbox';
import { createUseStyles,useTheme } from 'react-jss';

const useStyles = createUseStyles({
  content: {
    overflowWrap: 'anywhere'
  }
  
});

const ProfileView = ({user, token}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
    return (
      <Row className = {classes.content} wrap={true} horizontal= 'center'>
        <div>
          <div className="row align-items-center profile-header">
            <div className="col-md-2 mb-3">
              <img
                src={user.picture}
                alt="Profile"
                className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
              />
            </div>
            <div className="col-md text-center text-md-left">
              <h2>{user.name}</h2>
              <p className="lead text-muted">{user.email}</p>
            </div>
          </div>
          <div className="row">
            <pre className="col-12 text-light bg-dark p-4">
              {JSON.stringify(user, null, 2)}             
            </pre>
          </div>

          {(process.env.NODE_ENV === "development") &&
   
          <div className="row">
            <p className="col-12 text-light bg-dark p-4">
              {token}
            </p>
          </div> }
        </div>
        </Row>
      );
};

export default ProfileView;
