import { useAuth0 } from "@auth0/auth0-react";
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader, TextField, Tooltip } from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import { blue, common, green, grey, red } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ContentCreate from "@material-ui/icons/Create";
import ActionDelete from "@material-ui/icons/Delete";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { JobStatus, JobType } from '../../models/job';
import { LocationType } from '../../models/location';
import { selectCustomerById } from "../../redux/slicers/customerSlice";
import { openDrawer, selectLayerVisible, selectNewItemState, selectShowJobDetails, setNewItemState, setSelection, setSelectkey, setShowJobDetails } from "../../redux/slicers/dashboardSlice";
import { deleteJob, isEditingAJob, setEditJob } from '../../redux/slicers/jobsSlice';
import { mapSetSelectedFieldIds, mapSetSelectedStackIds, setSelectablility, setVisibility } from "../../redux/slicers/mapSlice";
import { createSelectByAuth0Id, selectUsers } from '../../redux/slicers/userSlice';
import PersonIcon from '@material-ui/icons/Person';
import DeleteDialog from "../DeleteDialog";
import { useResponsive } from "../Responsive";
import { ShowWhen } from "../../navigation/role-renderer/ShowWhen";
import Permission from "../../navigation/role-renderer/Permissions";


const grey500 = grey["500"];
const green400 = green["400"];

const white = common.white;

const useStyles = makeStyles((theme) => ({
  root: {
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatarTransport: {
    backgroundColor: blue[500],
  },
  avatarBale: {
    backgroundColor: green[500],
  },
  editButtonIcon: {
    fill: white,
  },
  editButton: {
    marginRight: "1em",
    color: white,
    backgroundColor: green400,
  },
  showButtonSelected: {
    backgroundColor: green400,
  },
  showButton: {
    // marginLeft: "2em"
  },
  deleteButton: {
    color: "white",
    marginRight: "1em",
    backgroundColor: red[500],
  },
  doneButtonIcon: {
    fill: white,
  },
  doneButton: {
    marginRight: "1em",
    color: white,
    backgroundColor: green400,
  },
  cancelButton: {
    color: "grey",
    fill: grey500,
  }
}));

export function SelectionCount(text, count) {

  return (
    <Grid item>
      <Typography variant="body1" align='center'>
        {text} : {count}
      </Typography>
    </Grid>
  )
}



export function TransportJobIcon() {
  const classes = useStyles();
  return (<Tooltip title="Transport Job" aria-label="transportJob"><Avatar aria-label="recipe" className={classes.avatarTransport}>T</Avatar></Tooltip>)
}

export function BaleJobIcon() {
  const classes = useStyles();
  return (<Tooltip title="Presse job" aria-label="balingJob"><Avatar aria-label="recipe" className={classes.avatarBale}>P</Avatar></Tooltip>)
}

export const getToTypography = (location) => {
  if (!location) {
    return "Til ukendt"
  }
  switch (location.type) {
    case LocationType.CUSTOMER:
      return "Til kunde: "

    case LocationType.FIELD:
      return "Til mark: "

    case LocationType.STACK:
      return "Til stak: "

    default:
      return "Til ukendt";
  }
}

export const getFromTypography = (location) => {
  if (!location) {
    return "Fra ukendt"
  }
  switch (location.type) {
    case LocationType.CUSTOMER:
      return "Fra kunde: "

    case LocationType.FIELD:
      return "Fra mark: "

    case LocationType.STACK:
      return "Fra stak: "

    default:
      return "Fra ukendt";
  }
}

export const getTextFromStatus = (Status) => {
  switch (Status) {
    case JobStatus.NEW:
      return "Planlægger"

    case JobStatus.CREATED:
      return "Oprettet"

    case JobStatus.READY:
      return "Klar"

    case JobStatus.IN_PROGRESS:
      return "I gang"

    case JobStatus.FINISHED:
      return "Færdig"
    default:
      return "ukendt";
  }
}


function TransportContent({ job }) {
  return (
    <>
      <Grid container item direction="row" spacing={2} alignItems='center'>
        <Grid item>
          <Typography variant="h6" color="textPrimary" component="p">
            Destinationer:
                </Typography>
        </Grid>
        {SelectionCount('Marker', job.toLocation?.filter((location) => location.type === LocationType.FIELD).length)}
        {SelectionCount('Stakke', job.toLocation?.filter((location) => location.type === LocationType.STACK).length)}
    </Grid>
      <Grid container item direction="row" spacing={2} alignItems='center'>
        <Grid item>
          <Typography variant="h6" color="textPrimary" component="p">
            Afhenting:
              </Typography>
        </Grid>
        {SelectionCount('Marker', job.fromLocation?.filter((location) => location.type === LocationType.FIELD).length)}
        {SelectionCount('Stakke', job.fromLocation?.filter((location) => location.type === LocationType.STACK).length)}
      
      </Grid>
    </>)
}


function BaleContent({ job }) {

  const toCustomer = useSelector((state) => selectCustomerById(state, job.toCustomerId))
  const fromCustomer = useSelector((state) => selectCustomerById(state, job.atCustomerId))

  return (
   <>
      <Grid container item direction="row" spacing={2} alignItems='center'>
        <Grid item>
          <Typography variant="h6" color="textPrimary" component="p">
            Presning:
                </Typography>
        </Grid>
        {SelectionCount('Marker', job.onLocation.filter((location) => location.type === LocationType.FIELD).length)}

      </Grid>
      <Grid item>
        <TextField defaultValue={fromCustomer?.name} label="Hos kunde" variant="outlined" InputProps={{ readOnly: true }} />
      </Grid>
      <Grid item>
        <TextField defaultValue={toCustomer?.name} label="Til kunde" variant="outlined" InputProps={{ readOnly: true }} />
      </Grid>
    </>)
}


export function JobTile({ job }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const showJobDetail = useSelector(selectShowJobDetails)
  const selectUserByAuth0Id = createSelectByAuth0Id(job?.createdByAuth0Id)
  const createdByUser = useSelector(selectUserByAuth0Id);
  const layersVisible = useSelector(selectLayerVisible)
  const users = useSelector(selectUsers)
  const itemState = useSelector(selectNewItemState)
  const [dialog, setDialog] = useState({open: false, onConfirm: null})
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch()
  const responsive = useResponsive()


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const onDelete = async () => {
    console.log("Delete job with id: " + job.id)
    const token = await getAccessTokenSilently();
    dispatch(deleteJob({ job: job, token: token }))
  }

  const onEdit = (e, job) => {    
    // dispatch(setShowJobDetails({}))    
    var selectedFields = [] 
    var selectedStacks = [] 
    
    
    if(job?.type === JobType.TRANSPORT){
      selectedFields = job?.toLocation?.filter((location) => 
        location.type === LocationType.FIELD
      ).map((location) => location.id )

      selectedStacks = job?.toLocation?.filter((location) => 
        location.type === LocationType.STACK
      ).map((location) => location.id )

    // dispatch(setSelectablility({fieldsLayer: true, stackLayer : true, balesLayer: false}))
    dispatch(setSelectkey('toLocation'))
    dispatch(setSelection({[LocationType.FIELD] : selectedFields}))
    dispatch(setSelection({[LocationType.STACK] : selectedStacks}))

    selectedFields = job?.fromLocation?.filter((location) => 
        location.type === LocationType.FIELD
      ).map((location) => location.id )

      selectedStacks = job.fromLocation?.filter((location) => 
        location.type === LocationType.STACK
      ).map((location) => location.id )

      dispatch(setSelectkey('fromLocation'))
      dispatch(setSelection({[LocationType.FIELD] : selectedFields}))
      dispatch(setSelection({[LocationType.STACK] : selectedStacks}))

      // dispatch(mapSetSelectedFieldIds(selectedFields))
      // dispatch(mapSetSelectedStackIds(selectedStacks))
    } else if (job?.type === JobType.BALING){      
      selectedFields = job?.onLocation?.filter((location) => 
        location.type === LocationType.FIELD
      ).map((location) => location.id )
      console.log(selectedFields)
    // dispatch(setSelectablility({fieldsLayer: true, stackLayer : false, balesLayer: false}))
    dispatch(setSelectkey('onLocation'))
    dispatch(setSelection({[LocationType.FIELD] : selectedFields}))
    // dispatch(mapSetSelectedFieldIds(selectedFields))
    }  

    dispatch(setShowJobDetails(job))
    dispatch(setVisibility({fieldsLayer: false,
      balesLayer: false,
      stackLayer: false,}
    ))
    dispatch(setNewItemState({...itemState, job: job, isEditing: true, isNew: false})) 
    dispatch(setSelectkey(""))

  }

  const onShow = (e, job) => {
    if (job.id === showJobDetail?.id) {
      //Disable detail view and set layers back to dashboard settings
      dispatch(setShowJobDetails({}))
      dispatch(setVisibility(layersVisible))
    } else {
      // Hide all default layers 
      dispatch(setShowJobDetails(job))
      dispatch(setVisibility({fieldsLayer: false,
        balesLayer: false,
        stackLayer: false,}
      ))
    }

    if(responsive.isMobile === true){    
      dispatch(openDrawer(false))
    }

  }

  const getAssignedUsers = (users) =>{
    var initialUsers = []
    initialUsers = users.filter(user => {
      const index = user.assignedJobs?.findIndex((aJob) => {     
        return aJob?.jobID === job.id
      })
      return !(index === -1)
    })
    return initialUsers
  }

  const closeDialog = (isConfirmed) => {
    setDialog({...dialog, open: false });     
    if (isConfirmed) {
        dialog.onConfirm()                    
    }
  }

  return (
    <Grid item>
      <Card className={classes.root}>
        <CardHeader
          avatar={
            job.type === JobType.TRANSPORT ?
              (<TransportJobIcon />) : (<BaleJobIcon />)
          }
          title={"Oprettet af: " + createdByUser?.name}
          subheader={"Status: " + getTextFromStatus(job.status)}
        />
        <CardContent>
          <Grid container direction="column" spacing={2}>
          {job.type === JobType.TRANSPORT ? <TransportContent job={job} /> : <BaleContent job={job} />}
          <Grid item>
            <List dense={false} 
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Tilknyttede brugere
                </ListSubheader>
              }>
                {getAssignedUsers(users).map((user) => {
                    return(<ListItem>
                              <ListItemIcon>
                                  <PersonIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary= {user.name}                
                                />
                            </ListItem>)
                })}
                
            </List>
          </Grid>

          </Grid>
        </CardContent>
        <CardActions disableSpacing>
          {!itemState.isEditing && <>
          <ShowWhen persmissions={[Permission.edit_job]}>
          <Tooltip title="Rediger" aria-label="edit">
            <Fab
              size="small"
              className={classes.editButton}
              onClick={(e) => onEdit(e, job)}
            >
              <ContentCreate />
            </Fab>
          </Tooltip>
          </ShowWhen></>}
          <ShowWhen persmissions={[Permission.delete_job]}>
          <Tooltip title="Slet" aria-label="delete">
            <Fab
              size="small"
              className={classes.deleteButton}
              onClick={(e) => { 
                setDialog({
                  open: true, 
                  onConfirm: onDelete,
                  title: "Sletning af job!",
                  text: "Sletning af jobbet er ikke muligt at fortryde!"
                })}}
            >
              <ActionDelete />
            </Fab>
          </Tooltip>
          </ShowWhen>

          {!itemState.isEditing && <Tooltip title="Vis" aria-label="vis">
            <Fab
              size="small"
              className={showJobDetail?.id === job.id ? classes.showButtonSelected : classes.showButton}
              onClick={(e) => onShow(e, job)}
            >
              <VisibilityIcon />
            </Fab>
          </Tooltip>}
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Noter:</Typography>
            <Typography>
              {job?.note}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
      <DeleteDialog
          open={dialog.open}
          closeDialog={closeDialog}
          dialogTitle = {dialog.title}
          dialogText = {dialog.text}
        />      
    </Grid>

  )
}

