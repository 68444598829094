import { withAuth0 } from '@auth0/auth0-react';
import { CardActionArea, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { common, green, red } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { selectEditFeature, setClosePopUp, setEditFeature, setDraw, setNewFieldPopUp, setPopUp, selectDraw } from '../../redux/slicers/mapSlice';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import GestureIcon from '@material-ui/icons/Gesture';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import { isConstructorDeclaration } from 'typescript';
import { clearCustomerFields, createCustomer, fetchDKFields, selectCustomerFields, selectCustomers, selectCustomerStatus } from '../../redux/slicers/customerSlice';
import { clearNewItemState, itemSelected, selectNewItemState, setNewItemState } from '../../redux/slicers/dashboardSlice';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Customer } from '../../models/customer';
import CustomerForm, { validateCustomer } from '../../pages/Customer/CustomerForm'
import { useState } from 'react';
import { createOwnFields } from '../../redux/slicers/fieldSlice';
import { Field, FieldStatus } from '../../models/organisationField';
import { createUseStyles } from 'react-jss';
import { NEW_ITEM_TYPE, POPUPTYPE } from './LayerTypes';
import { NewStackContent } from '../../components/Stack/EditStackTile';
import { Stack } from '../../models/stack';
import { createStack } from '../../redux/slicers/stackSlice';
import { formPageStyles } from '../../styles';
import { Formik } from 'formik';
import { CustomerFormContent } from '../../pages/Customer/CustomerForm';
import { truncate } from '@turf/turf';
import CircularProgress from '@material-ui/core/CircularProgress';
import Permission from '../../navigation/role-renderer/Permissions';
import { ShowWhen } from '../../navigation/role-renderer/ShowWhen';


const white = common.white;
const green400 = green["400"];
const styles = {
  root: {
    maxWidth: '300px',
    maxHeight: '600px',
    minWidth: '250px',
    overflow: 'auto'
  },
  media: {
    height: 140,
  },
  mapboxglPopupContent: {
    padding: 0,
  },
  deleteButton: {
    color: "white",
    marginRight: "1em",
    backgroundColor: red[500],
  },
  editButton: {
    marginRight: "1em",
    color: white,
    backgroundColor: green400,
  },

  button: {
    marginRight: '1em',
    height: "40px"
  },
  instructions: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  stepper: {
    padding: '3px'
  }

};

const useStyles = createUseStyles(styles)


function getSteps() {
  return ['Vælg', 'Opret', 'Rediger', 'Kør!'];
}

function FieldInfo({ field }) {
  console.log(field.properties)
  return (
    <div>
      <br /> {field.properties?.Afgroede}
      <br /> Areal: {field.properties?.IMK_areal} Ha
    </div>
  )
}


const DisplayCustomer = ({ customer }) => {

  console.log(customer)
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField defaultValue={customer.name} label="Navn" variant="outlined" InputProps={{ readOnly: true }} />
      </Grid>
      <Grid item>
        <TextField defaultValue={customer.cvr} label="CVR" variant="outlined" InputProps={{ readOnly: true }} />
      </Grid>
    </Grid>
  )
}

const CustomerHandling = ({ bindSubmitForm }) => {
  const customers = useSelector(selectCustomers)
  const newItemState = useSelector(selectNewItemState)
  const editFeature = useSelector(selectEditFeature)
  const dispatch = useDispatch()
  const [state, setState] = useState("unknown")
  const [submitForm, setSubmitForm] = useState(null)

  useEffect(() => {
    if (newItemState.EUField === true) {
      // DK_FIELD used -> Check if we already know the custome
      const customer = customers.find((customer) => { return customer?.cvr?.toString() === editFeature.properties?.CVR })

      if (customer === undefined) {
        setState("CreateSelect")
        var newCustomer = new Customer()
        newCustomer.cvr = editFeature.properties?.CVR
        dispatch(setNewItemState({ ...newItemState, customer: newCustomer, customerValid: false, cvrLocked: true, customerIsNew: true }))
      } else {
        setState('ShowCustomer')
        dispatch(setNewItemState({ ...newItemState, customer: customer, customerValid: true, customerIsNew: false }))
      }

    } else {
      dispatch(setNewItemState({ ...newItemState, customer: new Customer(), customerValid: false, cvrLocked: false, customerIsNew: true }))
      setState("CreateSelect")
    }
  }, [])

  return (
    <>
      {state === 'ShowCustomer' && <DisplayCustomer customer={newItemState.customer} />}
      {state === "CreateSelect" && <CreateCustomer bindSubmitForm={bindSubmitForm} />}
    </>
  )
}

const CreateCustomer = ({ bindSubmitForm }) => {

  const customers = useSelector(selectCustomers)
  const newItemState = useSelector(selectNewItemState)
  const dispatch = useDispatch()
  const [customerIsNew, setCreateCustomer] = useState(false)
  const classes = useStyles()


  useEffect(() => {
    if (newItemState.EUField === true) {
      // Field is an EU field and CVR was not recognised, so customer must be new
      setCreateCustomer(true)
    }
  }, [])


  const handleToggleCreateNewCustomer = () => {
    if (customerIsNew == false) {
      dispatch(setNewItemState({ ...newItemState, customer: new Customer(), customerValid: false }))
    }
    setCreateCustomer(!customerIsNew)


  }

  const handleCustomerSelect = (event, newVal) => {
    if (newVal) {
      dispatch(setNewItemState({ ...newItemState, customer: newVal, customerValid: true, customerIsNew: customerIsNew }))
    } else {
      dispatch(setNewItemState({ ...newItemState, customer: new Customer(), customerValid: false, customerIsNew: customerIsNew }))
    }
  }

  console.log("CreateCustomer")

  const onSubmit = (values) => {
    console.log(values)
    dispatch(setNewItemState({ ...newItemState, activeStep: 3, customer: values, customerIsNew: customerIsNew }))
  }

  const validate = (values) => {
    const result = validateCustomer(values)
    if (_.isEmpty(result) && newItemState.customerValid === false) {
      dispatch(setNewItemState({ ...newItemState, customerValid: true }))
    } else if (!_.isEmpty(result) && newItemState.customerValid === true) {
      dispatch(setNewItemState({ ...newItemState, customerValid: false }))
    }
    return result
  }

  return (
    <Grid container direction="column" spacing={2}>
      {(customerIsNew === false) ?
        <Grid item>
          <Autocomplete
            id="combo-from-customer"
            options={customers}
            value={newItemState.customer}
            getOptionLabel={(option) => option.name}
            onChange={handleCustomerSelect}
            // style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Vælg eksisterende kunde" variant="outlined" />}
          />
        </Grid>
        :
        <Grid item >
          <div>
            <Formik
              enableReinitialize
              initialValues={{
                ...newItemState.customer
              }}
              validate={validate}
              onSubmit={(values, { setSubmitting }) => {
                onSubmit(values);
                setTimeout(() => {
                  setSubmitting(false);
                }, 500);
              }}
            >
              {({ submitForm, isSubmitting, values }) => {

                bindSubmitForm(submitForm);
                return (<CustomerFormContent validateCVR={() => { }} disableCVR={newItemState.cvrLocked} />)
              }}
            </Formik>
          </div>
        </Grid>}
      <Grid item >
        {newItemState.EUField === false &&
          <Grid item>
            <Button
              variant="contained"
              color="default"
              className={classes.button}
              onClick={handleToggleCreateNewCustomer}
            >
              {customerIsNew ? "Vælg ekisterde kunde" : "Opret ny kunde i stedet"}
            </Button>
          </Grid>}
      </Grid>
    </Grid>
  )
}



export const CreateFieldContent = ({ coordinates, token }) => {

  const classes = useStyles()
  const dispatch = useDispatch()
  const newItemState = useSelector(selectNewItemState)
  const customerFields = useSelector(selectCustomerFields)
  const draw = useSelector(selectDraw)
  const status = useSelector(selectCustomerStatus)

  useEffect(() => {
    if (newItemState.updateEditFeature === true && customerFields.length > 0) {
      dispatch(setNewItemState({ ...newItemState, updateEditFeature: false, EUField: true }))
      dispatch(setDraw({
        ...draw, enableLayer: true,
        enableEdit: false
      }))
      dispatch(setEditFeature(customerFields[0]))
    }
  })


  const handleFindField = () => {
    dispatch(clearCustomerFields())
    dispatch(setEditFeature({}))
    dispatch(fetchDKFields({ search: { lng: coordinates.lng, lat: coordinates.lat }, token: token }))
    dispatch(setNewItemState({ ...newItemState, updateEditFeature: true }))
  }

  const handleDrawNewField = () => {
    dispatch(setDraw({
      controls: {
        polygon: true,
        point: false,
        line_string: false,
        trash: true,
        combine_features: false,
        uncombine_features: false,
      }, enableLayer: true,
      enableEdit: true
    }))
    dispatch(setNewItemState({ ...newItemState, EUField: false }))
    dispatch(setEditFeature({}))
    dispatch(clearCustomerFields())
    dispatch(setClosePopUp())

  }

  return (
    <div>
      <Grid container direction="row" spacing={2} justify="center">
        <Grid item>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            startIcon={status !== "loading" ? <CloudDownloadIcon /> : <CircularProgress size={20} />}
            onClick={handleFindField}
            disabled={status === "loading"}
          >
            Hent
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            startIcon={<GestureIcon />}
            onClick={handleDrawNewField}
          >
            Tegn
          </Button>
        </Grid>
      </Grid>
      {customerFields.length > 0 && <FieldInfo field={customerFields[0]} />}
    </div>
  )
}


export const CreateStackContent = (coordinates) => {

  const classes = useStyles()
  const dispatch = useDispatch()
  const newItemState = useSelector(selectNewItemState)
  useEffect(() => {

  })

  const handleDrawNewStack = () => {
    dispatch(setDraw({
      controls: {
        polygon: true,
        point: true,
        line_string: false,
        trash: true,
        combine_features: false,
        uncombine_features: false,
      },
      enableLayer: true,
      enableEdit: true
    }))
    dispatch(setEditFeature({}))
    dispatch(setClosePopUp())
  }

  return (
    <div>
      <Grid container direction="row" justify="center">
        <Grid item>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            startIcon={<GestureIcon />}
            onClick={handleDrawNewStack}
          >
            Tegn
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}







class NewItemPopUp extends React.Component {

  submitCustomerForm = null;

  constructor(props) {
    super(props)
    this.onClose = this.onClose.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.onStackChange = this.onStackChange.bind(this)
    this.showNextButton = this.showNextButton.bind(this)
    this.bindSubmitForm = this.bindSubmitForm.bind(this)
    this.handleAddCustomerFieldsToOwn = this.handleAddCustomerFieldsToOwn.bind(this)
    this.state = {
    }
  }

  onClose(e, stack) {

    this.props.clearCustomerFields()
    this.props.setEditFeature({})
    this.props.setDraw({
      enableLayer: false,
      enableEdit: false
    })
    this.props.clearNewITemState()
    this.props.onClose()
  }

  async componentDidMount() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    this.setState({ token: token });
  }

  handleAddCustomerFieldsToOwn(customerId) {

    if (this.props.newItemState.EUField === true && this.props.newItemState.customerValid === true) {
      var customerField = JSON.parse(JSON.stringify(this.props.editFeature))
      customerField.properties.id = customerField.properties.Marknr + customerField.properties.Journalnr;
      delete customerField['id'];
      var newField = new Field()
      newField.geometry = customerField;
      newField.status = FieldStatus.READY_FOR_BALING
      newField.ownerID = customerId
      if (!_.isEmpty(newField.geometry)) {
        this.props.createOwnFields({ fields: [newField], token: this.state.token })
      }
      this.onClose()
      return
    }

    if (this.props.newItemState.EUField !== true) {
      var customerField = JSON.parse(JSON.stringify(this.props.editFeature))
      customerField.properties.id = "MANUALLY_CREATED"
      var newField = new Field()
      newField.geometry = customerField;
      newField.status = FieldStatus.READY_FOR_BALING
      newField.ownerID = customerId
      if (!_.isEmpty(newField.geometry)) {
        this.props.createOwnFields({ fields: [newField], token: this.state.token })
      }
      this.onClose()
      return
    }
  }

  handleNext() {
    // Last step pushing finish
    if (this.props.newItemState.activeStep === getSteps().length - 1) {

      // We are done -> Create field and maybe customer
      if (this.props.newItemState.type === NEW_ITEM_TYPE.FIELD) {
        if (this.props.newItemState.customerIsNew === true) {
          // Create new customer
          var customer = JSON.parse(JSON.stringify(this.props.newItemState.customer))
          customer.organisationId = this.props.auth0.user['http://smartbaling.com/metadata'].organisation
          console.log(customer)
          this.props.createCustomer({ customer: [customer], token: this.state.token }).then((value) => {
            if (value.type === "customers/createCustomer/fulfilled")
              console.log("Create field")
            this.handleAddCustomerFieldsToOwn(value.payload[0].id)
          })
        } else {

          //Customer is known, just create field
          this.handleAddCustomerFieldsToOwn(this.props.newItemState.customer.id)
        }



      } else if (this.props.newItemState.type === NEW_ITEM_TYPE.STACK) {
        // Create stack  
        var finishedStack = {}
        var feature = JSON.parse(JSON.stringify(this.props.editFeature));
        delete feature.id
        finishedStack = { ...this.props.newItemState.stack, location: feature, organisationId: this.props.auth0.user['http://smartbaling.com/metadata'].organisation }
        this.props.createStack({ stack: [finishedStack], token: this.state.token })
        this.props.setEditFeature({})
        this.props.setDraw({
          enableLayer: false,
          enableEdit: false
        })
        this.onClose()
      }

    } else {

      // Check if we should submit customer form
      if (this.props.newItemState.activeStep === 2 && this.props.newItemState.type === NEW_ITEM_TYPE.FIELD) {
        // submit customer form
        if (this.submitCustomerForm) {
          console.log("Submitting")
          this.submitCustomerForm()
        }
      }

      this.props.setNewItemState({ ...this.props.newItemState, activeStep: this.props.newItemState.activeStep + 1 })

    }
  };

  handleBack() {
    this.props.setNewItemState({ ...this.props.newItemState, activeStep: this.props.newItemState.activeStep - 1 })
  };
  handleReset() {
    this.props.setNewItemState({ ...this.props.newItemState, activeStep: 0 })
  };

  handleSelect(selection) {
    this.props.setEditFeature({})
    this.props.clearCustomerFields()
    if (selection === NEW_ITEM_TYPE.STACK) {
      this.props.setNewItemState({ ...this.props.newItemState, activeStep: this.props.newItemState.activeStep + 1, type: selection, stack: new Stack(), isNew: true, isEditing: true })
    } else if (selection === NEW_ITEM_TYPE.FIELD) {
      this.props.setNewItemState({ ...this.props.newItemState, activeStep: this.props.newItemState.activeStep + 1, type: selection, field: new Field(), isNew: true, isEditing: true })
    }
  }

  onStackChange(stack) {

    this.props.setNewItemState({ ...this.props.newItemState, stack: stack })
  }

  showNextButton() {

    switch (this.props.newItemState.activeStep) {

      case 0: return false;
      case 1: return !_.isEmpty(this.props.editFeature)
      case 2:
        if (this.props.newItemState.type === NEW_ITEM_TYPE.FIELD && this.props.newItemState.customerValid === true) {
          return true
        }

        if (this.props.newItemState.type === NEW_ITEM_TYPE.STACK) {
          return true
        }
        return false;

      case 3:
        return true;

    }
  }

  bindSubmitForm(submitForm) {
    this.submitCustomerForm = submitForm
  }

  render() {

    const { classes } = this.props
    return (
      <>
        <Card className={classes.root}>

          <Stepper activeStep={this.props.newItemState.activeStep} alternativeLabel classes={{ root: classes.stepper }}>
            {getSteps().map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <CardContent>
            <div>
              {this.props.newItemState.activeStep === getSteps().length ? (
                <div>
                  <Typography className={classes.instructions}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={this.handleReset} className={classes.button}>
                    Nulstil
                  </Button>
                </div>
              ) : (
                <div>
                  {this.props.newItemState.activeStep === 0 &&

                    <Grid container direction="row" justify="center">
                      <ShowWhen persmissions={[Permission.create_field]}>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.handleSelect(NEW_ITEM_TYPE.FIELD)}
                            className={classes.button}
                          >
                            Opret Mark
                          </Button>
                        </Grid>
                      </ShowWhen>
                      <ShowWhen persmissions={[Permission.create_stack]}>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.handleSelect(NEW_ITEM_TYPE.STACK)}
                            className={classes.button}
                          >
                            Opret Stak
                          </Button>
                        </Grid>
                      </ShowWhen>
                    </Grid>
                  }

                  {(this.props.newItemState.activeStep === 1 && this.props.newItemState.type === NEW_ITEM_TYPE.FIELD) &&
                    <CreateFieldContent coordinates={this.props.coordinates} token={this.state.token} />
                  }
                  {(this.props.newItemState.activeStep === 1 && this.props.newItemState.type === NEW_ITEM_TYPE.STACK) &&
                    <CreateStackContent coordinates={this.props.coordinates} />
                  }

                  {(this.props.newItemState.activeStep === 2 && this.props.newItemState.type === NEW_ITEM_TYPE.FIELD) &&
                    <CustomerHandling bindSubmitForm={this.bindSubmitForm} />
                  }

                  {(this.props.newItemState.activeStep === 2 && this.props.newItemState.type === NEW_ITEM_TYPE.STACK) &&
                    <NewStackContent editStack={this.props.newItemState.stack} onStackChange={this.onStackChange} />
                  }

                </div>
              )}
            </div>
          </CardContent>

          <CardActions disableSpacing>
            <Button disabled={this.props.newItemState.activeStep === 0} onClick={this.handleBack} className={classes.button}>
              Tilbage
            </Button>
            {this.showNextButton() === true &&
              (<Button
                variant="contained"
                color="primary"
                onClick={this.handleNext}
                className={classes.button}
              >
                {this.props.newItemState.activeStep === getSteps().length - 1 ? 'Færdig' : 'Næste'}
              </Button>)}
            <Tooltip title="Luk" aria-label="close">
              <Fab
                size="small"
                className={classes.closeButton}
                onClick={(e) => this.onClose()}
              >
                <CloseIcon />
              </Fab>
            </Tooltip>
          </CardActions>
        </Card>
      </>
    );
  }

}

const mapStateToProps = state => (
  {
    editFeature: selectEditFeature(state),
    newItemState: selectNewItemState(state),
    customerFields: selectCustomerFields(state),
  })


function mapDispatchToProps(dispatch) {
  return {
    setNewItemState: (arg) => dispatch(setNewItemState(arg)),
    clearNewITemState: () => dispatch(clearNewItemState()),
    clearCustomerFields: () => dispatch(clearCustomerFields()),
    setEditFeature: (arg) => dispatch(setEditFeature(arg)),
    setDraw: (arg) => dispatch(setDraw(arg)),
    createOwnFields: (arg) => dispatch(createOwnFields(arg)),
    createStack: (arg) => dispatch(createStack(arg)),
    createCustomer: (arg) => dispatch(createCustomer(arg))
  };
}

export default withAuth0(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NewItemPopUp)))