import { createSlice,createAsyncThunk, createEntityAdapter  } from '@reduxjs/toolkit'

const baleAdapter = createEntityAdapter()

const initialState = baleAdapter.getInitialState({
    status: 'idle'
  })
  

  export const fetchBales = createAsyncThunk('bales/fetchBales', async token => {

      const serverUrl = process.env.REACT_APP_SERVER_URL;  
      console.log(token)
      console.log("Fetching bales at " + serverUrl)   
    
      const response = await fetch(
        `${serverUrl}/api/ListBale`,
         {
          headers: {
             Authorization: `Bearer ${token}`,
           },
         }
      );   

      const data = await response.json()
      if (response.ok) {       
        return data
      }
  })

const baleSlice = createSlice({
  name: 'bales',
  initialState,
  reducers: {
    baleAdded(state, action) {
      // ✅ This "mutating" code is okay inside of createSlice!
      const bale = action.payload
      state.entities[bale.id] = bale
    },    
    balesLoading(state, action) {
      return {
        ...state,
        status: 'loading'
      }
    },
    baleDeleted(state,action){
        delete state.entities[action.payload]
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchBales.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchBales.fulfilled, (state, action) => {
        //baleAdapter.setAll(state, action.payload)
        console.log("Fetch bales fulfilled")
        console.log(action)
         const newEntities = {}
         const id = []
         action.payload.forEach(bale => {
           newEntities[bale.id] = bale
           id.push(bale.id)
         })
        state.ids = id
        state.entities = newEntities
        state.status = 'idle'
      })      
  }
})

export const { baleAdded, balesLoading, baleDeleted} = baleSlice.actions

export default baleSlice.reducer

export const {
    selectAll: selectBales,
    selectById: selectBaleById
  } = baleAdapter.getSelectors(state => state.bales)