import { useAuth0 } from '@auth0/auth0-react';
import { Fab, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { common, green, red } from "@material-ui/core/colors";
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import ContentCreate from "@material-ui/icons/Create";
import ActionDelete from "@material-ui/icons/Delete";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import area from '@turf/area';
import center from '@turf/center';
import _ from 'lodash';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomers } from '../../redux/slicers/customerSlice';
import { openDrawer, selectNewItemState } from '../../redux/slicers/dashboardSlice';
import { deleteField, editingField, isEditingAField, selectFields, selectFieldsById, setEditField } from '../../redux/slicers/fieldSlice';
import { selectPopUp, setEditFeature, setDraw, setPopUp } from '../../redux/slicers/mapSlice';
import { POPUPTYPE, NEW_ITEM_TYPE } from '../../services/maps/LayerTypes';
import { MapboxContext } from '../../services/maps/MapComponent';
import { useResponsive } from '../Responsive';
import { EditFieldTile } from './EditFieldTile';
import { getTextFromStatus } from './FieldTile';

const white = common.white;
const green400 = green["400"];

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  deleteButton: {
    color: "white",
    marginRight: "1em",
    backgroundColor: red[500],
  },
  editButton: {
    marginRight: "1em",
    color: white,
    backgroundColor: green400,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableCell: {
    padding: "8px 8px"
  } 

});


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }


function Row(props) {
  const { field } = props;  
  const dbField = useSelector((state) => selectFieldsById(state,field.id))
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const popup = useSelector(selectPopUp)
  const map = useContext(MapboxContext)
  const itemState = useSelector(selectNewItemState)
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0();

  const responsive = useResponsive()

  const handleClick = () =>{

    if(itemState.isEditing === true) return

    if(!_.isEmpty(dbField.geometry)){
        const coord = center(dbField.geometry).geometry.coordinates    
        map?.flyTo({center: coord, essential: true})
    }

    // Show popup      
    dispatch(setPopUp({id: dbField.id, type: POPUPTYPE.FIELD})) 
    
    if(responsive.isMobile === true){    
      dispatch(openDrawer(false))
    }
    

  } 
  return (
    <React.Fragment>
      <TableRow className={classes.root} selected={popup.id === field.id} >
        <TableCell  className={classes.tableCell} >
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell  className={classes.tableCell} component="th" scope="row" onClick={() => handleClick()}>
          {getTextFromStatus(field.status)}
        </TableCell> 
        <TableCell className={classes.tableCell}  align="left" onClick={() => handleClick()}>{field.owner}</TableCell>
        <TableCell className={classes.tableCell}  align="right" onClick={() => handleClick()}>{field.areal}</TableCell>
        <TableCell className={classes.tableCell}  align="left" onClick={() => handleClick()}>{field.crop}</TableCell>
        <TableCell  className={classes.tableCell} align="right" onClick={() => handleClick()}>{field.amount}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
            <Typography paragraph>Noter:</Typography>
            <Typography>
              {field?.note}
             </Typography>  
             {/* {itemState.idEditing !== true && <Tooltip title="Rediger" aria-label="edit">
                    <Fab
                    size="small"
                    className={classes.editButton}
                    onClick={(e) => onEdit(e)}
                    >
                    <ContentCreate />
                    </Fab>
                </Tooltip>}
                <Tooltip title="Slet" aria-label="delete">
                    <Fab
                    size="small"
                    className={classes.deleteButton}
                    onClick={(e) => onDelete(e)}
                    >
                    <ActionDelete />
                    </Fab>
                </Tooltip>            */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const headCells = [
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'owner', numeric: false, disablePadding: false, label: 'Ejer' },
    { id: 'areal', numeric: true, disablePadding: false, label: 'Areal (Ha)' },
    { id: 'crop', numeric: false, disablePadding: false, label: 'Afgrøde' },
    { id: 'amount', numeric: true, disablePadding: false, label: 'Resterende baller' },  
  ];
  

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell />
          {headCells.map((headCell) => (
            <TableCell  className={classes.tableCell}
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }


const cleanFields = (fields, customers) =>{

   
    var data = fields.map((field) => {      
        const status = field?.status
        const owner = customers.find((customer) => customer.id === field?.ownerID)?.name
        // const area = parseFloat(field?.geometry?.properties?.IMK_areal);
        let areal = 0.0
        try {
          areal = (area(field?.geometry)/10000.0).toFixed(1)
        } catch(err) {
          console.log(err)
          console.log(field)
        }
       
        const amount = field?.currentAmountOfBales
        const id = field?.id
        const crop = field?.geometry?.properties?.Afgroede
        return ({status,owner,areal,amount,crop,id,})
    })
    return data
}


export default function FieldTable() {

 const fields = useSelector(selectFields)
 const customers = useSelector(selectCustomers)
 const cleanedFields = cleanFields(fields,customers)
const [order, setOrder] = React.useState('asc');
const [orderBy, setOrderBy] = React.useState('status');
const classes = useRowStyles();
 

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" >
      <EnhancedTableHead
              classes={classes}          
              order={order}
              orderBy={orderBy}              
              onRequestSort={handleRequestSort}          
            />    
        <TableBody>
        {stableSort(cleanedFields, getComparator(order, orderBy))                
                .map((field, index) => {          
              return (<Row key={field.id} field={field} />)
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
