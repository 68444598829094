import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { connect } from "react-redux";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { LinearProgress, Grid } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import SkeletonForm from "../../components/SkeletonForm";
import { formPageStyles } from "../../styles";
import SLUGS from "../../navigation/slugs"
import { Map } from "../../services/maps"
import { withAuth0 } from "@auth0/auth0-react";
import { resetStatus, createCustomer, selectCustomerById, updateCustomer, setCustomerCVR, clearCustomerFields } from '../../redux/slicers/customerSlice'
import CustomerMapUtils from "./CustomerMapUtils"
import { MapContext } from 'react-mapbox-gl'
import { clearFieldSelection, selectedFieldsIds } from "../../redux/slicers/mapSlice";
import { PinDropSharp } from "@material-ui/icons";
import { Customer } from "../../models/customer";
import { Field as MapField, FieldStatus } from "../../models/organisationField";
import { createOwnFields } from "../../redux/slicers/fieldSlice";
import _ from "lodash";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
const styles = formPageStyles;


export function validateCVR(cvr) {
  return /^[0-9]{8}$/i.test(cvr)
}


export function validateCustomer(values) {
  const errors = {};

  // //Update current cvr number for the map to fetch fields
  // this.props.setCustomerCVR(values.cvr)

  if (!values.name) {
    errors.name = "Påkrævet";
  }
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Ugyldig email addresse";
  }
  if (values.cvr && !validateCVR(values.cvr)) {
    errors.cvr = "Ugyldigt CVR nummer";
  }
  if (values.postalCode && !/^[0-9]{4}$/i.test(values.postalCode)) {
    errors.postalCode = "Ugyldigt Postnummer nummer";
  }
  if (values.phoneNo && !/^((\d{8})|(\+\d{10})|(00\d{10}))$/i.test(values.phoneNo)) {
    errors.phoneNo = "Ugyldigt telefon nummer";
  }
  return errors;
}


export const CustomerFormContent = ({ validateCVR, disableCVR }) => {

  var disableCVRField = false
  if (disableCVR) {
    disableCVRField = disableCVR
  }

  return (
    <Form>
      <Grid container style={styles.containerGrid} spacing={3} direction="column">
        <Grid item style={styles.cell} xs={12}>
          <Field
            variant="outlined"
            component={TextField}
            placeholder="Navn"
            label="Navn"
            name="name"
            fullWidth={true}
            required
          />
        </Grid>
        <Grid item style={styles.cell} xs={12} >
          <Field
            variant="outlined"
            component={TextField}
            placeholder="CVR"
            label="CVR"
            fullWidth={true}
            type="number"
            validate={(values) => { validateCVR(values) }}
            name="cvr"
            disabled={disableCVRField}
          />
        </Grid>
        <Grid item style={styles.cell} xs={12} >
          <Field
            variant="outlined"
            component={TextField}
            placeholder="Vej"
            label="Vej"
            fullWidth={true}
            name="streetname"
          />
        </Grid>
        <Grid item style={styles.cell} xs={12} >
          <Field
            variant="outlined"
            component={TextField}
            placeholder="Nr."
            label="Nr."
            fullWidth={true}
            type="number"
            name="houseNumber"
          />
        </Grid>
        <Grid item style={styles.cell} xs={12} >
          <Field
            variant="outlined"
            component={TextField}
            placeholder="Post Nr."
            label="Post Nr."
            fullWidth={true}
            type="number"
            name="postalCode"
          />
        </Grid>

        <Grid item style={styles.cell} xs={12} >
          <Field
            variant="outlined"
            component={TextField}
            placeholder="By"
            label="By"
            fullWidth={true}
            name="city"
          />
        </Grid>

        <Grid item style={styles.cell} xs={12} >
          <Field
            variant="outlined"
            component={TextField}
            placeholder="Email"
            label="Email"
            fullWidth={true}
            name="email"
          />
        </Grid>
        <Grid item style={styles.cell} xs={12} >
          <Field
            variant="outlined"
            component={TextField}
            placeholder="12345678"
            label="Mobile"
            fullWidth={true}
            type="string"
            name="phoneNo"
          />
        </Grid>
      </Grid>
    </Form>)
}


// class CustomerFormPage extends React.Component {
class CustomerForm extends React.Component {
  constructor(props) {
    super(props);
    this.onSnackBarClose = this.onSnackBarClose.bind(this);
    this.handleAddCustomerFieldsToOwn = this.handleAddCustomerFieldsToOwn.bind(this)
    this.validateCVR = this.validateCVR.bind(this)
    this.onSave = this.onSave.bind(this)
    this.formRef = {}
    this.handleSelect = this.handleSelect.bind(this)
  }

  state = {
    customer: {},
    snackbarOpen: false,
    autoHideDuration: 5000,
  };

  async componentDidMount() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    this.setState({ token: token });
    this.setState({ customer: this.props.customer })
  }
  componentDidUpdate(prevProps) {
  }

  validateCVR(values) {
    const cvr = { cvr: values }
    const error = validateCustomer(cvr)
    if (error.cvr === undefined) {
      this.props.setCustomerCVR(values)
    }
  }

  handleAddCustomerFieldsToOwn() {

    var newFields = []
    this.props.selectedCustomerFieldsId.forEach((selFieldId) => {

      // Find the customer field in the redux state
      var customerField = JSON.parse(JSON.stringify(this.props.customerFields.filter((field) => field.id === selFieldId)[0]))

      customerField.properties.id = customerField.properties.Marknr + customerField.properties.Journalnr;
      delete customerField['id'];
      var newField = new MapField()
      newField.geometry = customerField;
      newField.status = FieldStatus.NO_INFO
      newField.ownerID = this.state.customer.id
      newFields.push(newField)
    })
    this.props.createOwnFields({ fields: newFields, token: this.state.token })
    this.props.clearFieldSelection();
  }

  onSave(values) {
    if ('id' in this.state.customer) {
      const customer = { ...values }
      this.props.updateCustomer({ customer: customer, token: this.state.token })
      this.setState({ customer: customer })
    } else {
      var customer = new Customer()
      customer = { ...customer, ...values, organisationId: this.props.auth0.user['http://smartbaling.com/metadata'].organisation }
      this.props.createCustomer({ customer: [customer], token: this.state.token })
    }

    this.handleAddCustomerFieldsToOwn()
  }

  onSnackBarClose(event, reason) {
    if (reason !== 'clickaway') {
      this.setState({ snackbarOpen: false });
      this.props.resetStatus()
    }
  }

  handleSelect(event, newVal) {
    console.log(newVal)

    if (newVal === "selecting") {
      this.props.fieldToggle(true)
    } else {
      this.props.fieldToggle(false)
    }
  }
  render() {
    const { status } = this.props;
    const { customer = {} } = this.state;
    return (
      <div style={styles.container}>
        <div style={styles.containerForm}>
          {status === 'loading' ? (
            <div>
              <SkeletonForm />
            </div>
          ) : (
            <div>
              {status === 'loading' && <LinearProgress />}
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      ...customer
                    }}
                    validate={validateCustomer}
                    onSubmit={(values, { setSubmitting }) => {
                      this.onSave(values);
                      setTimeout(() => {
                        setSubmitting(false);
                      }, 500);
                    }}
                  >
                    {({ submitForm, isSubmitting, values }) => (
                      <>
                        <div style={styles.buttons}>
                          <Link to={SLUGS.customerHandling}>
                            <Button variant="contained">
                              <ArrowBackIosIcon /> Back{" "}
                            </Button>
                          </Link>
                          <Button
                            variant="contained"
                            style={styles.saveButton}
                            onClick={submitForm}
                            color="primary"
                            disabled={isSubmitting}
                          >
                            <SaveIcon /> Save
                          </Button>
                        </div>
                        <CustomerFormContent validateCVR={this.validateCVR} />
                        <br />
                        <Snackbar
                          open={this.state.snackbarOpen}
                          autoHideDuration={this.state.autoHideDuration}
                          onClose={this.onSnackBarClose}
                          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >
                          <Alert onClose={this.onSnackBarClose} severity={this.props.status}>
                            {this.props.statusMsg}
                          </Alert>
                        </Snackbar>
                      </>
                    )}
                  </Formik>
                </Grid>
                <Grid item>
                  <ToggleButtonGroup value={this.props.isSelectingFields === true ? "selecting" : "" } onChange={this.handleSelect} exclusive>
                    <ToggleButton value="selecting">
                      Vælg marker
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>

            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectCustomerById: (id) => selectCustomerById(state, id),
  selectedCustomerFieldsId: selectedFieldsIds(state),
  customerFields: state.customers.customerFields,
})

function mapDispatchToProps(dispatch) {
  return {
    createCustomer: (arg) => dispatch(createCustomer(arg)),
    updateCustomer: (arg) => dispatch(updateCustomer(arg)),
    resetStatus: () => dispatch(resetStatus()),
    setCustomerCVR: (cvr) => dispatch(setCustomerCVR(cvr)),
    clearFieldSelection: () => dispatch(clearFieldSelection()),
    clearCustomerFields: () => dispatch(clearCustomerFields()),
    createOwnFields: (arg) => dispatch(createOwnFields(arg)),
  };
}

export default withAuth0(connect(mapStateToProps, mapDispatchToProps)(CustomerForm));
