import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Grid, IconButton, Paper, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectLayerVisible, setLayerVisible } from '../../redux/slicers/dashboardSlice';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import LayersIcon from '@material-ui/icons/Layers';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  btnLayer:{
    position: 'absolute',
    zIndex: '10',
    marginBottom: 80,
    marginLeft: 5,
    bottom: 0,
    left: 0,
},    
root:{
    margin: "5px"
}
}));

export default function DashboardLayerSelect() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const layersVisible = useSelector(selectLayerVisible)
  const dispatch = useDispatch()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    const visibility = {layer: event.target.name, value: event.target.checked}
    dispatch(setLayerVisible(visibility))              
    };   

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className ={classes.btnLayer}>
       <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        <LayersIcon/>
      </Button> 
      {/* <IconButton onClick={handleClick}>
          <LayersIcon/>
      </IconButton> */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
      >
        <Paper className={classes.root}>
            <Grid container direction="column">
                <Grid item>
                <FormControl component="fieldset">
                        <FormLabel component="legend">Lag</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                    control={<Switch checked={layersVisible.stackLayer} onChange={handleChange} name="stackLayer" />}
                                    label="Stakke"
                            />                         
                        </FormGroup>
                    </FormControl>                
                </Grid>
                <Grid item>
                <FormControl component="fieldset">                        
                        <FormGroup>
                            <FormControlLabel
                                    control={<Switch checked={layersVisible.fieldsLayer} onChange={handleChange} name="fieldsLayer" />}
                                    label="Marker"
                            />                         
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item>
                <FormControl component="fieldset">                           
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={layersVisible.balesLayer} onChange={handleChange} name="balesLayer" />}
                                    label="Baller"
                                />                         
                            </FormGroup>
                        </FormControl>
                </Grid>
            </Grid>
        </Paper>
      </Popover>
    </div>
  );
}