import { createSlice,createAsyncThunk, createEntityAdapter  } from '@reduxjs/toolkit'
import _ from "lodash";
import { createSelector } from 'reselect'
const stacksAdapter = createEntityAdapter()

const initialState = stacksAdapter.getInitialState({
    status: 'idle',
    statusMsg: "",
    editStack : {},
    editStackIsNewStack: false,
    lastEditAmount: 24
  })

  export const createStack = createAsyncThunk('stacks/createStack', async (arg,{dispatch}) => {

    const serverUrl = process.env.REACT_APP_SERVER_URL;  
    const response = await fetch(
      `${serverUrl}/api/CreateStack`,
      {
        method: 'POST',      
        body: JSON.stringify(arg.stack),
        headers: {
            Authorization: `Bearer ${arg.token}`,
          }        
        }
    );   
    const data = await response.json()
    if(response.ok){
      await dispatch(fetchAllStacks({token:arg.token}))
      return data
    }
})

export const updateStack = createAsyncThunk('stacks/updateStack', async (arg,{dispatch}) => {

  const serverUrl = process.env.REACT_APP_SERVER_URL;  
  const response = await fetch(
    `${serverUrl}/api/UpdateStack`,
    {
      method: 'POST',      
      body: JSON.stringify(arg.stack),
      headers: {
          Authorization: `Bearer ${arg.token}`,
        }        
      }
  );   
  const data = await response.json()
  if(response.ok){
    await dispatch(fetchAllStacks({token:arg.token}))
    return data
  }
})


export const fetchAllStacks = createAsyncThunk('stacks/fetchAllStacks', async (arg,{rejectWithValue}) => {

    const serverUrl = process.env.REACT_APP_SERVER_URL;    
    const response = await fetch(
      `${serverUrl}/api/ListStacks`,
        {
        headers: {
            Authorization: `Bearer ${arg.token}`,
          }        
        }
    );   

    const data = await response.json()
    if (response.ok) {     
      return data
    }
})

export const deleteStack = createAsyncThunk('stacks/deleteStack', async (arg,{rejectWithValue,dispatch}) => {

  const serverUrl = process.env.REACT_APP_SERVER_URL;  
  const response = await fetch(
    `${serverUrl}/api/DeleteStack`,
      {
      method: 'DELETE',
      headers: {
          Authorization: `Bearer ${arg.token}`,
        },
        body: JSON.stringify(arg.stack)     
      }
  );   

  const data = await response.json()
  if (response.ok) {
    await dispatch(fetchAllStacks({token:arg.token}))   
    return data
  } else {
  return rejectWithValue(response)
  }
})

const stacksSlice = createSlice({
  name: 'stacks',
  initialState,
  reducers: {  
    resetStatus(state,action){
        state.status = 'idle'
        state.statusMsg = ""
    },
    setLastEditAmount(state,action){
      state.lastEditAmount = action.payload;
    }
  },  
  extraReducers: builder => {
    builder 
      .addCase(fetchAllStacks.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchAllStacks.fulfilled, (state, action) => {
            const newEntities = {}
            const id = []
            action.payload.forEach(stack => {
                newEntities[stack.id] = stack
                id.push(stack.id)
            })
            state.ids = id
            state.entities = newEntities
            state.status = 'idle'
        state.statusMsg = "Succesfully fetched all stacks"
      })
      .addCase(fetchAllStacks.rejected, (state, action) => {
        state.status = 'error'
        state.statusMsg = "Could not fetch all stacks" 
      }).addCase(deleteStack.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(deleteStack.fulfilled, (state, action) => {
        state.status = 'success'
        state.statusMsg = "Succesfully deleted stack"
      })
      .addCase(deleteStack.rejected, (state, action) => {
        state.status = 'error'
        state.statusMsg = action.payload 
      })
      .addCase(createStack.fulfilled, (state, action) => {    
        state.status = 'success'
        state.statusMsg = "Succesfully created a stack"
      })
      .addCase(createStack.rejected, (state, action) => {
        state.status = 'error'
        state.statusMsg = action.payload 
      })
      .addCase(updateStack.fulfilled, (state, action) => { 
        state.status = 'success'
        state.statusMsg = "Succesfully updated stack"
      })
      .addCase(updateStack.rejected, (state, action) => {
        state.status = 'error'
        state.statusMsg = action.payload 
      }) 
  }
})

export const {resetStatus,setLastEditAmount} = stacksSlice.actions

export default stacksSlice.reducer

export const {
    selectAll: selectStacks,
    selectById: selectStacksById
  } = stacksAdapter.getSelectors(state => state.stacks)
  
export const selectLastEditAmount = state => state.stacks.lastEditAmount

