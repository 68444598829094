import React from  'react';
import {Route, Switch } from "react-router-dom";

import DashboardContainer from "../pages/Dashboard/DashboardContainer"
import { ProtectedRoute } from "./components";
import { ProfileContainer } from "../pages/Profile";
import SLUGS from './slugs';
import HomeContainer from "../pages/Home/HomeContainer";
import CustomerFormPage from "../pages/Customer/CustomerFormPage"
import CustomerContainer from "../pages/Customer/CustomerContainer"
import UserContainer from "../pages/Users/UserContainer"
import UserFormPage from "../pages/Users/UserFormPage"

export const RouterConfig = () =>{
    return(
        <Switch>            
            <ProtectedRoute exact path = {SLUGS.dashboard} component={DashboardContainer}/>    
            <ProtectedRoute exact path = {SLUGS.profileInfo} component={ProfileContainer}/>  
            <ProtectedRoute exact path = {SLUGS.users} component={UserContainer}/>
            <ProtectedRoute exact path = {SLUGS.newUser} component={UserFormPage}/>   
            <ProtectedRoute path = {SLUGS.editUser} component = {UserFormPage}/>            
            <ProtectedRoute exact path = {SLUGS.customerHandling} component={CustomerContainer}/>
            <ProtectedRoute exact path = {SLUGS.newCustomer} component={CustomerFormPage}/>   
            <ProtectedRoute path = {SLUGS.editCustomer} component = {CustomerFormPage}/>             
            <Route exact path = {SLUGS.root} component= {HomeContainer}/>
        </Switch>
    );
};

export default RouterConfig;