import { createSlice,createAsyncThunk, createEntityAdapter, createSelector} from '@reduxjs/toolkit'
import _ from 'lodash'
import { createDispatchHook } from 'react-redux'


const fieldAdapter = createEntityAdapter()

const initialState = fieldAdapter.getInitialState({
    status: 'idle',
    statusMsg: "",   
  })

export const deleteField = createAsyncThunk('fields/deleteField', async (arg,{dispatch}) =>{
  const serverUrl = process.env.REACT_APP_SERVER_URL
  console.log("Delete field at " + serverUrl) 
  console.log(JSON.stringify(arg.field))   
  const response = await fetch(
    `${serverUrl}/api/deleteField`,
      {
      method: 'DELETE',      
      body: JSON.stringify(arg.field),
      headers: {
          Authorization: `Bearer ${arg.token}`,
        }        
      }
  );   

  const data = await response.json()
  if (response.ok) {  
    await dispatch(fetchOwnFields({token:arg.token}))   
    return data
  }
})

export const fetchOwnFields = createAsyncThunk('fields/fetchOwnFields', async (arg,{rejectWithValue}) => {

    const serverUrl = process.env.REACT_APP_SERVER_URL;  
    console.log("Fetching own Fields at at " + serverUrl) 
    console.log(arg)   
    const response = await fetch(
      `${serverUrl}/api/ListField`,
        {
        headers: {
            Authorization: `Bearer ${arg.token}`,
          }        
        }
    );   

    const data = await response.json()
    if (response.ok) {     
      return data
    }
})

export const createOwnFields = createAsyncThunk('fields/createOwnFields', async (arg,{dispatch}) => {

  const serverUrl = process.env.REACT_APP_SERVER_URL;  
  console.log("Creating own Fields at at " + serverUrl) 
  console.log(JSON.stringify(arg.fields))   
  const response = await fetch(
    `${serverUrl}/api/CreateField`,
      {
      method: 'POST',      
      body: JSON.stringify(arg.fields),
      headers: {
          Authorization: `Bearer ${arg.token}`,
        }        
      }
  );   

  const data = await response.json()
  if (response.ok) {  
    await dispatch(fetchOwnFields({token:arg.token}))   
    return data
  }
})

export const updateOwnFields = createAsyncThunk('fields/updateOwnFields', async (arg,{dispatch}) => {

  const serverUrl = process.env.REACT_APP_SERVER_URL;  
  console.log("Updating own Fields at at " + serverUrl) 
  console.log(JSON.stringify(arg))   
  const response = await fetch(
    `${serverUrl}/api/UpdateField`,
      {
      method: 'POST',      
      body: JSON.stringify(arg.field),
      headers: {
          Authorization: `Bearer ${arg.token}`,
        }        
      }
  );   

  const data = await response.json()
  if (response.ok) {  
    await dispatch(fetchOwnFields({token:arg.token}))   
    return data
  }
})

const fieldSlice = createSlice({
  name: 'fields',
  initialState,
  reducers: {  
    resetStatus(state,action){
        state.status = 'idle'
        state.statusMsg = ""
    }
  },
  extraReducers: builder => {
    builder 
      .addCase(fetchOwnFields.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchOwnFields.fulfilled, (state, action) => {
            const newEntities = {}
            const id = []
            action.payload.forEach(field => {
                newEntities[field.id] = field
                id.push(field.id)
            })
            state.ids = id
            state.entities = newEntities
            state.status = 'idle'
        state.statusMsg = "Succesfully fetched own fields"
      })
      .addCase(fetchOwnFields.rejected, (state, action) => {     
        state.status = 'error'
        state.statusMsg = "Could not fetch own fields" 
      })
      .addCase(createOwnFields.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(createOwnFields.fulfilled, (state, action) => {
        state.status = 'success'
        state.statusMsg = "Succesfully added to own fields"
      })
      .addCase(createOwnFields.rejected, (state, action) => {
        console.log(action)
        state.status = 'error'
        state.statusMsg = "Could not add to own fields" 
      })
      .addCase(deleteField.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(deleteField.fulfilled, (state, action) => {
        state.status = 'success'
        state.statusMsg = "Succesfully deleted field"
      })
      .addCase(deleteField.rejected, (state, action) => {
        console.log(action)
        state.status = 'error'
        state.statusMsg = "Could not delete field" 
      })
      .addCase(updateOwnFields.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(updateOwnFields.fulfilled, (state, action) => {
        state.status = 'success'
        state.statusMsg = "Succesfully updated to own fields"
      })
      .addCase(updateOwnFields.rejected, (state, action) => {
        console.log(action)
        state.status = 'error'
        state.statusMsg = "Could not update to own fields" 
      })
  }
})

export const {resetStatus, setLastEditAmountField} = fieldSlice.actions

export default fieldSlice.reducer

export const {
    selectAll: selectFields,
    selectById: selectFieldsById
  } = fieldAdapter.getSelectors(state => state.fields)

 