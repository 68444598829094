import React, { Component } from 'react'
import { connect } from 'react-redux'
// import DrawControl from 'react-mapbox-gl-draw';

import MapboxDraw from "@mapbox/mapbox-gl-draw";


import { selectEditFeature, selectDraw, setEditFeature, setDraw } from '../../redux/slicers/mapSlice';
import _ from 'lodash';
import { MapboxContext } from './MapComponent';
import { ControlPointSharp } from '@material-ui/icons';
import  DrawControl from "../../pages/Dashboard/DrawControl"

function noop() {
    /* do nothing */
}


class EditFeature extends Component {

    static contextType = MapboxContext;


    constructor(props) {
        super(props)

        this.state = {
            drawPolygon: true,
            // controls: {polygon: true,
            //             point: true,
            //             line_string: true,                    
            //             trash: true,
            //             combine_features: true,
            //             uncombine_features: true,
            //             },
            controlsAdded: false,

        }

        this.onDrawCreate = this.onDrawCreate.bind(this)
        this.onDrawDelete = this.onDrawDelete.bind(this)
        this.onDrawUpdate = this.onDrawUpdate.bind(this)
        this.addcontrol = this.addcontrol.bind(this)
        this.removeControl = this.removeControl.bind(this)
    }

    componentDidMount() {
        const map = this.context 
        console.log(map)
        // The map needs to be passed in the React Context, or welse we can't do
        // anything.
        if (!map || !map.getStyle()) {
            throw new Error('Map is undefined in React context.');
        }
        //const { modes, onDrawActionable, onDrawCombine, onDrawCreate, onDrawDelete, onDrawModeChange, onDrawRender, onDrawSelectionChange, onDrawUncombine, onDrawUpdate, position } = this.props;
        

        // Define a new Draw Control
        // this.draw = new mapbox_gl_draw_1.default(Object.assign(Object.assign({}, this.props), { modes: Object.assign(Object.assign({}, mapbox_gl_draw_1.default.modes), modes) }));
       
    }
    addcontrol({controls}){
        console.log("Adding controls")
        const map = this.context 
        const {position } = this.props;

        if(this.state.controlsAdded === true){
            console.log("Removing controls in add")
            map.removeControl(this.draw);
        }

        this.draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: controls           
        }) 

        // Add it to our map
        map.addControl(this.draw, position);
        // Hook draw events
        map.on('draw.actionable', this.props.onDrawActionable || noop);
        map.on('draw.combine', this.props.onDrawCombine || noop);
        map.on('draw.create', this.onDrawCreate || noop);
        map.on('draw.delete', this.onDrawDelete || noop);
        map.on('draw.modechange', this.props.onDrawModeChange || noop);
        map.on('draw.render', this.props.onDrawRender || noop);
        map.on('draw.selectionchange', this.props.onDrawSelectionChange || noop);
        map.on('draw.uncombine', this.props.onDrawUncombine || noop);
        map.on('draw.update', this.onDrawUpdate || noop);

        this.setState({...this.state, controlsAdded: true})
    }

    removeControl(){

        console.log("Removing controls")
        const map = this.context 
        if(this.state.controlsAdded === true){
            map.removeControl(this.draw);
        }
        this.draw = null
        this.setState({...this.state, controlsAdded: false})
    }   

    componentDidUpdate(prevProps) {
        const { editFeature } = this.props

        if((prevProps.draw.enableLayer !== this.props.draw.enableLayer) || prevProps.draw.enableEdit !== this.props.draw.enableEdit){
            
            if(this.props.draw.enableLayer === false){
                    this.removeControl()
                    this.props.setEditFeature({})
                    this.props.setDraw({...this.props.draw, enableLayer: false,
                        enableEdit: false})
                    return
            } else {
                if(this.props.draw.enableEdit === true){
                    this.addcontrol({controls:  this.props.draw.controls})
                 } else {
                     this.addcontrol({controls: this.props.draw.controls})
                 }
            }
        }
       

        if (this.draw === undefined || this.draw === null) {
            console.log("Draw is undefined")
            return
        }
        if (!_.isEmpty(this.props.editFeature)) {
            console.log("Adding feature")
            this.draw.add(editFeature)
        } else {
            console.log("Delete all")
            this.draw.deleteAll()
        }
    }

    onDrawCreate({ features }) {
        this.draw.deleteAll()
        this.draw.add(features[0])
        this.props.setEditFeature(features[0])
    }

    onDrawDelete({ features }) {
        this.props.setEditFeature({})
    }

    onDrawUpdate({ features }) {
        this.props.setEditFeature(features[0])
    }

    componentWillUnmount() {
        this.props.setEditFeature({})
        this.props.setDraw({enableLayer: false,
            enableEdit: false})


        const map = this.context;
        if (!map || !map.getStyle()) {
            return;
        }
        if (!this.draw) {
            return;
        }
        map.removeControl(this.draw);
    }

    render() {

        console.log("Draw rendered")       
        return (
            <>
            {this.props.draw.enableEdit === true && <DrawControl/>}
            </>
        )
        
    }
}

const mapStateToProps = state => (
    {
        editFeature: selectEditFeature(state),
        draw: selectDraw(state)
    })

function mapDispatchToProps(dispatch) {
    return {
        setEditFeature: (feature) => dispatch(setEditFeature(feature)),
        setDraw: (arg) => dispatch(setDraw(arg))
    };
}


export default (connect(mapStateToProps, mapDispatchToProps)(EditFeature))
EditFeature.defaultProps = {
    position: 'top-left'
};
