import React from 'react'
import { useSelector } from 'react-redux'

export const ShowWhen = ({children, persmissions}) => {
    const {selectorPermissions, loading, error } = useSelector((state) => state.selectorPermissions)

    console.log(selectorPermissions)

    if(persmissions.some((elem) => selectorPermissions.includes(elem))){        
        return <>{children}</>
    }else{
        return null
    }
}