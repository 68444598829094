import React from 'react';
import mapboxgl from 'mapbox-gl';
import ReactMapboxGl from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import { withStyles } from '@material-ui/styles'
import ReactDOM from 'react-dom'
import { connect } from "react-redux";
import { selectDraw, selectStyle, setStyle } from '../../redux/slicers/mapSlice'



// mapboxgl.accessToken = process.env.REACT_APP_MAPS_TOKEN;


const MapComponent = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPS_TOKEN
});


const styles = theme => ({
  container: {
    margin: '0 0',
    border: '0px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  mapStyle: {
    flex: 12
  },
});


export const MapboxContext = React.createContext()

class Map extends React.Component {

  constructor(props) {
    super(props);
    this.onStyleLoad = this.onStyleLoad.bind(this)
    this.controlGeoCoding = this.controlGeoCoding.bind(this)
    this.state = {
      map: null,
      geocodingAdded: false
    }
  }

  componentDidMount() {
    console.log("Map mounted")
  }

  componentDidUpdate(prevProps) {

    if (prevProps.draw.enableLayer !== this.props.draw.enableLayer) {
      this.controlGeoCoding()
    }
  }

  controlGeoCoding() {
    if (this.state.map === null) return
    if (this.props.draw.enableLayer === false) {
      if (this.state.geocodingAdded === false) {
        this.geocoding = new MapboxGeocoder({
          accessToken: process.env.REACT_APP_MAPS_TOKEN,
          mapboxgl: mapboxgl,       
        });
        this.state.map.addControl(this.geocoding, 'top-left')
        this.setState({ ...this.state, geocodingAdded: true })
      }
    } else {
      if (this.state.geocodingAdded === true) {
        this.state.map.removeControl(this.geocoding)
        this.setState({ ...this.state, geocodingAdded: false })
      }
    }
  }

  onStyleLoad(mapbox) {
    console.log("Style loaded")
    this.setState({ map: mapbox })

    mapbox.addControl(new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true
    }), "bottom-left");

    this.controlGeoCoding()

  }

  render() {
    const { classes, children } = this.props
    return (
      <MapComponent
        style='mapbox://styles/mapbox/satellite-streets-v11'
        className={classes.container}
        zoom={this.props.style.zoom}
        center={this.props.style.center}
        // fitBounds={this.props.style.fitBounds}
        // movingMethod='flyTo'
        // flyToOptions={{speed: 0.1}}
        onStyleLoad={this.onStyleLoad}
      >
        <MapboxContext.Provider value={this.state.map}>
          {this.state.map !== null && children}
        </MapboxContext.Provider>
      </MapComponent>
    );
  };
}

const mapStateToProps = state => (
  {
    style: selectStyle(state),
    draw: selectDraw(state)
  })

function mapDispatchToProps(dispatch) {
  return {
    setStyle: (style) => dispatch(setStyle(style))
  };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Map))
// export default (Map)