import { OrganisationBase } from "./organisationBase";

export enum JobStatus {
    NEW="NEW",
    CREATED = "CREATED",
    READY ="READY",
    IN_PROGRESS = "IN_PROGRESS",
    FINISHED = "FINISHED",
}

export enum JobType {
    TRANSPORT = "TRANSPORT",
    BALING = "BALING"
}

export class Job extends OrganisationBase {
    status: JobStatus = JobStatus.CREATED;
    note: string;
    type: JobType;
}

