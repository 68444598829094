import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { blue, common, green, grey } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import { center } from '@turf/turf';
import _ from "lodash";
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StackStatus } from "../../models/stack";
import { clearNewItemState, clearSelection, selectNewItemState, setNewItemState } from '../../redux/slicers/dashboardSlice';
import { clearAllSelections, selectEditFeature, setEditFeature, setDraw, setSelectablility, setClosePopUp } from '../../redux/slicers/mapSlice';
import { createStack, editingStack, editStackIsNewStack, finishedEditing, setEditStack, updateStack } from '../../redux/slicers/stackSlice';
import { MapboxContext } from "../../services/maps/MapComponent";
import { getTextFromStatus } from './StackTile';
import GestureIcon from '@material-ui/icons/Gesture';

//layers

const grey500 = grey["500"];
const green400 = green["400"];
const white = common.white;

const useStyles = makeStyles((theme) => ({
  root: {
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatarTransport: {
    backgroundColor: blue[500],
  },
  avatarBale: {
    backgroundColor: green[500],
  },
  editButtonIcon: {
    fill: white,
  },
  editButton: {
    marginRight: "1em",
    color: white,
    backgroundColor: green400,
  },
  deleteButton: {
    color: "grey",
    fill: grey500,
  },
  doneButtonIcon: {
    fill: white,
  },
  doneButton: {
    marginRight: "1em",
    color: white,
    backgroundColor: green400,
  },
  cancelButton: {
    color: "grey",
    fill: grey500,
  },
  selected: {  
    backgroundColor: "green",
  }  
}));


export function NewStackContent({editStack ,onStackChange}) {
  
  const dispatch = useDispatch()  
  // const map = useContext(MapboxContext)  
  const editFeature = useSelector(selectEditFeature)
  const classes = useStyles()
 
  useEffect(() => {
    
      if(!_.isEmpty(editStack.location) && _.isEmpty(editFeature)){
          dispatch(setEditFeature(editStack.location))
      }
      return () => {
       
      }
  },[editStack,dispatch])

  const handleStatusChange = (event) => {
    if (event.target.name === 'status') {
      onStackChange({ ...editStack, status: event.target.value })
      // dispatch(setEditStack({ ...editStack, status: event.target.value }))
    }
  }

  const handleNotesChanged = (event) => {
    onStackChange({ ...editStack, note: event.target.value })
    // dispatch(setEditStack({ ...editStack, note: event.target.value }))
  }

  const handleNameChanged = (event) => {
    onStackChange({ ...editStack, name: event.target.value })
    // dispatch(setEditStack({ ...editStack, note: event.target.value }))
  }


  const handleEditStack = () => {
    
    dispatch(setDraw({enableLayer: true,
      enableEdit: true,
      controls: {  polygon: true,
        point: true,
        line_string: false,                    
        trash: true,
        combine_features: false,
        uncombine_features: false,
        }}) ) 
    dispatch(setEditFeature(editStack.location))
    dispatch(setClosePopUp())
  }
  // function goToLocation(){    
  //   if(!_.isEmpty(mapEditFeature)){
  //       const coord = center(mapEditFeature).geometry.coordinates
  //       console.log(coord)
  //       map?.flyTo({center: coord, zoom: 16, essential: true})
  //   }    
  // }  

  return (
    <Grid item container direction="column" spacing={3}>
      <Grid item>
         <FormControl className={classes.formControl}>
              <InputLabel htmlFor="status-native-helper">Status</InputLabel>
              <Select
                value={editStack?.status}
                onChange={handleStatusChange}
                inputProps={{
                  name: 'status',
                  id: 'status-native-helper',
                }}              >
                <MenuItem value={StackStatus.OPEN}> {getTextFromStatus(StackStatus.OPEN)}</MenuItem>
                <MenuItem value={StackStatus.CLOSED}> {getTextFromStatus(StackStatus.CLOSED)}</MenuItem>
                <MenuItem value={StackStatus.FINISEHED}> {getTextFromStatus(StackStatus.FINISEHED)}</MenuItem>            
              </Select>
            </FormControl>
      </Grid>      
      {/* <Grid container item direction="row" spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <TextField fullWidth value={_.isEmpty(mapEditFeature) ? "Ingen lokation " : "Lat/Lon : " + center(mapEditFeature).geometry.coordinates} label="Location" variant="outlined" InputProps={{ readOnly: true }} onClick= {goToLocation} />
        </Grid>
      </Grid> */}
      <Grid item>
      <TextField
            fullWidth
            id="outlined-multiline-static"
            defaultValue={editStack?.name}
            onBlur={handleNameChanged}
            label="Navn"
            multiline
            variant="outlined"
          />
      </Grid>
      <Grid item >
           <TextField label="Ønsket antal" variant="outlined" type="number" InputLabelProps={{
            shrink: true,
          }} 
          defaultValue={editStack?.requestedAmount}
          onBlur={(event) => {onStackChange({ ...editStack, requestedAmount : event.target.value })}}/>          
      </Grid>
      <Grid item >
            <TextField label="Nuværende antal" variant="outlined" type="number" InputLabelProps={{
                shrink: true,
             }} 
             defaultValue={editStack?.currentAmount}
             onBlur={(event) => {onStackChange({ ...editStack, currentAmount : event.target.value })}}  
             />    
      </Grid>
      <Grid item>
      <TextField
            fullWidth
            id="outlined-multiline-static"
            defaultValue={editStack?.note}
            onBlur={handleNotesChanged}
            label="Noter"
            multiline
            variant="outlined"
          />
      </Grid>
      <Grid item>
      <Button
            variant="contained"
            color="default"
            // className={classes.button}
            startIcon={<GestureIcon />}
            onClick={handleEditStack}
          >
            Tegn
          </Button>


      </Grid>

    </Grid>
  )
}

export function EditStackTile() {
  const mapEditFeature = useSelector(selectEditFeature)
  const editItem = useSelector(selectNewItemState)  
  const classes = useStyles();
  const dispatch = useDispatch()
  const { getAccessTokenSilently,user } = useAuth0();    
  
  const onDone = async (e) => {

    const token = await getAccessTokenSilently();
    var finishedStack = {}
    var feature = JSON.parse(JSON.stringify(mapEditFeature));
    delete feature.id
    finishedStack =  { ...editItem.stack, location: feature, organisationId: user['http://smartbaling.com/metadata'].organisation}

    console.log(finishedStack)
    console.log(editItem)
    if(editItem.isNew === true){
      dispatch(createStack({stack: [finishedStack], token: token}))
    } else {
      dispatch(updateStack({stack: finishedStack, token: token}))
    }
    dispatch(setSelectablility({fieldsLayer: false, balesLayer: false, stackLayer: false}))
    dispatch(setEditFeature({}))
    dispatch(setDraw({enableLayer: false,
      enableEdit: false}))
    dispatch(clearNewItemState())
  }

  const onCancel = (e) => {
     dispatch(setSelectablility({fieldsLayer: false, balesLayer: false, stackLayer: false}))
     dispatch(setEditFeature({}))
     dispatch(setDraw({enableLayer: false,
      enableEdit: false}))
     dispatch(clearNewItemState())
  }

  const onStackChange = (item) => {  
    dispatch(setNewItemState({...editItem, stack: item}))
  }

  return (
    <Grid item contatiner direction="column" spacing={4}>        
          <NewStackContent mapEditFeature = {mapEditFeature} editStack={editItem.stack} onStackChange ={onStackChange}/>         
          <Grid item container direction = "row" spacing={2}>
            <Grid item>
            <Tooltip title="Accepter" aria-label="edit">
              <Fab
                size="small"
                className={classes.doneButton}
                onClick={(e) => onDone(e)}
              >
                <DoneIcon />
              </Fab>
            </Tooltip>
            </Grid>
            <Grid item >
              <Tooltip title="Fortryd" aria-label="delete">
                <Fab
                  size="small"
                  className={classes.cancelButton}
                  onClick={(e) => onCancel(e)}
                >
                  <CancelIcon />
                </Fab>
              </Tooltip>
            </Grid>
          </Grid>
    </Grid>
  )
}

