import React from  'react';
import ProfileView from "./ProfileView";
import {withAuth0 } from "@auth0/auth0-react";

const { Component } = require("react");
class ProfileContainer extends Component{
    
    constructor(props){
        super(props);

        this.state = {token: ""};
    }

    async componentDidMount(){    
        const { getAccessTokenSilently } = this.props.auth0;        
        const token = await getAccessTokenSilently();
        console.log(this.props)
        this.setState({token: token});
    }

    render() {
        const { user } = this.props.auth0;  
        return <ProfileView user={user} token = {this.state.token} />
    }
}

export default withAuth0(ProfileContainer);