import { createSlice } from '@reduxjs/toolkit'

const mapSlice = createSlice({
  name: 'map',
  initialState: {style: {center : [9.543178, 56.381167],
                         zoom: [8]},
                 selectedFieldsIds : [],
                 selectedBaleIds: [],
                 selectedStackIds:[],
                 layersVisible: {fieldsLayer: true,
                                 balesLayer: true,
                                 stackLayer: true,},
                layersSelectable: {fieldsLayer: false,
                                balesLayer: false,
                                stackLayer: false,},
                editFeature : {},
                draw:{enableLayer: false,
                      enableEdit: false,
                      controls:  {  polygon: false,
                                    point: false,
                                    line_string: false,                    
                                    trash: false,
                                    combine_features: false,
                                    uncombine_features: false,
                                    }},
                popUp: {id: "", type: ""},
                popUpAllowed: true,
                balePopUpId: "",              
                mapClicked: {},
                },
  reducers: {
        setStyle(state, action) {
        // ✅ This "mutating" code is okay inside of createSlice!
        state.style = action.payload
        },        
        setZoom(state,action){
            state.style.zoom = [action.payload]
        },
        setBounds(state,action){
           state.style.fitBounds = action.payload
        },
        setVisibility(state,action){
            state.layersVisible = action.payload
        },
        setSelectablility(state,action){
            state.layersSelectable = action.payload
        },
        setPopUpAllowed(state,action){
            state.layersPopUp = action.payload
        },
        clearFieldSelection(state,action){
            state.selectedFieldsIds = []
        },
        clearStackSelection(state,action){
            state.selectedStackIds = []
        },
        clearAllSelections(state,action){
            state.selectedFieldsIds = []
            state.selectedBaleIds = []
            state.selectedStackIds = []
        },
        setSelectFieldId(state,action){       

            const existAt = state.selectedFieldsIds.indexOf(action.payload) 
            if(existAt === -1){
                //Did not exist -> push to selected
               state.selectedFieldsIds.push(action.payload)                
            } else {
                // Existed remove from array
                var newArray = state.selectedFieldsIds.slice()
                newArray.splice(existAt,1)
                state.selectedFieldsIds = newArray
            }
        },
        setSelectStackId(state,action){      
            const existAt = state.selectedStackIds.indexOf(action.payload) 
            if(existAt === -1){
                //Did not exist -> push to selected
               state.selectedStackIds.push(action.payload)                
            } else {
                // Existed remove from array
                var newArray = state.selectedStackIds.slice()
                newArray.splice(existAt,1)
                state.selectedStackIds = newArray
            }
        },        
        mapSetSelectedFieldIds(state,action){
            state.selectedFieldsIds = action.payload
        },
        mapSetSelectedStackIds(state,action){
            state.selectedStackIds = action.payload
        },
        clearBaleSelection(state,action){
            state.selectedBaleIds = []
        },
        setSelectBaleId(state,action){
            state.selectedBaleIds.push(action.payload)
        },
        setEditFeature(state,action){
            state.editFeature = action.payload
        },
        setDraw(state,action){
            state.draw = action.payload
        },
        setPopUp(state,action){
            state.popUp = action.payload         
        },
        setPopUpAllowed(state,action){
            state.popUpAllowed = action.payload
        },
        setClosePopUp(state,action){
            state.popUp = {id: "", type: ""}
        }
    }
})

export const {setStyle,setZoom,clearFieldSelection,setSelectFieldId,clearBaleSelection,setSelectBaleId,setVisibility, setPopUpAllowed,setSelectablility,clearAllSelections, mapSetSelectedFieldIds,setBounds,setEditFeature,setDraw
    ,mapSetSelectedStackIds,setSelectStackId,clearStackSelection,setPopUp,setClosePopUp} = mapSlice.actions

export const selectStyle = state => state.map.style 
export const selectMapLayersVisible = state => state.map.layersVisible
export const selectedFieldsIds = state => state.map.selectedFieldsIds
export const selectedBaleIds = state => state.map.selectedBaleIds
export const selectedStacksIds = state => state.map.selectedStackIds
export const selectLayersSelectable = state => state.map.layersSelectable
export const selectPopUpAlowed = state => state.map.popUpAllowed
export const selectEditFeature = state => state.map.editFeature
export const selectDraw = state => state.map.draw
export const selectPopUp = state => state.map.popUp
export default mapSlice.reducer