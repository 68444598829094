import { Feature } from "@turf/turf";
import { JobType } from "./job";
import { OrganisationBase } from "./organisationBase";

export enum AuthLevel {
    Any = "Any",
    Administrator = "Administrator",
    Baler = "Baler",
    Collector ="Collector"
}

export class JobReference {
    jobID: string;
    organisationID: string;
    jobType: JobType;
}

export class User extends OrganisationBase {
    auth0ID: string;
    name: string;
    phone: string;
    transportID: string;
    assignedJobs: JobReference[];
    workingOnJobs: JobReference[];
    lastSeenPoint: Feature;
}

export class ServiceUser{
    name:string = "";
    userID:string;
    auth0ID:string;
    roleList: AuthLevel[] =[];
    email:string = "";
    password:string;
    phone:string ="";
    createdByAuth0Id: string;    
    transportID:string;
    assignedJobs:JobReference[] = [];
    workingOnJobs: JobReference[] = [];
    lastSeenPoint: Feature;
    organisationId: string;    
    permissions: string[];
}





// internal class ServiceUser
//     {
//         [JsonProperty("name")]
//         public string Name { get; set; }

//         [JsonProperty("userID")]
//         public string UserID { get; set; }

//         [JsonProperty("auth0ID")]
//         public string Auth0ID { get; set; }

//         [JsonProperty("roleList")]
//         public List<AuthLevel> Roles { get; set; }

//         [JsonProperty("email")]
//         public string Email { get; set; }

//         [JsonProperty("password")]
//         public string Password { get; set; }

//         [JsonProperty("phone")]
//         public string Phone { get; set; }

//         [JsonProperty("transportID")]
//         public string TransportID { get; set; }

//         [JsonProperty("assignedJobs")]
//         public List<JobReference> AssignedJobs { get; set; }

//         [JsonProperty("workingOnJobs")]
//         public List<JobReference> WorkingOnJobs { get; set; }

//         [JsonProperty("lastSeenPoint")]
//         public Feature LastSeenPoint { get; set; }

//         [JsonProperty("organisationId")]
//         public string OrganisationId { get; set; }

//         [JsonProperty("createdByAuth0Id")]
//         public string CreatedByAuth0Id { get; set; }
        
//         [JsonProperty("permissions")]
//         public List<string> Permissions { get; set; }
//     }
// }