import { pink } from "@material-ui/core/colors";
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useSelector } from 'react-redux';
import FieldTable from "../../components/Fields/FieldTable";
import { selectFields } from "../../redux/slicers/fieldSlice";


const pink500 = pink['500'];


const DashboardUtilPanelFields = () => {
    const fields = useSelector(selectFields)

    return (<> 
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={2}
        >
            
         
            {fields.length > 0 ? (
                <>                 
                {/* {creatingNewField && <EditFieldTile />}    */}
                <FieldTable/>

                
                </>)
                : (
                    <Grid item>
                        <p style={{ textAlign: "center" }}>No Data Found !</p>
                    </Grid>)
            }
        </Grid>        
    </>
    )
}

export default DashboardUtilPanelFields
